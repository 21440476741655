import styled from 'styled-components';


export const Container = styled.div`
  width: 50%;
  margin-top: 6%;
`;

export const Title = styled.h1`
  margin-top: 5%;
  margin-left: 3%;
`;

export const Greeting = styled.h3`
  margin-top: 10%;
  margin-bottom: 5%;
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  margin-top: 50px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Col = styled.div`
  flex: 1;
  margin-right: ${props => props.marginRight || '0'};
`;

export const ContainerSpinner = styled.div`
  display: flex;
  margin-top: 15%; 
`;
