import api_ada from "./api_ada";

export const create_macro_sector = (data) => {
    return api_ada.post('macrosector/create', data)
};

export const update_macro_sector = (data) => {
    return api_ada.put('macrosector/update', data)
};

export const list_macro_sector = (data) => {
    return api_ada.post('macrosector/all', data)
};

export const delete_macro_sector = (data) => {
    return api_ada.post('macrosector/delete', data)
};
