import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { MdLink, MdLinkOff } from 'react-icons/md';
import { Container } from './styles';

const ButtonGroup = ({ buttonGroup, handleButtonGroup }) => {
  return (
    <Container $buttonGroup={buttonGroup} onClick={handleButtonGroup}>
      <Tooltip target={buttonGroup ? '.custom-target-link' : '.custom-target-unlink'} />
      {buttonGroup ? <MdLink size={25} /> : <MdLinkOff size={25} />}
    </Container>
  );
};

export default ButtonGroup;
