// import React, { useState } from 'react';

// import { FileInput, Label, FileInputLabel, FileLabel } from './styles';

// const InpFileUploader = () => {
//     const [outputData, setOutputData] = useState([]);

//     const handleFileUpload = (event) => {
//         const file = event.target.files[0];
//         const reader = new FileReader();
    
//         reader.onload = () => {
//             const fileContent = reader.result;
//             const lines = fileContent.split('\n').filter(line => line.trim() !== '');
    
//             const pipesInfo = [];
//             const coordinatesInfo = [];
    
//             let readingPipes = false;
//             let readingCoordinates = false;
    
//             for (const line of lines) {
//                 const trimmedLine = line.trim();
    
//                 if (trimmedLine.startsWith("[PIPES]")) {
//                     readingPipes = true;
//                     readingCoordinates = false;
//                     continue;
//                 }
    
//                 if (trimmedLine.startsWith("[COORDINATES]")) {
//                     readingCoordinates = true;
//                     readingPipes = false;
//                     continue;
//                 }
    
//                 if (readingPipes) {
//                     const fields = trimmedLine.split(/\s+/);
//                     if (fields.length >= 3) {
//                         const [id, node1, node2] = fields;
//                         pipesInfo.push({ id, node1, node2 });
//                     }
//                 }
    
//                 if (readingCoordinates) {
//                     const fields = trimmedLine.split(/\s+/);
//                     if (fields.length >= 3) {
//                         const [node, xCoord, yCoord] = fields;
//                         coordinatesInfo.push({ node, xCoord, yCoord });
//                     }
//                 }
//             }
    
//             const output = pipesInfo.map(pipe => {
//                 const n0 = pipe.node1;
//                 const n1 = pipe.node2;
//                 const findCoordinate = node => coordinatesInfo.find(coord => coord.node === node);
//                 const coordN0 = findCoordinate(n0);
//                 const coordN1 = findCoordinate(n1);
    
//                 if (coordN0 && coordN1) {
//                     const { xCoord: x0, yCoord: y0 } = coordN0;
//                     const { xCoord: x1, yCoord: y1 } = coordN1;
//                     return { n0, n1, x0, x1, y0, y1 };
//                 }
    
//                 return null; 
//             }).filter(Boolean); 
    
//             setOutputData(output);
//         };
    
//         reader.readAsText(file);
//     };
    

//     return (
//         <div>

//             <Label>Upload de Arquivo .inp</Label>
//             <div style={{ display: 'flex', alignItems: 'center' }}>
//                 <FileInputLabel>
//                     Escolha um arquivo
//                     <FileInput type="file" onChange={handleFileUpload} />
//                 </FileInputLabel>
//                 {/* <FileLabel> {fileName && <div>Nome do Arquivo:  {fileName}</div>}</FileLabel> */}
//                 {outputData.length > 0 && (
//                     <div>
//                         <h3>Saída Gerada:</h3>
//                         <pre>{JSON.stringify(outputData, null, 2)}</pre>
//                     </div>
//                 )}
//             </div>
//             {/* <h2>Upload de Arquivo .inp</h2>
//             <input type="file" accept=".inp" onChange={handleFileUpload} />
//             {outputData && (
//                 <div>
//                     <h3>Saída Gerada:</h3>
//                     <pre>{JSON.stringify(outputData, null, 2)}</pre>
//                 </div>
//             )} */}
//         </div>
//     );
// };

// export default InpFileUploader;


import React, { useState } from 'react';
import proj4 from 'proj4';
import { FileInput, Label, FileInputLabel, FileLabel } from './styles';

proj4.defs([
  [
    'EPSG:32723',
    '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs'
  ],
  [
    'EPSG:4326',
    '+proj=longlat +datum=WGS84 +no_defs'
  ]
]);

const InpFileUploader = () => {
    const [outputData, setOutputData] = useState([]);

    const utmToDecimal = (x, y) => proj4('EPSG:32723', 'EPSG:4326', [x, y]);

    const utmToLonLat = (easting, northing, zone, hemisphere) => {
        const utmProjection = '+proj=utm +zone=' + zone + ' +' + (hemisphere === 'S' ? 'south' : 'north') +
            '+ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
        const wgs84Projection = '+proj=longlat +datum=WGS84 +no_defs';
        proj4.defs('EPSG:31983', utmProjection);
        const utmCoordinates = [easting, northing];
        const lonlatCoordinates = proj4('EPSG:31983', wgs84Projection, utmCoordinates);
        return [lonlatCoordinates[1], lonlatCoordinates[0]];
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = () => {
            const fileContent = reader.result;
            const lines = fileContent.split('\n').filter(line => line.trim() !== '');
    
            const pipesInfo = [];
            const coordinatesInfo = [];
    
            let readingPipes = false;
            let readingCoordinates = false;
    
            for (const line of lines) {
                const trimmedLine = line.trim();
    
                if (trimmedLine.startsWith("[PIPES]")) {
                    readingPipes = true;
                    readingCoordinates = false;
                    continue;
                }
    
                if (trimmedLine.startsWith("[COORDINATES]")) {
                    readingCoordinates = true;
                    readingPipes = false;
                    continue;
                }
    
                if (readingPipes) {
                    const fields = trimmedLine.split(/\s+/);
                    if (fields.length >= 3) {
                        const [id, node1, node2] = fields;
                        pipesInfo.push({ id, node1, node2 });
                    }
                }
    
                if (readingCoordinates) {
                    const fields = trimmedLine.split(/\s+/);
                    if (fields.length >= 3) {
                        const [node, xCoord, yCoord] = fields;
                        coordinatesInfo.push({ node, xCoord, yCoord });
                    }
                }
            }
    
            const output = pipesInfo.map(pipe => {
                const n0 = pipe.node1;
                const n1 = pipe.node2;
                const findCoordinate = node => coordinatesInfo.find(coord => coord.node === node);
                const coordN0 = findCoordinate(n0);
                const coordN1 = findCoordinate(n1);
    
                if (coordN0 && coordN1) {
                    const { xCoord: x0, yCoord: y0 } = coordN0;
                    const { xCoord: x1, yCoord: y1 } = coordN1;
                    console.log(`{n0:${n0}, n1:${n1}, x0:${x0}, y0:${y0}, x1:${x1}, y1:${y1}`)
                    const [lon0, lat0] = utmToLonLat(parseFloat(x0), parseFloat(y0), 23, 'S'); // Adicione os parâmetros corretos
                    const [lon1, lat1] = utmToLonLat(parseFloat(x1), parseFloat(y1), 23, 'S'); // Adicione os parâmetros corretos
                    return { n0, n1, x0: lon0, x1: lon1, y0: lat0, y1: lat1 };
                }
    
                return null;
            }).filter(Boolean);
    
            setOutputData(output);
        };
    
        reader.readAsText(file);
    };



return (
    <div>

        <Label>Upload de Arquivo .inp</Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <FileInputLabel>
                Escolha um arquivo
                <FileInput type="file" onChange={handleFileUpload} />
            </FileInputLabel>
            {/* <FileLabel> {fileName && <div>Nome do Arquivo:  {fileName}</div>}</FileLabel> */}
            {outputData.length > 0 && (
                <div>
                    <h3>Saída Gerada:</h3>
                    <pre>{JSON.stringify(outputData, null, 2)}</pre>
                </div>
            )}
        </div>
        {/* <h2>Upload de Arquivo .inp</h2>
        <input type="file" accept=".inp" onChange={handleFileUpload} />
        {outputData && (
            <div>
                <h3>Saída Gerada:</h3>
                <pre>{JSON.stringify(outputData, null, 2)}</pre>
            </div>
        )} */}
    </div>
);
};

export default InpFileUploader;