import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { publish } from '../../../utils/events';

import red_pressure from "../../../assets/img/pins/red_pressure_icon.png";
import green_pressure from "../../../assets/img/pins/green_pressure_icon.png";
import yellow_pressure from "../../../assets/img/pins/yellow_pressure_icon.png";
import purple_pressure from "../../../assets/img/pins/purple_pressure_icon.png";
import gray_pressure from "../../../assets/img/pins/gray_pressure_icon.png";
import green_pin_macro from "../../../assets/img/pins/DEV_MACRO_VERDE.png";

import { mapStyle, mapStyleFeira } from '../../../utils/MapStyles';
import useCompanyStore from '../../../store/useCompanyStore';


const iconMapping = {
  pressure: {
    red: red_pressure,
    green: green_pressure,
    yellow: yellow_pressure,
    purple: purple_pressure
  },
  flow: {
    red: red_pressure,
    green: green_pin_macro,
    yellow: yellow_pressure,
    purple: purple_pressure
  }
};

const MiniMap = ({ lat, lon, color, desc, others, devices, selectedSensor, alarms_devices, height }) => {
  const { tradingName } = useCompanyStore();

  const [customMarkerIcon, setCustomMarkerIcon] = useState(null);
  const [customMarkerOthersIcon, setCustomMarkerOthersIcon] = useState(null);
  const [showStreet, setShowStreet] = useState(null);
  const position = { lat, lng: lon };

  useEffect(() => {
    let result = [];
    if (selectedSensor != null) {
      result = Object.values(devices).filter(item => item.deviceId === selectedSensor)
        .map(obj => ({ ...obj, data_type: "pressure", type: "sensor" }));
    }

    if (result[0] && result[0].data_type) {
      setCustomMarkerIcon(getCustomMarkerIcon(result[0].dataType, result[0].type));
      publish('up-heatMap-selected', true);
    }

    const tradingNameCheck = tradingName.includes('Demonstração') || tradingName.includes('Comercial');
    const selectedMapStyle = tradingNameCheck ? mapStyleFeira : mapStyle;
    const mapOptions = {
      zoomControl: false,
      styles: selectedMapStyle,
      fullscreenControl: true
    };
    setShowStreet(mapOptions);
  }, [selectedSensor]);

  useEffect(() => {
    if (others !== undefined) {
      const icons = others
        .filter(({ lat: otherLat, lon: otherLon }) => lat !== otherLat || lon !== otherLon)
        .map(({ lat, lon }) => ({
          url: gray_pressure,
          lat,
          lng: lon,
          scaledSize: new window.google.maps.Size(25, 25)
        }));
      setCustomMarkerOthersIcon(icons);
    }
  }, [lat, lon, others]);

  function getCustomMarkerIcon(dataType, type) {
    const category = (dataType === 'pressure' && type === 'sensor') ? 'pressure' : 'flow';
    return {
      url: iconMapping[category][color] || green_pin_macro,
      scaledSize: new window.google.maps.Size(30, 30)
    };
  }

  return (

      <GoogleMap
        mapContainerStyle={{ width: '100%', height: height ? `${height}px` : '100px' }}
        center={position}
        zoom={15}
        options={showStreet}
      >
        {customMarkerOthersIcon && customMarkerOthersIcon.map((icon, idx) => (
          <Marker key={"minimap_" + idx} position={icon} icon={icon} />
        ))}
        {customMarkerIcon && <Marker position={position} icon={customMarkerIcon} />}
      </GoogleMap>

  );
};

export default MiniMap;