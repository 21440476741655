import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  background: #000000dd;
  color: black;
  right: 0;
  top: calc(67vh - 111px);
  width: 235px;
  font-size: 10px;
  padding: 5px;
`;

export const Paragraph = styled.p`
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 2px;
  color: white;
`;

export const Item = styled.div`
  background-color: ${props => props.$bgColor};
  color: ${props => props.$textColor};
  padding: 3px;
  white-space: nowrap;
`;
