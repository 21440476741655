import styled from 'styled-components';
import { Form, FormCheck } from 'react-bootstrap';

export const Container = styled.div`
  position: absolute;
  background: #000000dd;
  color: white;
  width: 135px;
  bottom: calc(32.3vh - 119px);
  font-size: 11px;
  padding: 5px;
`;

export const Title = styled.p`
  font-size: 10px;
  font-weight: bold;
`;

export const CustomFormCheck = styled(FormCheck)`
  label {
    font-size: 10px;
  }
`;