import React from 'react';

import DEVS from '../../assets/img/cards/DEVS.svg';
import DISTANCE from '../../assets/img/cards/DISTANCE.svg';
import HIDRO from '../../assets/img/cards/HIDRO.svg';
import MACRO from '../../assets/img/cards/MACRO.svg';
import REFERENCE from '../../assets/img/cards/REFERENCE.svg';
import { Container, Content, Value } from './styles';

const CardsInformation = ({
    title,
    value,
    icon,
    size,
    isKm,
    isSelected
}) => {

    const iconSelected = () => {
        switch (icon) {
            case 'DEVS':
                return DEVS;
            case 'HIDRO':
                return HIDRO;
            case 'MACRO':
                return MACRO;
            case 'REFERENCE':
                return REFERENCE;
            case 'DISTANCE':
                return DISTANCE;
            default:
                return null;
        }
    }

    const iconUrl = iconSelected() || '';

    return (
        <Container $expanded={''} size={size}>
            <Content $sizeText={isSelected}>
                <span>{title}</span>
                <img src={iconUrl} alt={title}/>
            </Content>
            <Value $size={typeof value === 'string' && value.length > 8} $isKm={isKm} $sizeText={isSelected}>
                <h1>{value}</h1> 
                {isKm && <h2>/km</h2>}
            </Value>
        </Container>
    );
}

export default CardsInformation;