import React, { useEffect, useState} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import MiniMap from "../../components/Maps/MiniMap/index";
import symbol_sensor from "../../assets/img/install_points/pressureOff.png";
import symbol_macro from "../../assets/img/install_points/DEV_MACRO.png";
import RenderHeatMap from '../HeatMap/index';


export const getData = (data) => {
	var rd_map = {};
	
	for(let i in data){
		let id = String(data[i].deviceId);
		let dtc = data[i].timestamp;
		dtc = dtc.toString().slice(0, -3);
		if(rd_map[id] == undefined)
			rd_map[id] = {}
		
		if(rd_map[id][dtc] == undefined)
			rd_map[id][dtc] = 0;
		
		rd_map[id][dtc] += 1
	}
	return rd_map;
}

const RenderDeviceTable = ({devices, selectedSensor, data, dateOn, dateTo}) => {
	var rdata= getData(data);
	var elements = [];
	if (selectedSensor != null)
		elements = [...new Set(devices.filter(e=>selectedSensor == e.deviceId))];
	return <>
		{elements.length > 0 ?
			<div className="table-responsive">
				<table className="table">
				<thead>
					<tr>
					<th>&nbsp;</th>
					<th style={{textAlign: "left", paddingLeft: 10, paddingRight: 20}}><FormattedMessage id="information" /></th>
					<th style={{textAlign: "left", paddingLeft: 10, paddingRight: 20}}><FormattedMessage id="location" /></th>
					<th style={{textAlign: "left", paddingLeft: 10, paddingRight: 20}}><FormattedMessage id="readings" /></th>
					</tr>
				</thead>
				<tbody>
					{
						elements.map((r, idx)=>{return RenderDeviceTableRow(r, rdata[r.deviceId], devices, selectedSensor, dateOn, dateTo)})
					}
				</tbody>
				</table>
			</div> : <></>
		}
	</>
	
}

const RenderDeviceTableRow = (row, value, devices, selectedSensor, dateOn, dateTo) =>{	
	const [showHeatMap, setShowHeatMap] = useState(false);


	useEffect(() => {
		setShowHeatMap(false);
		setTimeout(() => {
			setShowHeatMap(true);
		}, 50);
	}, [selectedSensor])
	
	return <tr style={{display: "transparent"}} key={row.deviceId}>
	  <td className="col-sm-2" style={{textAlign: 'center', verticalAlign: 'middle'}} id='cell-a'>
	  	<img src={row.type == "sensor" ? symbol_sensor : symbol_macro} style={{width: 55}}/>
	  </td>
	  <td className="col-sm-4" id='cell-b' style={{verticalAlign: 'middle'}}>
	    <div>
	      <i className="fa fa-circle" style={{color: "green", fontSize: 12, marginRight: 5}}></i> <strong>{row.deviceId}</strong>
	      <div><FormattedMessage id="latitude" />:  {row.lat}</div>
		  <div><FormattedMessage id="longitude" />: {row.long}</div>
		 {row && row.elevation && <div><FormattedMessage id="altitude" />: {row.elevation.toFixed(2)} <FormattedMessage id="meters" /></div>}
	    </div>
	  </td>
	  <td className="col-sm-3" id='cell-c' style={{verticalAlign: 'middle'}}>
	    <div style={{width:200, paddingRight: 10, paddingLeft: 10}}>
			<MiniMap lat={row.lat} lon={row.long} color={"green"} desc={row.deviceId} others={[]} devices={devices} selectedSensor={selectedSensor}/>
	    </div>
	  </td>
	  <td className="col-sm-4" id='cell-d'>
		{showHeatMap && <RenderHeatMap value={value} dateOn={dateOn} dateTo={dateTo}/>}
	  </td>
	</tr>
}

export default RenderDeviceTable;