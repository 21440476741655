import styled from 'styled-components';
import { Form, FormCheck } from 'react-bootstrap';

export const Container = styled.div`
  position: absolute;
  background: #000000dd;
  color: white;
  width: ${({ $state }) => ($state ? '170px' : '38px')};
  bottom: calc(49vh - 119px);
  font-size: 11px;
  padding: 5px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
`;

export const CustomFormCheck = styled(FormCheck)`
  label {
    font-size: 10px;
  }
`;

export const StyledButton = styled.div`
    border-radius: 20px;
    padding: 2px;
    &:hover {
    background-color: #2980b9;
    }
`;