import styled, {css} from "styled-components";
import Dropdown from 'react-bootstrap/Dropdown';

const activeButtonStyles = css`
  background-color: ${props => props.theme.colors.primary}; ;
  border-color: ${props => props.theme.colors.primary}; ;
`;

// export const Container = styled.div`
//     background-color: ${props => props.theme.colors.primary};
//     height: 55px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// `;

// export const ContainerLogo = styled.a`
//     width: 260px;
//     text-align: end;
// `;

// export const Logo = styled.img`
//     height: 24px;
//     text-align: end;
// `;

// export const ContainerItems = styled.nav`
//   background-color: beige;
//     display: flex;
//     justify-content: start;
//     margin-left: -615px;
// `;

export const Items = styled.a`
    color: ${props => props.$actived ? props.theme.colors.white : props.theme.colors.black};
    margin-right: 10px;
    text-decoration: ${props => props.$actived ? 'underline' : 'none'};
    align-items: center;
    &:hover {
        opacity: .7;
    }
    > svg {
        font-size: 20px;
        margin-right: 1px;
    }
`;

export const CustomToggle = styled(Dropdown.Toggle)`
 padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.primary}; 
  color: #fff;
  border: none;
  border-color: ${props => props.theme.colors.primary}; 

  &::after {
    display: none;
    background-color: ${props => props.theme.colors.primary}; 
  }

  & ::before {
    background-color: ${props => props.theme.colors.primary}; 
  }

  &.btn-primary {
    background-color: ${props => props.theme.colors.primary}; 
  }


  &:hover {
    background-color: ${props => props.theme.colors.primary}; 
    color: #fff; 
  }

  &.show {
    background-color: ${props => props.theme.colors.primary}; 
    color: #fff; /* Cor do texto quando o menu está aberto */
  }

  &:checked {
    ${activeButtonStyles}
  }

  &:focus {
    background-color: ${props => props.theme.colors.primary}; 
    border-color: ${props => props.theme.colors.primary}; 
  }

  /* Estilos quando o botão está ativo (pressionado) */
  &:active {
    background-color: ${props => props.theme.colors.primary}; 
    border-color: ${props => props.theme.colors.primary}; 
  }

  /* Estilos quando o botão está desabilitado */
  &:disabled {
    background-color: ${props => props.theme.colors.primary}; 
    border-color: ${props => props.theme.colors.primary}; 
  }


`;

export const CustomMenu = styled(Dropdown.Menu)`
    margin-top: 18px;
    border: 2px solid grey; 
    border-radius: 0%;
    margin-right: 16%;
  &::before {
    content: "";
    position: absolute;
    top: -20px; 
    left: 92%; 
    transform: translateX(-50%);
    border-width: 12px; 
    border-style: solid;
    border-color: transparent transparent grey transparent; 
  }
`;

export const CustomItemDropdown = styled(Dropdown.Item)`
  >div {
    display: block;
    padding-bottom: 3px;
    border-bottom: 1px gray dotted;
  }
  @media(max-width: 900px) {
    &:nth-child(0) {
      display: none;
    }
    &:nth-child(1) {
      display: none;
    }
    &:nth-child(2) {
      display: none;
    }
    &:nth-child(3) {
      display: none;
    }
    >div {
      display: none;
    }
  }
`;

export const HeaderContainer = styled.header`
  background-color: ${props => props.theme.colors.primary};
  display: grid;
  grid-template-columns: 1fr 3fr 0fr 0fr; 
  height: 55px;
  color: #fff;
  gap: 15px;
  @media(min-width: 1080px) {
    grid-template-columns: 244px 5.3fr 1fr 0.1fr; 
  }
`;

export const Logo = styled.div`
  text-align: end;
  font-size: 2rem;
  >img {
    height: 28px;
  }
  @media(max-width: 900px) {
      text-align: start;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
`;

export const Alarm = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
  @media(max-width: 900px) {
      display: none;
  }
`;

export const Nav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 900px) {
      display: none;
  }
`;

export const NavItem = styled.div`
  margin: 0;
  width: 1fr;
`;

export const NavItemSelect = styled.div`
  text-align: center;
  width: 300px;
  display: none;
  @media(min-width: 900px) {
    display: block; /* De 900px a 1080px, será 'block' */
  }

  @media(min-width: 1080px) {
    display: block; 
    margin-left: -140px;
  }
`;