import styled, { css, keyframes } from "styled-components";

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const loadingStyles = css`
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 1.5s infinite;
`;

export const Container = styled.div``

export const GridContainer = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-rows: ${({ $size }) => $size ? '30px 30px 1fr 0.3fr' : '30px 30px 1fr'}; 
  grid-template-columns: ${({ $sidebarOpen }) => $sidebarOpen ? '235px' : '80px'} minmax(0, 0.9fr) minmax(0, 1.1fr);
  gap: 15px; /* Espaçamento entre os elementos */
  height: 82vh; /* Altura total do grid */
  transition: grid-template-columns 0.4s;
  padding-left: 10px;
  padding-right: 10px;
`;

export const GridItem = styled.div`
  ${({$skeleton}) => $skeleton ? 'background-color: #e0e0e0;' : loadingStyles};
  border: 1px solid #ccc;
  color: #000;
`;

export const GridItemSidebar = styled.div`
  /* grid-area:  ${({ $size }) => $size ? '2 / 1 / 4 / 2' : '2 / 1 / 3 / 2'}; */
  grid-area:  ${({ $size }) => $size ? '2 / 1 / 5 / 2' : '2 / 1 / 4 / 2'};
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  overflow: auto;
  color: #000;
`;

export const GridItemSearch = styled.div`
  border-radius: 5px;
  background-color: ${({ $sidebarOpen }) => $sidebarOpen ? '#fff' : '#8086C0'};
  border: ${({ $sidebarOpen }) => $sidebarOpen ? 'none' : '#8086C0 1px solid'};
  display: flex;
  align-items: center;
  justify-content: ${({ $sidebarOpen }) => $sidebarOpen ? 'space-between' : 'center'};
`;

export const GridItemInput = styled.input`
  width: 62%;
  border: #000 solid 1px;
  padding-left: 10px;
`;

export const GridItemTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const GridItemDate = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const TitleContainer = styled.div`
    background: linear-gradient(to right, #cfcfcf 50%, ${props => props.theme.colors.success} 50%);
    width: 70%;
    height: 100%;
    display: flex; 
    padding: 0 5px; 
`;

export const CompanyText = styled.span`
    color: ${props => props.theme.colors.black}; 
    align-self: center;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    text-align: center;
`;

export const SectorText = styled.span`
    color: ${props => props.theme.colors.black}; 
    align-self: center;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    text-align: center;
`;

export const SidebarContainer = styled.div`
  background-color: #f0f0f0;
  position: relative;

`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: 13px;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: ${({ $sidebarOpen }) => $sidebarOpen ? 'space-between' : 'center'};
  border-bottom: 4px #fff solid;
  align-items: center;
  padding: 10px;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
    background-color: #8086C0;
  }
  ${props => props.$active && `
    opacity: 0.5;
    background-color: #8086C0;
  `}
`;

export const GridItemTable = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr 1fr;

  gap: 10px;

  & > div {
    grid-column: span 1;
  }

  & > div:nth-child(5) {
    grid-column: span 2;
  }
`;

export const TableItem = styled.div`
  ${({$skeleton}) => $skeleton ? 'background-color: #e0e0e0;' : loadingStyles};
  background-color: #e0e0e0;
  border: 1px solid #ccc;
`;

export const NewLine = styled.div`
  grid-area: 4 / 2 / 5 / 4;
  height: 100%;
`;

export const TitleMap = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  font-weight: bold;
  font-size: 25px;
`;

export const TitleTable = styled.div`
  grid-area: 2 / 3 / 3 / 4;
  font-weight: bold;
  font-size: 25px;
  border-bottom: 2px solid #d0d0d0;
  margin-bottom: -5px;
`;