import React from 'react';

const aux = navigator.language.split(/[-_]/)[0];
const language = aux === 'pt' ? 'pt' : aux === 'es' ? 'es' : aux === 'en' ? 'en': 'en' ;

let useMonths = [];

if(language !== "en") {
  if(language === "pt") {
    useMonths = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
  }else if(language === "es") {
    useMonths = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  }
}else{
  useMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
}

export const MiniCalendar = (date) => {
  return <table style={{margin: "0px 20px", width: 72}}>
    <tbody>
    <tr>
      <td style={{
          color: "white",
          background: "#de3232",
          width: 215,
          textAlign: "center",
          fontSize: 16,
          fontWeight: "bold",
          borderRadius: "10px 10px 0px 0px",
          lineHeight: 0.4
        }}
      >
        {useMonths[date.getMonth()]}
      </td>
    </tr>
    <tr>
      <td style={{
        color: "black",
        textAlign: "center",
        background: "whitesmoke",
        fontSize: 23,
        fontWeight: "bold"
      }}>
        {date.getDate()}
      </td>
    </tr>
    </tbody>
  </table>
}

export const MiniCalendarAlarms = (date) => {
  return <table style={{margin: "0px 20px", width: 40}}>
    <tbody>
    <tr>
      <td style={{
          color: "white",
          background: "rgb(128, 134, 192)",
          textAlign: "center",
          fontSize: 13,
          fontWeight: "bold",
          borderRadius: "10px 10px 0px 0px",
          lineHeight: 0.4,
          padding: "5px",
        }}
      >
        {useMonths[date.getMonth()]}
      </td>
    </tr>
    <tr>
      <td style={{
        color: "black",
        textAlign: "center",
        background: "whitesmoke",
        fontSize: 14,
        fontWeight: "bold"
      }}>
        {date.getDate()}
      </td>
    </tr>
    </tbody>
  </table>
}