import styled, { css } from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

const CustomButtonStyles = css`
    background-color: #fff;
    color: #000;
    border-color: #fff;
`;


export const CustomDropdownButton = styled(DropdownButton)`
    > button {
        width: 300px;
        background-color: #fff;
        color: #000;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
        border-color: #fff;
        &.btn-check:checked + &,
        &.active,
        &.show,
        &:first-child:active,
        &:not(.btn-check) + &.active {
            ${CustomButtonStyles}
        }
        &:hover,
        &:focus,
        &:active {
            background-color: #fff;
            color: #000;
            border-color: #fff;
        }

        &.show {
            background-color: #fff; 
            color: #000; 
            border-color: #fff; 
        }
    }
`;
