import React, { memo, useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip as PrimeReactTooltip } from 'primereact/tooltip';
import { getDatetimeFromTimestamp, toSingleDataset } from "../../../utils/Utils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  registerables
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';
import reset from "../../../assets/img/ICONE.png";
import useStateSidebarStore from '../../../store/useStateSidebarStore';
import useSliderStore from '../../../store/useSliderStore';

import { Container, Header, TitleButton, ButtonSelected, ButtonNoSelected, ContainerLegend, ItemWrapper, ColoredBar, Chart, ContainerReset } from './styles';

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

const CalculateWaterFlow = (ref_date, region) => {
  const intl = useIntl();
  const water_flow = Array.isArray(region) ? region : [];
  const water_flowX = [...new Set(water_flow.filter(e => ref_date == null || getDatetimeFromTimestamp(e.datetime).getTime() > ref_date).map(e => getDatetimeFromTimestamp(e.datetime)))];
  const water_flowX_ordered = water_flowX.sort((a, b) => a.getTime() - b.getTime());  
  let water_flowMap = {}, fpMap = {}, demand_mvnMap = {}, maxV = null, maxF = null, maxE = null;
  for (let k in water_flow) {
    const dtc = getDatetimeFromTimestamp(water_flow[k]["datetime"]);
    water_flowMap[dtc] = water_flow[k]["demand"];
    maxV = maxV === null ? water_flow[k]["demand"] :
      water_flow[k]["demand"] === null ? maxV :
        maxV < water_flow[k]["demand"] ? water_flow[k]["demand"] : maxV;
    demand_mvnMap[dtc] = water_flow[k]["demandMvn"];
    maxE = maxE === null ? water_flow[k]["demandMvn"] :
      water_flow[k]["demandMvn"] === null ? maxE :
        maxE < water_flow[k]["demandMvn"] ? water_flow[k]["demandMvn"] : maxE;
  }

  let dt = toSingleDataset(water_flowX_ordered, water_flowMap);
  let datasetsReading = [];
  datasetsReading.push(
    {
      type: "line",
      fill: false,
      borderColor: ['#43509e'],
      label: intl.formatMessage({ id: "flow_tooltip" }),
      data: dt,
      borderWidth: 1,
      hoverRadius: 8,
      pointRadius: 1,
    }
  );
  dt = toSingleDataset(water_flowX_ordered, demand_mvnMap);
  datasetsReading.push(
    {
      type: "line",
      fill: false,
      label: intl.formatMessage({ id: "flow_tooltip_mnf" }),
      borderColor: ['#D41800'],
      data: dt,
      borderWidth: 1,
      hoverRadius: 8,
      pointRadius: 1,
    }
  );

  return {
    labels: water_flowX_ordered,
    datasets: datasetsReading,
    maxY: Math.max(maxV, maxE)
  };
}


const RenderWaterFlow = ({ ref_date, region, btnChangeChart }) => {
  const intl = useIntl();
  const { isOpen } = useStateSidebarStore();
  const { currentSlider, setCurrentSlider } = useSliderStore();
  // const dispatch = useDispatch();
  // const { currentSlider } = useSelector((rootReducer) => rootReducer.sliderReducer);
  // const { currentZoom } = useSelector((rootReducer) => rootReducer.zoomReducer);
  const [verticalLineShow, setVerticalLineShow] = useState(false);
  const [verticalLinePosition, setVerticalLinePosition] = useState(null);
  const [flag, setFlag] = useState(false);
  const [isWindowMaximized, setIsWindowMaximized] = useState(false);
  const [upDateSize, setUpDateSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const lastZoomLevelRef = useRef(null);
  const myChartRefFlow = useRef(null);

  useEffect(() => {
    if (currentSlider) {
      setVerticalLineShow(currentSlider.show)
      const date_full = new Date(currentSlider.value);
      const day = date_full.getDate();
      const month = date_full.getMonth() + 1; // Os meses começam em zero, então é necessário adicionar 1
      const year = date_full.getFullYear().toString().slice(-2); // Obtém os últimos dois dígitos do ano
      setVerticalLinePosition(`${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`)
    }
  }, [currentSlider])

  useEffect(() => {
    if (myChartRefFlow && myChartRefFlow.current) {
      myChartRefFlow.current.resize();
    }
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (myChartRefFlow && myChartRefFlow.current) {
        myChartRefFlow.current.resize();
      }
    }, 2600);
  }, [upDateSize]);

  useEffect(() => {
    setTimeout(() => {
      if (myChartRefFlow && myChartRefFlow.current) {
        myChartRefFlow.current.resize();
      }
    }, 2200);
  }, [isWindowMaximized])

  useEffect(() => {
    const handleResize = () => {
      const isMaximized = window.innerWidth === window.screen.width;
      setIsWindowMaximized(isMaximized);
      const width = window.innerWidth;
      const height = window.innerHeight;
      setUpDateSize({ width, height });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleZoomComplete = (chart) => {
    if (chart) {
      const currentZoomLevelMin = chart.chart.scales['x'].min;
      const currentZoomLevelMax = chart.chart.scales['x'].max;
      lastZoomLevelRef.current = {
        min: currentZoomLevelMin,
        max: currentZoomLevelMax
      };
      setFlag(true);
    }
  };

  const resetZoomBtn = () => {
    if (myChartRefFlow && myChartRefFlow.current) {
      myChartRefFlow.current.resetZoom()
      setFlag(false);
    }
  };

  var WATER_FLOW_DATA = CalculateWaterFlow(ref_date, region);
  var WATER_FLOW_OPTS = {
    responsive: false,
    animation: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: intl.formatMessage({ id: "flow_subtitle" }),
          padding: 5
        },
        gridLines: {
          drawBorder: false,
          //display: true,
          drawTicks: false,
          color: '#eef0fa',
          zeroLineColor: 'rgba(90, 113, 208, 0)',
        },
        ticks: {
          //display: false,
          beginAtZero: false,
          min: 0,
          max: 30,
          // stepSize: 10,
          padding: 10,
        }
      },
      x: {
        min: flag ? lastZoomLevelRef.current.min : 'original',
        max: flag ? lastZoomLevelRef.current.max : 'original',
        scaleLabel: {
          display: false,
          labelString: 'Dia do mês'
        },
        type: 'time',
        time: {
          unit: 'day',
          parser: 'MM/DD/YYYY HH:mm',
          tooltipFormat: intl.formatMessage({ id: "day_tooltip" }),
          fontsize: '10px',
          displayFormats: {
            'day': intl.formatMessage({ id: "day_label_x" })
          }
        },
        position: 'bottom',
        grid: {
          drawBorder: false,
          display: false,
          drawTicks: false
        },
        ticks: {
          beginAtZero: false,
          stepSize: 10,
          fontColor: "#878f87",
          padding: 10,
          maxRotation: 0,
          minRotation: 0,
        }
      },
    },
    legend: {
      display: true,
      position: "bottom",
      fontSize: 10
    },
    elements: {
      line: {
        tension: 0
      },
      point: {
        radius: 0
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const aa = context.map((a) => {
              return a
            })
            return aa[0].label;
          },
          label: function (context) {

            return '';
          },
          footer: function (context) {
            const bb = context.map((a) => {
              return a
            })
            return `${bb[0].dataset.label}: ${bb[0].formattedValue}`;
          }
        }
      },
      zoom: {
        limits: {
          x: {
            min: WATER_FLOW_DATA.labels[0],
            max: WATER_FLOW_DATA.labels[WATER_FLOW_DATA.labels.length - 1]
          }
        },
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl',
        },
        zoom: {
          wheel: {
            enabled: false
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
          drag: {
            enabled: true
          },
          mode: 'x',
          onZoomComplete: (chart => {
            handleZoomComplete(chart);
          }),
        },
        drag: {
          backgroundColor: 'rgba(110, 255, 148, 0.4)',
        },
      },
      autocolors: false,
      annotation: verticalLineShow ? {
        annotations: {
          line1: {
            type: 'line',
            scaleID: 'x',
            value: verticalLinePosition,
            // value: '03/01/23',
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            borderColor: 'rgba(0, 255, 0, 0.52)',
            borderWidth: 4,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      } : {}
    }
  };

  return (
    <Container>
      <Header>
        <TitleButton>
          <ButtonSelected><FormattedMessage id="flow_title" /></ButtonSelected>
          <ButtonNoSelected onClick={() => btnChangeChart('Fator de Pesquisa')}><FormattedMessage id="research_factor_title" /></ButtonNoSelected>
        </TitleButton>
        <PrimeReactTooltip target=".custom-target-btn-reset" />
        <ContainerReset className='custom-target-btn-reset'
          onClick={() => resetZoomBtn()}
          data-pr-tooltip={intl.formatMessage({ id: "reset_zoom" })}
          data-pr-position="left"
        >
          <img src={reset} width={22} alt="Reset" style={{ verticalAlign: "text-top" }} />
        </ContainerReset>
      </Header>
      <Chart $data={WATER_FLOW_DATA}>
        {/* <Line data={WATER_FLOW_DATA} options={WATER_FLOW_OPTS} redraw={false} ref={myChartRefFlow} width={windowWidth} /> */}
        {WATER_FLOW_DATA && WATER_FLOW_DATA.labels.length > 0 ? <Line data={WATER_FLOW_DATA} options={WATER_FLOW_OPTS} redraw={false} ref={myChartRefFlow} /> : <FormattedMessage id="msg_no_data" />}
      </Chart>
      <ContainerLegend>
        <ItemWrapper>
          <ColoredBar $borderColor="#43509e" />
          <FormattedMessage id="flow_tooltip" />
        </ItemWrapper>
        <ItemWrapper>
          <ColoredBar $borderColor="#D41800" />
          <FormattedMessage id="flow_tooltip_mnf" />
        </ItemWrapper>
      </ContainerLegend>
    </Container>
  )
}

export default memo(RenderWaterFlow);