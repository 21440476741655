import React, { useEffect, useState } from 'react';
import { keycloak } from '../../keycloak';
import { useAuth } from '../../hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';

import {
    LoadingOverlay,
    LoadingSpinnerWrapper,
    LoadingMessageWrapper,
    LoadingMessage
} from './styles'

const Intercept = () => {
    const { login } = useAuth();
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(null);
    const [tokenKeycloak, setTokenKeycloak] = useState('');
    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (!keycloak.authenticated) {
                keycloak.login({ redirectUri: window.location.origin + '/intercept/' + clientId });
            } else {
                setFlag(true);
            }
        }, 2500)
    }, []);

    useEffect(() => {
        if (keycloak) {
            if (keycloak && keycloak.authenticated) {
                localStorage.setItem('isKcAda', true);
                localStorage.setItem('last_client', clientId);
                handleLogin();
            }
        }
    }, [flag])
    
    const handleLogin = async (e) => {
        setLoading(true);
        const response = await login(keycloak.tokenParsed.email, password, keycloak.token);
        if (login) {
            if (response) {
                navigate('/home');
            } else {
                setLoading(false);
            }
        } else {
            alert('Função de autenticação não encontrada.');
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && <LoadingOverlay>
                <LoadingSpinnerWrapper />
                <LoadingMessageWrapper>
                    {/* <LoadingMessage><FormattedMessage id="loading" /></LoadingMessage> */}
                </LoadingMessageWrapper>
            </LoadingOverlay>
            }
        </div>
    )
};

export default Intercept;