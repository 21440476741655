import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { GoogleMap, Marker, InfoWindowF, Polyline } from '@react-google-maps/api';
import green_pressure from "../../../assets/img/install_points/pressureOn.png";
import gray_pressure from "../../../assets/img/install_points/gray_pressure_icon.png";
import green_pin_macro from "../../../assets/img/install_points/DEV_MACRO_VERDE.png";
import gray_pin_macro from "../../../assets/img/install_points/DEV_MACRO_CINZA.png";

import { mapStyle, mapStyleFeira } from '../../../utils/MapStyles';
import useCompanyStore from '../../../store/useCompanyStore';

const InstallPointMap = ({ traces, selectedSensor, onSelectSensor, devices, sizeMap }) => {
  const intl = useIntl();
  const { tradingName } = useCompanyStore();
  const [customMarkerIcon, setCustomMarkerIcon] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });
  const [defaultZoom, setDefaultZoom] = useState(2);
  const [mapObj, setMapObj] = useState(null);

  const [showTraces, setShowTraces] = useState(false);
  const [showStreet, setShowStreet] = useState(null);

  useEffect(() => {
    if (window?.google?.maps)
      renderMarkers(devices);
  }, [devices, selectedSensor]);

  useEffect(() => {
    setShowTraces(true);
    const tradingNameCheck = tradingName.includes('Demonstração') || tradingName.includes('Comercial');
    const selectedMapStyle = tradingNameCheck ? mapStyleFeira : mapStyle;
    const mapOptions = {
      zoomControl: false,
      styles: selectedMapStyle,
      fullscreenControl: true
    };
    setShowStreet(mapOptions);
  }, [traces])

  useEffect(() => {
    if (customMarkerIcon) {
      setTimeout(() => {
        fitBounds();
      }, 500);
    }
  }, [customMarkerIcon])

  const fitBounds = () => {
    const map = mapObj;
    if (window?.google?.maps) {
      const bounds = new window.google.maps.LatLngBounds();
      if (customMarkerIcon != null) {
        customMarkerIcon.forEach((marker) => {
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
        });
        map.fitBounds(bounds);
        const newCenter = map.getCenter();
        if (devices && devices.length > 0) {
          setDefaultCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
          setDefaultZoom(map.getZoom());
        } else if (devices && devices.length === 0) {
          setDefaultCenter({ lat: -15.685068956906802, lng: -47.94552127288126 });
          setDefaultZoom(map.getZoom());
        }
      }
    }
  };

  function renderMarkers(devices) {
    var icons = [];
    let url = "";
    let urlMacro = "";
    for (let i in devices) {
      if (devices[i].dataType == "pressure") {
        if (selectedSensor == null) {
          url = gray_pressure;
        }
        else {
          if (selectedSensor == devices[i].deviceId) {
            url = devices[i].dataType == 'pressure' ? green_pressure : green_pin_macro;
          }
          else {
            url = gray_pressure;
          }
        }
      }
      else {
        if (selectedSensor == null) {
          url = gray_pin_macro;
        }
        else {
          if (selectedSensor == devices[i].deviceId) {
            url = devices[i].dataType == 'pressure' ? green_pressure : green_pin_macro;
          }
          else {
            url = gray_pin_macro;
          }
        }
      }
      icons.push({
        url: url,
        id: devices[i].deviceId,
        lat: devices[i].lat,
        lng: devices[i].long,
        device: devices[i].deviceId,
        elevation: devices[i].elevation,
        installPointName: devices[i].installPointName,
        scaledSize: new window.google.maps.Size(40, 40)
      });
    }
    setCustomMarkerIcon(icons);
  }

  function handleMarkerClick(marker) {
    setSelectedMarker(marker);
  }

  return (<>
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      onLoad={(map) => setMapObj(map)}
      center={defaultCenter}
      zoom={defaultZoom}
      options={showStreet}
    >
      {customMarkerIcon != null && customMarkerIcon.map((e, idx) => {
        return <Marker key={"mrk_bm_idx_" + idx} position={e} icon={e} onClick={() => handleMarkerClick(e)}>
          {selectedMarker == e && (
            <InfoWindowF
              position={selectedMarker.position}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div style={{ width: "250px", maxWidth: "100%" }}>
                <h6><FormattedMessage id="details" /></h6>
                <div><strong><FormattedMessage id="location" />:</strong> {e.lat}, {e.lng}</div>
                <div><strong><FormattedMessage id="device" />:</strong> {e.device}</div>
                <div><strong><FormattedMessage id="point" />:</strong> {e.installPointName}</div>
                {e && e.elevation && <div><strong><FormattedMessage id="altitude" />:</strong> {e.elevation.toFixed(2)} <FormattedMessage id="meters" /></div>}

                {e.id !== "MACRO" &&
                  (onSelectSensor != undefined) &&
                  <button className={"btn"} style={{ margin: 5, zoom: 0.7, float: "right", backgroundColor: selectedSensor == e.id ? '#E44C4E' : '#6EFF94', borderColor: 'transparent' }}
                    onClick={() => onSelectSensor(e.id)}>{selectedSensor == e.id ? intl.formatMessage({ id: "deselect" }) : intl.formatMessage({ id: "select" })}</button>
                }

              </div>
            </InfoWindowF>
          )}
        </Marker>;
      })}
      {showTraces && traces && traces.map((line, index) => {
        return <Polyline
          key={index}
          path={[{ lat: line.x0, lng: line.y0 }, { lat: line.x1, lng: line.y1 }]}
          options={{
            strokeColor: "#006699",
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
      })}
    </GoogleMap>
  </>
  );
};

export default InstallPointMap;