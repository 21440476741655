import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, DrawingManager } from '@react-google-maps/api';
import _debounce from 'lodash/debounce';
import { mapStyle } from '../../../utils/MapStyles';

const MapComponent = ({ coordSector, isBig, deletePolygon, kmlData, onPathDrawn, onDeletePolygonPath }) => {
    const [map, setMap] = useState(null);
    const [showDraw, setShowDraw] = useState(false);
    const [create, setCreate] = useState(false);
    const [defaultCenter, setDefaultCenter] = useState({ lat: -23.47003808219593, lng: -47.417858840616105 });
    const [kmlDataMap, setKmlDataMap] = useState(null);
    const [mapKey, setMapKey] = useState(0);

    const polygonsRef = useRef([]);
    const polygonsRefKmz = useRef([]);

    const [verify, setVerify] = useState(false);

    useEffect(() => {
        setCreate(isBig);
        setShowDraw(isBig);
        if (isBig) {
            // clearExistingPolygons();
            setTimeout(() => {
                setVerify(true);
            }, 1000);
        } else {
            setTimeout(() => {
                setVerify(false);
            }, 500);
        }
    }, [isBig]);

    useEffect(() => {
        if (deletePolygon) {
            onDeletePolygon();
        }
        if (kmlDataMap) {
            reloadMap();
        }
    }, [deletePolygon]);

    useEffect(() => {
        if (kmlData) {
            const averageLatitude = kmlData.reduce((sum, point) => sum + point.lat, 0) / kmlData.length;
            const averageLongitude = kmlData.reduce((sum, point) => sum + point.long, 0) / kmlData.length;
            setDefaultCenter({ lat: averageLatitude, lng: averageLongitude });
            polygonMapKmz(kmlData);
        }
    }, [kmlData, isBig, map]);

    useEffect(() => {
        polygonsRef.current.forEach(polygon => polygon.setMap(null));
        if (coordSector.length > 0) {
            const allCoordinates = coordSector.flatMap(sector => sector.sectorGeoDelimitation);
            const averageLatitude = allCoordinates.reduce((sum, point) => sum + point.lat, 0) / allCoordinates.length;
            const averageLongitude = allCoordinates.reduce((sum, point) => sum + point.long, 0) / allCoordinates.length;
            setDefaultCenter({ lat: averageLatitude, lng: averageLongitude });
            polygonMap(coordSector);
        }
    }, [coordSector]);

    const mapOptions = {
        zoomControl: true,
        styles: mapStyle,
        fullscreenControl: true
    };

    const onDeletePolygon = () => {
        polygonsRefKmz.current.forEach(polygon => polygon.setMap(null));
        polygonsRefKmz.current = []; // Clear the reference array
    };

    const reloadMap = () => {
        setMapKey(prevKey => prevKey + 1);
    };

    const polygonMap = data => {
        const aux = data.map(sector => sector.sectorGeoDelimitation.map(coord => ({ lat: coord.lat, lng: coord.long })));
        aux.forEach((coords, index) => {
            try {
                const polygon = new window.google.maps.Polygon({
                    paths: coords,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.08,
                    strokeWeight: 1,
                    fillColor: "#FF0000",
                    fillOpacity: 0.3,
                    zIndex: index,
                });
                polygon.setMap(map);
                polygonsRef.current.push(polygon);
            } catch (error) {
                console.error("Error creating polygon:", error);
            }
        });
    };

    const polygonMapKmz = data => {
        polygonsRefKmz.current.forEach(polygon => polygon.setMap(null)); // Clear existing polygons
        const aux = data.map(coord => ({ lat: coord.lat, lng: coord.long }))
        try {
            const polygon = new window.google.maps.Polygon({
                paths: aux,
                editable: isBig,
                draggable: false,
                strokeColor: "#00FF00",
                strokeOpacity: 0.08,
                strokeWeight: 1,
                fillColor: "#00FF00",
                fillOpacity: 0.3,
                zIndex: 1,
            });
            window.google.maps.event.addListener(polygon.getPath(), 'set_at', function () {
                completePolygon(polygon);
            });
            setShowDraw(false);
            polygon.setMap(map);
            polygonsRefKmz.current.push(polygon);
            // onPathDrawn(data);
        } catch (error) {
            console.error("Error creating polygon:", error);
        }
    };

    const completePolygon = data => {
        const polygonCoords = data.getPath().getArray();
        const updatedPoints = polygonCoords.map(point => {
            return {
                "lat": point.lat(),
                "long": point.lng()
            };
        });
        onPathDrawn(updatedPoints);
        // onDeletePolygonPath(false);
    }

    const mapStyles = {
        height: isBig ? "800px" : "67vh",
        width: "100%"
    };

    const clearExistingPolygons = () => {
        polygonsRefKmz.current.forEach(polygon => {
            polygon.setMap(null);
        });
        // Clear the array
        polygonsRefKmz.current = [];
    };

    return (
        <div className='ooo'>
            <GoogleMap
                key={mapKey}
                mapContainerStyle={isBig ? mapStyles : mapStyles}
                onLoad={map => setMap(map)}
                zoom={15}
                center={defaultCenter}
                options={mapOptions}
            >
                {showDraw && (
                    <DrawingManager
                        options={{
                            drawingControl: verify,
                            drawingControlOptions: {
                                position: window.google.maps.ControlPosition.TOP_CENTER,
                                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
                            },
                            polygonOptions: {
                                fillColor: `#6EFF94`,
                                fillOpacity: 0.08,
                                strokeColor: `#6EFF94`,
                                strokeWeight: 1,
                                clickable: true,
                                editable: true,
                                zIndex: 1,
                            },
                        }}
                        onPolygonComplete={(polygon) => {
                            polygonsRefKmz.current.push(polygon);
                            completePolygon(polygon);
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    );
};

export default MapComponent;
