import React, { useState } from 'react';
// import './styleManagerAlarms.css';

const ImageUploader = ({ onImageChange, status, onNewImage }) => {
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
      onImageChange(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div style={{ marginBottom: 40 }}>
        {!status?.length && (
          <label htmlFor="upload" className="custom-file-upload">
            <input
              type="file"
              id="upload"
              accept="image/*"
              onChange={handleImageChange}
            />
            Escolha uma imagem 
          </label>
        )}
        {status?.length && <button className="custom-file-upload" onClick={onNewImage}>Trocar Imagem</button>}
      </div>
      <div>
        {image && (
          <img
            src={image}
            alt="Uploaded"
            style={{ width: '75%', marginTop: '10px' }}
          />
        )}
      </div>
    </div>
  );
};



export default ImageUploader;