import axios from 'axios'
import { URL_KDI_API, TIMEOUT_REQ_LOGIN } from '../config/config';

const api = axios.create({
  timeout: TIMEOUT_REQ_LOGIN,
})

const dologin = (data) => {
  return api.post(URL_KDI_API + 'auth/login', data);
};

export default dologin;
