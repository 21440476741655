import styled from 'styled-components';

export const Container = styled.div`
    padding: 1px 3px 3px 3px;
    background-color: rgb(230, 230, 230);
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const TitleButton = styled.div`
    align-self: center;
    margin: auto;
`;

export const ButtonSelected = styled.button`
    background-color: #6eff94;
    cursor: auto;
    padding-left: 5px;
    padding-right: 5px;
    height: 22px;
    border: 1px solid #6eff94;
    align-self: center;
`;

export const ButtonNoSelected = styled.button`
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #6eff94;
    height: 22px; 
    align-self: center;
`;

export const Chart = styled.div`
    background-color: #fff;
    max-height: 21vh;
    max-width: 49vw;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.$data.labels.length > 0 ? 'max-height: 21vh' : 'height: 21vh' }
`;

export const ContainerLegend = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const ItemWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const ColoredBar = styled.div`
  width: 30px;
  height: 5px;
  border: 1px solid ${props => props.$borderColor};
  border-radius: 20px;
  background-color: ${props => props.$borderColor};
  margin-right: 10px;
`;

export const ContainerReset = styled.div`
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    height: 28px;
    margin-right: 20px;
    align-self: center;
    user-select: none;
`;