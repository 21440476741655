import React, { useState, useRef, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Toast } from 'primereact/toast';
import { change_password } from '../../services/change_password';
import { ProgressSpinner } from 'primereact/progressspinner';

import { Container, Title, Greeting, FormInput, Button, Row, Col, ContainerSpinner } from './styles';

import Header from '../../components/Header';

import useUserStore from '../../store/useUserStore';

const ChangePassword = () => {
  const { user } = useUserStore();
  const intl = useIntl();
  const toast = useRef(null);
  const [userInfo, setUserInfo] = useState({
    currentPassword: '',
    newPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      id: user.user_id,
      oldPassword: userInfo.currentPassword,
      password: userInfo.newPassword
    }
    callNewPassword(payload);
    setLoading(true);
  };

  const callNewPassword = async (data) => {
    try {
      await change_password(data);
      toast.current.show({ severity: 'success', summary: intl.formatMessage({ id: "change_password_message_success" }), life: 3000 });
      setUserInfo((old) => ({ ...old, currentPassword: '', newPassword: '' }));
      setLoading(false);
    } catch (err) {
      console.error(err);
      toast.current.show({ severity: 'error', summary: intl.formatMessage({ id: "change_password_message_error_summary" }), detail: intl.formatMessage({ id: "change_password_message_error_detail" }), life: 3000 });
      setLoading(false);
    }
  }

  return (
    <div>
      <Header />
      <Toast ref={toast} />
      <Title><FormattedMessage id="message_title" /></Title>
      <Container className='container'>
        <Greeting><FormattedMessage id="greetings" /> {user?.name}</Greeting>
        <Row>
          <Col marginRight='1%'>
            <div className="form-group">
              <label htmlFor="currentPassword"><FormattedMessage id="current_password" /></label>
              <FormInput
                type="password"
                className="form-control"
                id="currentPassword"
                name="currentPassword"
                value={userInfo.currentPassword}
                onChange={handleChange}
                required
              />
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label htmlFor="newPassword"><FormattedMessage id="new_password" /></label>
              <FormInput
                type="password"
                className="form-control"
                id="newPassword"
                name="newPassword"
                value={userInfo.newPassword}
                onChange={handleChange}
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="button" onClick={handleSubmit}><FormattedMessage id="button_password" /></Button>
          </Col>
        </Row>
        <ContainerSpinner>
          {loading && <ProgressSpinner />}
        </ContainerSpinner>
      </Container>
    </div>
  );

}

export default ChangePassword;