import styled from 'styled-components';


export const Container = styled.div`
    width: 100%;
    background-color: #e0e0e0;
    height: 100%;
    border-radius: 7px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    
`;

export const Value = styled.div`
    align-self: self-end;
    display: flex;
    margin-bottom: -10px;
    
>h1 {
    font-size: ${props => props.$sizeText && props.$size ? '1vw' : (props.$size && !props.$sizeText ? '1.5vw' : !props.$size && props.$sizeText ? '2.5vw' : '5vw')};
}

>h2 {
    font-size: 30px;
    align-self: flex-end;
    margin-bottom: 24px;
}
`;

export const Content = styled.div`
    position: relative;

> img {
    margin-top: ${props => props.$sizeText ? '10px' : '10px'};;
    height: 50%;
    position: absolute;
    top: 35%;
    left: -5px;
}

> span {
    font-size:  ${props => props.$sizeText ? '1.2vw' : '1.3vw'};
    font-weight: 500;
}
`;

