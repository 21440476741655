import React from "react";
import { Dropdown } from "primereact/dropdown";
import devs from "../../../../assets/img/dropdown/exclamation.png";

const DropdownCompAlarm = ( { data, handleDropdownChangeAlarm, value} ) => {

  const imageBodyEvent = (alarmTypeId) => {
    const alarmTypeImages = {
        "Falta de Água": "falta_agua.png",
        "Fechamento de registro de rede": "fechamento.png",
        "Mistura de setor": "mistura.png",
        "Reparo de vazamento": "reparo.png",
        "Suspeita de Fraude ou usos não medidos": "suspeita.png",
        "Vazamento": "vazamento.png",
    };
  
    if (alarmTypeId in alarmTypeImages) {
        return <img src={require(`../../../../assets/img/dropdown/${alarmTypeImages[alarmTypeId]}`)} alt={"Imagem"} className="w-4rem" style={{ width: '24px', marginRight: '10px' }}/>
    } else {
        return <img src={devs} alt={""} className="w-4rem" style={{ width: '24px', marginRight: '10px' }} />
    }
  
  };
 

  const alarmTemplate = (option) => {
    return (
      <div className="car-option">
        {imageBodyEvent(option.alarmTypeName)}
        <span>{option.alarmTypeName}</span>
      </div>
    );
  };

  const selectedAlarmTemplate = (option, props) => {
    if (option) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-around', alignSelf: 'center' }}>
           {imageBodyEvent(option.alarmTypeName)}
          <div style={{ alignSelf: 'center' }}>{option.alarmTypeName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  return (
    <div>
      <Dropdown
        options={data}
        value={value}
        valueTemplate={selectedAlarmTemplate}
        onChange={handleDropdownChangeAlarm}
        optionLabel="alarmTypeName"
        optionValue="alarmTypeId"
        itemTemplate={alarmTemplate}
        placeholder="Selecione o tipo de alarme"
        style={{ width: '100%', marginTop: "8px", backgroundColor: 'rgb(230, 230, 230' }}
      />
    </div>
  );
};

export default DropdownCompAlarm;
