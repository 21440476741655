import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, DrawingManager, Marker, Polyline, MarkerClusterer } from '@react-google-maps/api';
import _debounce from 'lodash/debounce';
import { mapStyle } from '../../../utils/MapStyles';

const calculateAverage = (points, prop) => {
    const sum = points.reduce((acc, point) => acc + point[prop], 0);
    return sum / points.length;
};

const MapComponent = ({ data, isBig, deletePolygon, onPath, onDevices, kmlData, tubes, totalDvc }) => {
    const drawnPolygonRef = useRef([]);
    const [showDraw, setShowDraw] = useState(false);
    const [create, setCreate] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const [defaultCenter, setDefaultCenter] = useState({ lat: -23.47003808219593, lng: -47.417858840616105 });

    const [pipeCoord, setPipeCoord] = useState(null);

    const [kmlDataMap, setKmlDataMap] = useState(null);

    const [polyState, setPolyState] = useState([]);

    const [mapKey, setMapKey] = useState(0);

    const [allDvc, setAllDvc] = useState([]);

    useEffect(() => {
        polyState.forEach(polygon => {
            if (polygon.setMap) {
                polygon.setMap(drawingManager.getMap());
            }
        });

        setTimeout(() => {
            const markersInside = polyState.flatMap(polygon => findMarkersInsidePolygon(polygon, data));
            onDevices(markersInside);
        }, 500);
    }, [polyState]);

    useEffect(() => {
        setPipeCoord(tubes);
    }, [tubes])

    useEffect(() => {
        if (totalDvc) {
            const list = data.map(device => device.device_id);
            const finalList = totalDvc.filter(device => {
                if (!list.includes(device.device_id)) {
                    device.all = true;
                    return true;
                }
                return false;
            });
            setAllDvc(finalList);
        }
    }, [totalDvc])

    useEffect(() => {
        if (isBig) {
            setCreate(true);
        } else if (!isBig) {
            setCreate(false);
        }
    }, [isBig])

    useEffect(() => {
        if (deletePolygon) {
            onDeletePolygon();
        }
        if (kmlDataMap) {
            reloadMap();
        }
    }, [deletePolygon])

    useEffect(() => {
        if (kmlData) {
            setKmlDataMap(kmlData);
            const allCoordinates = kmlData.flat();
            const changeLabel = allCoordinates.map(obj => ({
                lat: obj.latitude,
                long: obj.longitude
            }));
            onPath(changeLabel);
        }
    }, [kmlData])

    useEffect(() => {
        debounced();
        debouncedB();
        return debounced.cancel, debouncedB.cancel;
    }, [])

    const debounced = _debounce(() => {
        if (!drawnPolygonRef.current.length) {
            setShowDraw(true);
        }
    }, 2000);

    const debouncedB = _debounce(() => {
        const elements = document.querySelectorAll('.ooo [role="menubar"]');

        if (elements.length > 1) {
            elements.forEach((element, index) => {
                if (index === 1) {
                    element.style.display = 'none';
                }
            });
        }
    }, 2050);

    const reloadMap = () => {
        setMapKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        if (data.length > 0) {
            const avgLat = calculateAverage(data, 'lat');
            const avgLng = calculateAverage(data, 'long');
            setDefaultCenter({ lat: avgLat, lng: avgLng });
        }
    }, [data]);

    useEffect(() => {
        if (kmlData && kmlData.length > 0) {
            const allCoordinates = kmlData.flat();
            const avgLat = calculateAverage(allCoordinates, 'latitude');
            const avgLng = calculateAverage(allCoordinates, 'longitude');
            setDefaultCenter({ lat: avgLat, lng: avgLng });
            setShowDraw(true);
        } else {
            setShowDraw(false);
        }
    }, [kmlData]);

    const mapStyles = {
        height: "67vh",
        width: "100%"
    };

    const mapStylesBig = {
        height: "800px",
        width: "100%"
    };

    const onDeletePolygon = () => {
        setKmlDataMap(null);
        drawnPolygonRef.current.forEach(polygon => polygon.setMap(null));
        drawnPolygonRef.current = [];
        setIsDrawing(false);
    }

    const findMarkersInsidePolygon = (polygon, points) => {
        return points.filter(point => {
            const markerLatLng = new window.google.maps.LatLng(point.lat, point.long);
            return window.google.maps.geometry.poly.containsLocation(markerLatLng, polygon);
        });
    };

    const onPolygonComplete = (polygon) => {
        drawnPolygonRef.current.push(polygon);
        setIsDrawing(true);
        const polygonCoords = polygon.getPath().getArray();
        const updatedPoints = polygonCoords.map(point => ({
            lat: point.lat(),
            long: point.lng()
        }));

        const markersInside = data.filter(point => {
            const markerLatLng = new window.google.maps.LatLng(point.lat, point.long);
            return window.google.maps.geometry.poly.containsLocation(markerLatLng, polygon);
        });

        onPath(updatedPoints);
        onDevices(markersInside);
    }

    const mapOptions = {
        zoomControl: true,
        styles: mapStyle,
        fullscreenControl: true
    };

    const [drawingManager, setDrawingManager] = useState(null);

    const onDrawingManagerLoad = (drawingManager) => {
        setDrawingManager(drawingManager);
    };

    const drawPolygonFromKmlData = () => {
        if (drawingManager && kmlData && kmlData.length > 0) {
            const polygons = kmlData.map(polygonData => {
                const coordinates = polygonData.map(point => ({
                    lat: point.latitude,
                    lng: point.longitude
                }));

                const polygonOptions = {
                    paths: coordinates,
                    fillColor: `#6EFF94`,
                    fillOpacity: 0.08,
                    strokeColor: `#6EFF94`,
                    strokeWeight: 2,
                    clickable: true,
                    editable: false,
                    zIndex: 1
                };

                const polygon = new window.google.maps.Polygon(polygonOptions);
                polygon.setMap(drawingManager.getMap());
                return polygon;
            });

            setPolyState(polygons);
        }
    };

    useEffect(() => {
        drawPolygonFromKmlData();
    }, [drawingManager, kmlDataMap, data]);

    const createSvgMarker = (label) => `
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none">
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" fill="#88c55a"/>
            <text x="50%" y="50%" text-anchor="middle" stroke="black" stroke-width="0.5px" dy=".3em" font-family="Roboto" font-size="6px" fill="black">${label}</text>
        </svg>
    `;

    const markerOptions = React.useCallback((label) => ({
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(createSvgMarker(label))}`,
        scaledSize: new window.google.maps.Size(48, 48),
    }), []);

    const MarkerComponent = React.memo(({ point }) => {
        const label = point.serial_number ? point.serial_number.slice(-4) : point.serialNumber.slice(-4);
        return (
            <Marker
                position={{ lat: point.lat, lng: point.long }}
                icon={markerOptions(label)}
            />
        );
    });

    return (
        <div className='ooo'>
            <GoogleMap
                key={mapKey}
                mapContainerStyle={create ? mapStylesBig : mapStyles}
                zoom={15}
                center={defaultCenter}
                options={mapOptions}
            >

                {allDvc.map((point, index) => (
                    <MarkerComponent key={index} point={point} />
                ))}
                {data.map((point, index) => (
                    <MarkerComponent key={index} point={point} />
                ))}
                {pipeCoord && pipeCoord.map((line, index) => {
                    return <Polyline
                        key={index}
                        path={[{ lat: line.x0, lng: line.y0 }, { lat: line.x1, lng: line.y1 }]}
                        options={{
                            strokeColor: "#FF0000",
                            strokeOpacity: 1,
                            strokeWeight: 1,
                        }}
                    />
                })}
                {showDraw && (
                    <DrawingManager
                        onLoad={onDrawingManagerLoad}
                        onPolygonComplete={onPolygonComplete}
                        options={{
                            drawingControl: !kmlDataMap && create,
                            drawingControlOptions: {
                                drawingModes: [
                                    window.google.maps.drawing.OverlayType.POLYGON
                                ],
                                position: window.google.maps.ControlPosition.TOP_CENTER
                            },
                            polygonOptions: {
                                fillColor: `#6EFF94`,
                                fillOpacity: 0.08,
                                strokeColor: `#6EFF94`,
                                strokeWeight: 1,
                                clickable: true,
                                editable: false,
                                zIndex: 1
                            }
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    );
};

export default MapComponent;

