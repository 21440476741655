import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useCompanySectorStore = create(
  persist(
    (set) => ({
      clientIdSelected: null,
      sectorIdSelected: null,
      startDateSelected: null,
      endDateSelected: null,
      setClientIdSelected: (clientIdSelected) => set({ clientIdSelected }),
      setSectorIdSelected: (sectorIdSelected) => set({ sectorIdSelected }),
      setStartDateSelected: (startDateSelected) => set({ startDateSelected }),
      setEndDateSelected: (endDateSelected) => set({ endDateSelected })
    }),
    {
      name: '@ada-company-sector',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useCompanySectorStore;