import React, { useState } from 'react';

import { 
    ModalWrapper,
    ModalContent,
    ButtonContent,
    DeleteButton,
    CancelButton
 } from "./styles";

export const ModalDelete = ({ onDelete, onCancel, sectorData }) => {
    
    return (
        <ModalWrapper>
            <ModalContent>
                <h6>Deseja realmente excluir o Setor abaixo?</h6>
                <h5>{sectorData ? sectorData.sectorName : ''}</h5>
                <ButtonContent>
                    <DeleteButton onClick={onDelete}>Excluir</DeleteButton>
                    <CancelButton onClick={onCancel}>Cancelar</CancelButton>
                </ButtonContent>
            </ModalContent>
        </ModalWrapper>
    );
};
