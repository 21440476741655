import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: calc(50vh - 221px);
  z-index: 5;
  right: 0;
  background-color: white;
  text-align: center;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  font-size: 10px;
`;

export const Code = styled.code`
  font-size: 10px;
`;

export const ColorBar = styled.div`
  width: 35px;
  height: 143px;
  display: flex;
  flex-direction: column;
`;

export const ColorBlock = styled.div`
  flex: 1;
  background-color: ${({ $color }) => $color}; 
`;
