import React, { useState, useRef } from 'react'
import {
  Container,
  Logo,
  Form,
  FormTitle,
  BackGround,
  Content,
  ContentForm,
  ContainerForgotPassword
} from './styles'
import background_img from '../../assets/img/login/background_img.png'
import logo_2 from '../../assets/img/login/logo_2.png';
import Input from '../../components/InputLogin';
import Button from '../../components/ButtonLogin';

import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import forgotPassword from '../../services/forgotPassword';

import { Toast } from 'primereact/toast';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [resultRequest, setResultRequest] = useState(false);
  const toast = useRef(null);
  const intl = useIntl();

  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      setResultRequest(true);
      const payload = {
        email: email
      };

      const response = await forgotPassword(payload);
      if (response) {
        setResultRequest(false);
        showSuccess();
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }

    } catch (error) {
      setResultRequest(false);
      showError(error);
    }


  };

  const showError = (error) => {
    if (error.response) {
      const msg = error.response.data.message;
      toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 5000 });
    } else if (error.request) {
      const message = error.message;
      toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    }
  }


  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: intl.formatMessage({ id: "success_forgot_summary" }), detail: intl.formatMessage({ id: "success_forgot_detail" }), life: 3000 });
  }

  return (
    <Content>
      <Toast ref={toast} />
      <Container>
        <BackGround>
          <img src={background_img} alt='background' />
        </BackGround>
        <ContentForm>
          <Logo>
            <img src={logo_2} alt='ADA' />
            <h1>Ada</h1>
          </Logo>
          <Form onSubmit={handleSignIn}>
            <FormTitle><FormattedMessage id="reset_password" /></FormTitle>
            <FormTitle><FormattedMessage id="email" /></FormTitle>
            <Input
              type='email'
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type='submit'
              disabled={resultRequest}
            >
              {resultRequest ? <FormattedMessage id="wait" /> : <FormattedMessage id="reset" />}
            </Button>
          </Form>
        </ContentForm>
      </Container>
    </Content>
  )
}

export default ForgotPassword;