import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Title, CustomFormCheck, FormCheck } from './styles';

const CommandBar = ({ kindPin, setKindPin, delta, setDelta, isolinhas, setIsolinhas }) => {
    return (
      <Container>
        <Title><FormattedMessage id="settings" /></Title>
        <CustomFormCheck
          type={'radio'}
          name={'kind'}
          label={<small><FormattedMessage id="hydraulic_load_title" /></small>}
          onClick={() => { setKindPin("value"); setDelta(!delta); }}
          checked={kindPin === "value"}
          readOnly
        />
        <CustomFormCheck
          type={'radio'}
          name={'kind'}
          label={<small><FormattedMessage id="delta_mnf" /></small>}
          onClick={() => setDelta(!delta)}
          checked={delta}
          readOnly
        />
        <CustomFormCheck
          type={'checkbox'}
          name={'isolinhas'}
          label={<small><FormattedMessage id="isolines" /></small>}
          onClick={() => setIsolinhas(!isolinhas)}
          checked={isolinhas}
          readOnly
        />
      </Container>
    );
  }
  
  export default CommandBar;
  
