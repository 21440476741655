import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
`;

export const Heading1 = styled.h1`
  margin-top: 70px;
  margin-left: 50px;
`;

export const Heading4 = styled.h4`
  margin-top: 70px;
  margin-left: 50px;
`;

export const CenteredColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ editClient, confirmClient }) => (editClient ? '60px' : confirmClient ? '60px' : '20%')};
`;

export const ContainerMyButton = styled.div`
    display: flex;
    justify-content: center;
`;

export const ContainerDropDown = styled.div`
    margin-top: ${({ $confirmClient }) => ( $confirmClient ? '60px' : '20%')};
`;

export const ContainerTable = styled.div`
    margin-inline: auto;
    margin-top: 10px;
    display: flex;
    justify-content: center;
`;

export const MyButton = styled.div`
    width: 150px;
    margin-top: 70px;
    background-color: ${props => props.theme.colors.warning};
    cursor: pointer;
    padding: 10px;
    text-align: center;
    color: ${props => props.theme.colors.white}; 
    border-radius: 5px;
`;

