import React from "react";
import { Dropdown } from "primereact/dropdown";

const DropdownComp = ({ data, handleDropdown, value}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Dropdown
        value={value}
        onChange={handleDropdown}
        options={data}
        optionLabel="tradingName"
        placeholder="Selecionar cliente"
        className="w-full md:w-14rem"
        style={{ backgroundColor: "rgb(128, 134, 192)", width: 500, marginTop: "8px" }}
      />
    </div>
  );
};

export default DropdownComp;

