import axios from 'axios';
import { ADA_API_URL as ADA_BASE_URL, TIMEOUT_REQ } from '../config/config';

const api_ada = axios.create({
  baseURL: ADA_BASE_URL,
  timeout: TIMEOUT_REQ,
});


api_ada.interceptors.request.use(async (config) => {
  const userData = JSON.parse(sessionStorage.getItem('@ada-user'));
  const token = userData.state.user.token;
  if (userData && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api_ada;