import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 500px;
  margin-top: 14%;
`;

export const Title = styled.h2`
  margin-top: 20px;
`;

export const Form = styled.form`
  margin-top: 20px;
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  margin-bottom: 5px;
  display: block;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

export const Button = styled.button`
  padding: 10px 20px;
`;