import { create } from 'zustand';

const initialClientSelectedState = {
  id: '',
  tradingName: '',
};

const useClientSelectedStore = create((set) => ({
  clientSelected: initialClientSelectedState,
  setClientSelected: (value) => set({ clientSelected: value }),
}));

export default useClientSelectedStore;