import React, { useState, useEffect, useRef } from 'react'
import _debounce from 'lodash/debounce';
import { Toast } from 'primereact/toast';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import JSZip from 'jszip';

import Header from '../../components/Header';
import { clients_all } from '../../services/alarms';
import {
    FormContainer,
    StyledInput,
    StyledMultiSelect,
    StyledButton,
    Label,
    FileInputLabel,
    FileLabel,
    StyleTitle,
    ContainerButtonMap,
} from './styles';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MapMacroSector from '../../components/Maps/CreateMacroSector ';

import { useNavigate, useParams } from 'react-router-dom';
import { create_macro_sector, list_macro_sector } from '../../services/macro_sector';

const CreateMacroSector = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const navigate = useNavigate();
    const [selectedClient, setSelectedClient] = useState(null);
    const [sectorsList, setSectorsList] = useState(null);
    const [sector, setSector] = useState(null);
    const [nameMacroSector, setNameMacroSector] = useState('');
    const [fileName, setFileName] = useState({
        sector: null,
        web: null
    });
    const [isCreate, setIsCreate] = useState(false);
    const [deleteSector, setDeleteSector] = useState(false);


    const [kmlData, setKmlData] = useState(null);

    const [selectAll, setSelectAll] = useState(false);
    const [sectorsMap, setSectorsMap] = useState([]);
    const [pathDrawn, setPathDrawn] = useState(null);

    const [mandatoryItem, setMandatoryItem] = useState(false);

    useEffect(() => {
        debouncedGetAllClients();
        return debouncedGetAllClients.cancel;
    }, []);

    const debouncedGetAllClients = _debounce(() => {
        getAllClients();
    }, 1);

    useEffect(() => {
        if (sectorsList && sector) {
            const aux = sector.map(sectorId => {
                const foundSector = sectorsList.find(sector => sector.sectorId === sectorId);
                if (foundSector) {
                    return {
                        "sectorId": foundSector.sectorId,
                        "sectorName": foundSector.sectorName,
                        "sectorGeoDelimitation": foundSector.sectorGeoDelimitation,
                        "sectorCode": foundSector.sectorCode,
                        "isMaster": false
                    };
                } else {
                    return {
                        "sectorId": "",
                        "sectorName": "",
                        "sectorGeoDelimitation": "",
                        "sectorCode": "",
                        "isMaster": false
                    };
                }
            });
            setSectorsMap(aux)
        }

    }, [sector])

    const getAllClients = async () => {
        try {
            const response = await clients_all();
            const clientObject = response.data.find(client => client.id === id);
            setSelectedClient(clientObject);
            getSectorByClient(clientObject);
        } catch (error) {
            if (error.response) {
                const msg = error.response.data.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
            } else if (error.request) {
                const message = error.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
            }
        }
    };

    const getSectorByClient = async (data) => {
        if (data) {
            const payload = {
                'clientId': data.id,
                'isMaster': false
            };

            try {
                const response = await list_macro_sector(payload);
                const aux = response.data.filter(sector => !sector.isMaster);
                setSectorsList(aux);
            } catch (error) {
                if (error.response) {
                    const msg = error.response.data.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
                } else if (error.request) {
                    const message = error.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
                }
            }
        }
    };

    const handleSend = async () => {
        if (nameMacroSector && nameMacroSector.length > 0) {
            if (selectedClient) {

                const withoutSectorGeoDelimitation = sectorsMap.map(sector => {
                    const { sectorGeoDelimitation, ...rest } = sector;
                    return rest;
                });

                const data = {
                    "sectorName": nameMacroSector,
                    "sectorCode": `Code ${nameMacroSector}`,
                    "clientId": selectedClient.id,
                    "isMaster": true,
                    "sectorGeoDelimitation": kmlData && kmlData.length > 0 ? kmlData : pathDrawn,
                    "subSector": withoutSectorGeoDelimitation
                }

                try {
                    await create_macro_sector(data);
                    toast.current.show({ severity: 'success', summary: 'Setor criado com sucesso.', life: 3000 });
                    setTimeout(() => {
                        navigate('/manager-macro-sector');
                    }, 1500);
                } catch (error) {
                    if (error.response) {
                        const msg = error.response.data.message;
                        toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
                    } else if (error.request) {
                        const message = error.message;
                        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
                    }
                }
            }
        }
        if (!nameMacroSector || nameMacroSector.trim().length === 0) {
            setMandatoryItem(true);
        }
    };

    const handleFileRead = (data) => {
        const updatedCoord = data;
        parseKML(updatedCoord);
    };


    const handleDeletePolygon = () => {
        setKmlData(null);
        setDeleteSector(true);
        setFileName(prevFileName => ({ ...prevFileName, sector: '' }));
    };

    const parseKML = (content) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, 'text/xml');

        const coordinatesNode = xmlDoc.querySelector('coordinates');
        const coordinatesText = coordinatesNode.textContent.trim();
        const coordinatesArray = coordinatesText.split(' ');

        const polygonCoordinates = coordinatesArray.map(coord => {
            const [longitude, latitude] = coord.split(',').map(parseFloat);
            return { latitude, longitude };
        });
        const formatCoord = polygonCoordinates.map(coord => ({ lat: coord.latitude, long: coord.longitude }))
        setKmlData(formatCoord);
    };

    const callReturn = () => {
        navigate('/manager-macro-sector');
    };

    const handleSectorSelection = (e) => {
        setSector(e.value);
        setSelectAll(e.value.length === sectorsList.length);
    };

    const handleSelectAllSectors = (e) => {
        setSector(!e.checked ? sectorsList.map(sect => sect.sectorId) : []);
        setSelectAll(!e.checked);
    };

    const handlePathDrawn = data => {
        setPathDrawn(data);
    };

    const handleDeletePath = data => {
        setDeleteSector(data);
    };

    return (
        <>
            <Header />
            <Toast ref={toast} />
            <Container>
                {!isCreate && <StyleTitle>Criar Macro Setor</StyleTitle>}
                <FormContainer>
                    <Row>
                        <Col style={{ marginTop: 14 }}>
                            <MapMacroSector coordSector={sectorsMap} isBig={isCreate} deletePolygon={deleteSector} kmlData={kmlData} onPathDrawn={handlePathDrawn} onDeletePolygonPath={handleDeletePath} />
                            <ContainerButtonMap $data={kmlData && kmlData.length > 0 ? true : false}>
                                <button disabled={kmlData && kmlData.length > 0 ? true : false} onClick={() => setIsCreate(!isCreate)}>
                                    {!isCreate ? 'Criar Polígono' : 'Fechar'}
                                </button>
                                <button onClick={handleDeletePolygon}>Apagar Polígono</button>
                            </ContainerButtonMap>
                        </Col>
                        {!isCreate && <>
                            <Col>

                                <Label>Cliente</Label>
                                <span>{selectedClient && selectedClient.tradingName}</span>

                                <Label>Nome do Macro Setor</Label>
                                <StyledInput $mandatary={mandatoryItem} type="text" onChange={(e) => setNameMacroSector(e.target.value)} />
                                {mandatoryItem && <div style={{ display: 'flex', justifyContent: 'right' }}><label style={{ color: 'red', fontSize: 14 }}>Item Obrigatório !</label></div>}

                                <Label>Setores</Label>

                                <StyledMultiSelect
                                    filter
                                    value={sector}
                                    options={sectorsList ? sectorsList.map(sector => ({ label: sector.sectorName, value: sector.sectorId })) : []}
                                    onChange={handleSectorSelection}
                                    selectAll={selectAll}
                                    onSelectAll={handleSelectAllSectors}
                                    virtualScrollerOptions={{ itemSize: 43 }}
                                    maxSelectedLabels={3}
                                    placeholder="Selecione os Setores"
                                    className="w-full md:w-20rem"
                                />

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <Label>Área do Macro Setor</Label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FileInputLabel>
                                                Escolha um arquivo
                                                <input
                                                    type="file"
                                                    accept=".kmz"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        setDeleteSector(false);
                                                        try {
                                                            const reader = new FileReader();
                                                            reader.onload = async (event) => {
                                                                const zip = new JSZip();
                                                                const zipFile = await zip.loadAsync(event.target.result);
                                                                const nameFile = Object.keys(zipFile.files);
                                                                const kmlContent = await zipFile.file(`${nameFile[0]}`).async('text');
                                                                setFileName(prevFileName => ({ ...prevFileName, sector: nameFile }));
                                                                handleFileRead(kmlContent);
                                                                e.target.value = null;
                                                            };
                                                            reader.readAsArrayBuffer(file);
                                                        } catch (error) {
                                                            console.error('Erro ao ler o arquivo KMZ:', error);
                                                        }
                                                    }}
                                                />
                                            </FileInputLabel>
                                            <FileLabel> {fileName && fileName.sector && <div> {fileName.sector}</div>}</FileLabel>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </>}
                    </Row>
                </FormContainer>
                {!isCreate && <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                    <StyledButton onClick={callReturn}>Cancelar</StyledButton>
                    <StyledButton $data={true} onClick={handleSend}>Salvar</StyledButton>
                </div>}
            </Container>
        </>
    )
}

export default CreateMacroSector