import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const useUserStore = create(
  persist(
    (set) => ({
      user: [], 
      setUser: (newUser) => set({ user: newUser }),
    }),
    {
      name: '@ada-user',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);


export default useUserStore;
