import styled, { css } from 'styled-components';

export const Content = styled.div`
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
`;

export const ContentForm = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    background-color: #fff;
`;

export const BackGround = styled.div`
    > img {
        max-width: 100%;
        max-height: auto;
    }
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    > h1 {
        margin-top: 18px;
    }

    > img {
        width: 60px;
        height: 60px;
    }
`;

export const Form = styled.form`
    width: 350px;

    padding: 3px;
    border-radius: 10px;
`;

export const FormTitle = styled.h5`
    color: grey;
`;

export const ContainerForgotPassword = styled.a`
    width: 350px;
    text-align: end;
    text-decoration: none;
    margin-top: 30px;
    color: #000;
`;
