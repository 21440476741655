import React from 'react';
import { useIntl } from 'react-intl';
import { Container, Labels, Label, Code, ColorBar, ColorBlock } from './styles';

const ColorScale = ({ isolinhas, kindPin, minVal, maxVal, delta }) => {
    const intl = useIntl();

    const colors = [
        'rgb(0, 255, 0)',
        'rgba(25, 150, 100, 0.76)',
        'rgb(155, 165, 0)',
        'rgb(255, 255, 0)',
        'rgb(255, 189, 14)',
        'rgb(255, 140, 0)',
        'rgb(196, 87, 23)',
        'rgb(234, 47, 58)',
        'rgb(170, 0, 0)',
        'rgb(124, 0, 152)'
    ];

    const isNumeric = !isNaN(parseFloat(minVal)) && isFinite(minVal) && !isNaN(parseFloat(maxVal)) && isFinite(maxVal);

    if (isNumeric) {
        return (isolinhas || kindPin === "value") && (
            <Container>
                <Labels>
                    <Label>{intl.formatMessage({ id: "scale_colors" })}</Label>
                    <Label>{delta ? "Delta" : intl.formatMessage({ id: "scale_colors_h_l" })}</Label>
                </Labels>
                <Code>{delta ? 10 : maxVal}</Code>
                <center>
                    <ColorBar>
                        {colors.map((color, index) => (
                            <ColorBlock key={index} $color={color} />
                        ))}
                    </ColorBar>
                </center>
                <Code>{delta ? -10 : minVal}</Code>
            </Container>
        );
    } else {
        return null;
    }
}

export default ColorScale;