import React, { useState } from 'react'
import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Container,
  Logo,
  Form,
  FormTitle,
  BackGround,
  Content,
  ContentForm,
  ContainerForgotPassword,
  LoadingOverlay,
  LoadingSpinnerWrapper,
  LoadingMessageWrapper,
  LoadingMessage
} from './styles'

import Input from '../../components/InputLogin';
import Button from '../../components/ButtonLogin';

import background_img from '../../assets/img/login/background_img.png'
import logo_2 from '../../assets/img/login/logo_2.png';

const Signin = () => {

  const { login } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tokenKeycloak, setTokenKeycloak] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await login(email, password, tokenKeycloak);
    if (login) {
      if (response) {
        localStorage.setItem('isKcAda', false);
        navigate('/home');
      } else {
        setLoading(false);
      }
    } else {
      alert('Função de autenticação não encontrada.');
      setLoading(false);
    }
  };

  return (
    <Content>
      {loading && <LoadingOverlay>
        <LoadingSpinnerWrapper />
        <LoadingMessageWrapper>
          <LoadingMessage><FormattedMessage id="loading" /></LoadingMessage>
        </LoadingMessageWrapper>
      </LoadingOverlay>
      }
      <Container>
        <BackGround>
          <img src={background_img} alt='background' />
        </BackGround>
        <ContentForm>
          <Logo>
            <img src={logo_2} alt='ADA' />
            <h1>Ada</h1>
          </Logo>
          <Form onSubmit={handleLogin}>
            <FormTitle><FormattedMessage id="email" /></FormTitle>
            <Input
              type='email'
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormTitle><FormattedMessage id="password" /></FormTitle>
            <Input
              type='password'
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type='submit'
            >
              Login
            </Button>
          </Form>
          <ContainerForgotPassword href='/forgot-password'><FormattedMessage id="forgot_password" /></ContainerForgotPassword>
        </ContentForm>
      </Container>
    </Content>
  )
}

export default Signin;