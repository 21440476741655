import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import CalHeatMap from "cal-heatmap";
import moment from "moment";
import { subscribe } from '../../utils/events';
import 'moment/locale/pt-br'
import 'moment/locale/es'
import 'moment/locale/en-gb'
import { Heatmap, ContainerLegend, BoxContainer, ColoredBox, RedBox, GreenBox, Text } from './styles';

const aux = navigator.language.split(/[-_]/)[0];
const language = aux === 'pt' ? 'pt' : aux === 'es' ? 'es' : aux === 'en' ? 'en' : 'en';

if (language !== "en") {
  if (language === "pt") {
    moment.locale("pt-br");
  } else if (language === "es") {
    moment.locale("es");
  }
} else {
  moment.locale("en-gb");
}

const RenderHeatMap = (data) => {
  const intl = useIntl();
  let noComunication = {};
  const [isReload, setReload] = useState(false);
  subscribe("emmit-heatMap", (a) => setReload(a));
  subscribe("up-heatMap-selected", (a) => setReload(a));
  const dataInit = data.dateOn;

  const diffTime = Math.abs(new Date(data.dateOn) - new Date(data.dateTo));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const formatDate = (date) => (date.toISOString().split('T')[0]);

  for (let i = 0; i < diffDays; i++) {
    const currentDateInMs = new Date(dataInit).valueOf();
    const ThirtyDaysInMs = 1000 * 60 * 60 * 24 * i;
    const calculatedDate = new Date(currentDateInMs + ThirtyDaysInMs);
    const timeStamp = new Date(formatDate(calculatedDate)).getTime().toString().slice(0, -3);
    noComunication[timeStamp] = 0;
  }

  const final = data.value ? Object.assign(data.value, noComunication) : noComunication;

  useEffect(() => {
    var cal = new CalHeatMap();
    cal.init({
      subDomain: "x_day",
      legendMargin: [0, 10, 0, 0],
      legendVerticalPosition: "center",
      domainGutter: 10,
      domainMargin: 0,
      rowLimit: 10,
      legendOrientation: "vertical",
      label: {
        position: "top"
      },
      legendColors: { min: "#ff0000", max: "#6eff94", base: "#AEAEAD" },
      itemSelector: "#cal-heat",
      domain: 'month',
      cellSize: 15,
      range: 2,
      start: dataInit,
      displayLegend: false,
      legend: [1],
      itemName: [intl.formatMessage({ id: "communication" }), intl.formatMessage({ id: "communication" })],
      subDomainTitleFormat: {
        empty: intl.formatMessage({ id: "message_empty" }),
        filled: `${intl.formatMessage({ id: "there_was" })} {count} ${intl.formatMessage({ id: "communication(s)_in" })} {date}`
      },
      legendTitleFormat: {
        lower: `${intl.formatMessage({ id: "less_than" })} {min} {name}`,
        upper: `${intl.formatMessage({ id: "bigger_then" })} {max} {name}`
      },
      data: final,
      domainLabelFormat: function (date) {
        return moment(date).format("MMMM").toUpperCase();
      },
      subDomainDateFormat: function (date) {
        return moment(date).format("LL");
      }
    });
    return () => cal = cal.destroy();
  }, [isReload]);

  return (

    <>
      <Heatmap id='cal-heat' />
      <ContainerLegend>
        <BoxContainer>
          <RedBox />
          <Text><FormattedMessage id="not_communicate" /></Text>
        </BoxContainer>
        <BoxContainer>
          <GreenBox />
          <Text><FormattedMessage id="communicated" /></Text>
        </BoxContainer>
      </ContainerLegend>
    </>

  );
}
export default RenderHeatMap;