import React, { memo, useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip as PrimeReactTooltip } from 'primereact/tooltip';
import { ALL_64_COLOR } from "../../../utils/Utils";
import { Line } from 'react-chartjs-2';
import moment from 'moment/moment';
import reset from "../../../assets/img/ICONE.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  registerables
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { OverlayPanel } from 'primereact/overlaypanel';
import 'primeicons/primeicons.css';
import 'chartjs-adapter-moment';
import useStateSidebarStore from '../../../store/useStateSidebarStore';
import useSliderStore from '../../../store/useSliderStore';

import { Container, Header, TitleChart, SettingsOpt, SettingsContainer, Content, SettingsCircle, ButtonReset, Chart } from './styles';

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

const offsetMinutes = new Date().getTimezoneOffset();
const HOURADJUST = offsetMinutes / 60;

const RenderMVNHydroLoad = ({ ref_date, region, selectedSensor, onSelectLine }) => {
  const intl = useIntl();
  const op = useRef(null);
  const { isOpen } = useStateSidebarStore();
  const { currentSlider, setCurrentSlider } = useSliderStore();
  const [verticalLineShow, setVerticalLineShow] = useState(false);
  const [verticalLinePosition, setVerticalLinePosition] = useState(null);
  const [upDateSize, setUpDateSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [newWidth, setNewWidth] = useState(0);
  const [checkedItems, setCheckedItems] = useState({});
  const [flag, setFlag] = useState(false);
  
  const lastZoomLevelRef = useRef(null);
  const myChartRefHidro = useRef(null);

  const calculateMVNHydroLoad = () => {
    const mvn_pressureMap = {};
    let maxV = null;
    let selected = null;
    const datasets = [];
    let idx = 0;

    const filteredMvnPressure = region.filter(e => ref_date == null || e.date > ref_date);
    const mvn_pressureXTemp = [...new Set(filteredMvnPressure.map(e => e.date))].sort((a, b) => new Date(a) - new Date(b));
    const mvn_pressureX = mvn_pressureXTemp.map(timestamp => timestamp + (HOURADJUST * 60 * 60 * 1000));

    filteredMvnPressure.forEach(item => {
      if (mvn_pressureMap[item.ID] === undefined) {
        mvn_pressureMap[item.ID] = {};
      }
      mvn_pressureMap[item.ID][item.date] = item.hydroLoadMvn;
      maxV = maxV === null ? item.hydroLoadMvn : Math.max(item.hydroLoadMvn, maxV);
    });

    for (const key in mvn_pressureMap) {
      const dt = mvn_pressureXTemp.map(date => mvn_pressureMap[key][date]);
      const dataset = {
        fill: false,
        borderColor: selectedSensor === key ? "#6b45ba" : (selectedSensor !== null ? "#BBBBBB" : ALL_64_COLOR[idx % 128]),
        label: key,
        data: dt,
        borderWidth: selectedSensor === key ? 2 : 1,
        hoverRadius: 8,
        pointRadius: 1,
        spanGaps: false
      };

      if (selectedSensor === key) {
        selected = dataset;
      } else {
        datasets.push(dataset);
      }

      if (selectedSensor === null) {
        idx += 4;
      }
    }

    if (selected !== null) {
      datasets.unshift(selected);
    }

    return {
      labels: mvn_pressureX,
      datasets: datasets,
      maxY: maxV
    };
  };

  useEffect(() => {
    if (currentSlider) {
      setVerticalLineShow(currentSlider.show);
      const date = moment(currentSlider.value);
      setVerticalLinePosition(date);
    }
  }, [currentSlider]);

  useEffect(() => {
    if (myChartRefHidro.current) {
      myChartRefHidro.current.resize();
      handleSelectAll();
    }
  }, [newWidth]);

  useEffect(() => {
    if (myChartRefHidro.current) {
      setTimeout(() => {
        myChartRefHidro.current.resize();
        handleSelectAll();
      }, 1000);
    }
  }, [isOpen]);

  useEffect(() => {
    handleClearAll();
    setTimeout(() => {
      handleSelectAll();
      if (myChartRefHidro.current) {
        myChartRefHidro.current.resize();
      }
    }, 2000);
  }, [upDateSize]);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width, innerHeight: height } = window;
      setUpDateSize({ width, height });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById("myElement");
    if (element) {
      const observer = new MutationObserver(() => {
        const elementWidth = element.offsetWidth;
        setNewWidth(elementWidth);
      });

      observer.observe(element, { attributes: true, childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const handleZoomComplete = (chart) => {
    if (chart) {
      const currentZoomLevelMin = chart.chart.scales['x'].min;
      const currentZoomLevelMax = chart.chart.scales['x'].max;
      lastZoomLevelRef.current = {
        min: currentZoomLevelMin,
        max: currentZoomLevelMax
      };
      setFlag(true);
    }
  };

  const resetZoomBtn = () => {
    if (myChartRefHidro && myChartRefHidro.current) {
      myChartRefHidro.current.resetZoom();
      setFlag(false);
    }
  };

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const chartData = final;
      const clickedElement = elements[0];
      const datasetIndex = clickedElement.datasetIndex;
      const dataIndex = clickedElement.index;
      const dataset = chartData.datasets[datasetIndex];
      const value = dataset.data[dataIndex];
      onSelectLine({
        label: dataset.label.replace('ID ', ''),
        value,
      })
    }
  };

  const temp = calculateMVNHydroLoad();
  const transformedData = temp.datasets.map(dataset => ({
    fill: dataset.fill,
    borderColor: dataset.borderColor,
    label: dataset.label,
    data: dataset.data.map((value, index) => ({
      x: temp.labels[index],
      y: value
    })),
    borderWidth: dataset.borderWidth,
    hoverRadius: dataset.hoverRadius,
    pointRadius: dataset.pointRadius,
    spanGaps: dataset.spanGaps
  }));
  const labels = temp.labels;
  const datasets = transformedData;
  const maxY = temp.maxY;

  const handleSelectAll = () => {
    const initialCheckedItems = datasets.reduce((acc, item) => {
      acc[item.label] = true;
      return acc;
    }, {});
    setCheckedItems(initialCheckedItems);
  };

  const handleClearAll = () => {
    setCheckedItems({});
  };

  const handleCheckboxChange = (label) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [label]: !prevCheckedItems[label],
    }));
  };
  const filteredData = datasets.filter((item) => checkedItems[item.label]);
  const final = {
    labels,
    datasets: filteredData,
    maxY
  }

  const MVN_PRESSURE_DATA = final;

  const MVN_PRESSURE_OPTS = {
    responsive: false,
    onClick: handleChartClick,
    parsing: false,
    animation: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: intl.formatMessage({ id: "hydraulic_load_subtitle" }),
          padding: 5
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: '#eef0fa',
          zeroLineColor: 'rgba(90, 113, 208, 0)',
        },
      },
      x: {
        min: flag ? lastZoomLevelRef.current.min : 'original',
        max: flag ? lastZoomLevelRef.current.max : 'original',
        type: 'time',
        time: {
          unit: 'day',
          parser: 'l',
          tooltipFormat: intl.formatMessage({ id: "day_tooltip" }),
          displayFormats: {
            'day': intl.formatMessage({ id: "day_label_x" })
          }
        },
        scaleLabel: {
          display: false,
          labelString: 'Dia do mês'
        },
        position: 'bottom',
        grid: {
          drawBorder: false,
          display: false,
          drawTicks: false
        },
        ticks: {
          marginTop: 10,
          beginAtZero: false,
          padding: 1,
          maxRotation: 0,
          minRotation: 0,
          // maxTicksLimit: 20,
        }
      },
    },
    legend: {
      display: false
    },
    elements: {
      line: {
        tension: 0
      }
    },
    tooltips: {
      backgroundColor: 'rgba(2, 171, 254, 1)',
    },
    plugins: {
      legend: {
        display: false
      },
      decimation: {
        enabled: true,
        algorithm: 'min-max'
      },
      zoom: {
        limits: {
          x: {
            min: final.labels[0],
            max: final.labels[final.labels.length - 1]
          }
        },
        pan: {
          enabled: true,
          mode: 'xy',
          modifierKey: 'ctrl',
        },
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
          drag: {
            enabled: true,
            backgroundColor: 'rgba(110, 255, 148, 0.4)',
          },
          onZoomComplete: (chart) => {
            handleZoomComplete(chart);
          },
        },
      },
      autocolors: false,
      annotation: verticalLineShow ? {
        annotations: {
          line1: {
            type: 'line',
            scaleID: 'x',
            value: verticalLinePosition,
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            borderColor: 'rgba(0, 255, 0, 0.52)',
            borderWidth: 4,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      } : {},
      tooltip: {
        caretPadding: 10
      }
    }
  };

  return (
    <Container>
      <Header>
        <PrimeReactTooltip target=".custom-target-settings" />
        <i className="pi pi-sliders-h custom-target-settings"
          style={{ fontSize: '1rem', cursor: 'pointer', color: 'green', alignSelf: 'center', marginLeft: 15 }}
          onClick={(e) => op.current.toggle(e)}
          data-pr-tooltip={intl.formatMessage({ id: "settings" })}
          data-pr-position="left"
        ></i>
        <TitleChart><FormattedMessage id="hydraulic_load_title" /></TitleChart>
        <OverlayPanel ref={op} position="top">
          <SettingsOpt>
            <PrimeReactTooltip target=".custom-target-pi-check-square" />
            <i className="pi pi-check-square custom-target-pi-check-square"
              style={{ fontSize: '1rem', cursor: 'pointer', color: 'green' }}
              onClick={handleSelectAll}
              data-pr-tooltip={intl.formatMessage({ id: "select_all" })}
              data-pr-position="left"
            ></i>
            <PrimeReactTooltip target=".custom-target-pi-stop" />
            <i className="pi pi-stop custom-target-pi-stop"
              style={{ fontSize: '1rem', cursor: 'pointer', color: 'black' }}
              onClick={handleClearAll}
              data-pr-tooltip={intl.formatMessage({ id: "clear_all" })}
              data-pr-position="right"
            ></i>
          </SettingsOpt>
          <SettingsContainer>
            {datasets.map((item, index) => (
              <Content key={index}>
                <SettingsCircle $borderColor={item.borderColor} />
                <label>
                  <input
                    type="checkbox"
                    checked={checkedItems[item.label] || false}
                    onChange={() => handleCheckboxChange(item.label)}
                  />
                  <span>  {item.label}</span>
                </label>
              </Content>
            ))}
          </SettingsContainer>
        </OverlayPanel>
        <PrimeReactTooltip target=".custom-target-btn-reset" />
        <ButtonReset className='custom-target-btn-reset'
          onClick={() => resetZoomBtn()}
          data-pr-tooltip={intl.formatMessage({ id: "reset_zoom" })}
          data-pr-position="left"
        >
          <img src={reset} width={22} alt="Reset" style={{ verticalAlign: "text-top" }} />
        </ButtonReset>
      </Header>
      <Chart $data={MVN_PRESSURE_DATA}>
        {/* <Line data={MVN_PRESSURE_DATA} options={MVN_PRESSURE_OPTS} redraw={false} ref={myChartRefHidro}  width={windowWidth}/> */}
        {MVN_PRESSURE_DATA && MVN_PRESSURE_DATA.labels.length > 0 ? <Line data={MVN_PRESSURE_DATA} options={MVN_PRESSURE_OPTS} redraw={false} ref={myChartRefHidro} /> : <FormattedMessage id="msg_no_data" />}
      </Chart>
    </Container>
  );
};

export default memo(RenderMVNHydroLoad);
