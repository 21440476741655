import axios from 'axios'
import { URL_KDI_API as BASE_URL, TIMEOUT_REQ } from '../config/config';
const userData = JSON.parse(sessionStorage.getItem('@ada-user'));
const user = userData.state.user.token;
const api = axios.create({
  baseURL: (BASE_URL),
  timeout: TIMEOUT_REQ,
  headers: { 'Authorization': `Bearer ${user}` },
})

export default api;