import styled, { css } from 'styled-components';

export const Container = styled.input`

    width: 100%;

    margin: 7px 0;
    padding: 10px;

    border-radius: 5px;
    border: black solid 1px;
    height: 60px;
    font-size: 18px;

`;