import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';

const ConfirmModal = ({show, onYesClick, onNoClick, onClose, message}) => {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (!show) {
            setVisible(false)
        }
        if (show) {
            setVisible(true)
        }
    }, [show])
    
  
    return (
      <div>  
        <ConfirmDialog
          visible={visible}
          onHide={onClose}
          message={message}
          header="Atenção"
          footer={
            <>
              <Button label="Sim" icon="pi pi-check" onClick={onYesClick} />
              <Button label="Não" icon="pi pi-times" onClick={onNoClick} className="p-button-secondary" />
            </>
          }
          // Traduções para os botões
          locale={{ accept: "Sim", reject: "Não" }}
        />
      </div>
    );
  };
  
  export default ConfirmModal;