import styled, { keyframes } from 'styled-components';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';

export const FormContainer = styled.div`
  margin: 1% auto;
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  margin-bottom: 10px;
  border: ${({ $mandatary }) => $mandatary ? '1px solid #FF0000' : '1px solid #000'};
  border-radius: 5px;
`;

export const StyledInputZ = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #000;
  border-radius: 5px;
`;

export const StyledSelect = styled(Dropdown)`
  margin-bottom: 15px;
  width: 100%;
  padding: 8px;
  height: 45px;
  border: 1px solid #000;
  > span {
    align-self: center;
  }
`;

export const StyledMultiSelect = styled(MultiSelect)`
  margin-bottom: 15px;
  width: 100%;
  padding: 8px;
  height: 45px;
  border: 1px solid #000;
  > div {
    align-self: center;
  }
`;

export const StyledButton = styled.button`
  border-radius: 5px;
  padding: 14px 26px;
  font-size: 20px;
  background-color:  ${({ $data, theme }) => $data ? theme.colors.success : theme.colors.warning};
  color: #000;
  border: none;
  cursor: pointer;
  margin-top: 10px;
`;

export const FileInput = styled.input`
  display: none;
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-top: 10px;
`;

export const FileInputLabel = styled.label`
  border-radius: 5px;
  display: inline-block;
  padding: 8px 16px;
  background-color: ${({ $disabled }) => $disabled ? '#bababa' : '#007bff'};
  color: #fff;
  cursor: ${({ $disabled }) => $disabled ? 'auto' : 'pointer'};
`;

export const FileLabel = styled.span`
  font-weight: bold;
  margin-left: 10px;
`;

export const StyleTitle = styled.h2`
  margin-top: 1%
`;

export const ContainerButtonMap = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  >button {
    border-radius: 5px;
    color: #fff;
    padding: 8px 16px;
    /* ${({ $data }) => $data ? 'space-between' : 'center'}; */
    &:nth-child(1) {
      background-color:  ${props => props.theme.colors.primary};
      cursor: pointer;
    }
    &:nth-child(2) {
      background-color: ${props => props.theme.colors.warning};
      
    }
  }
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const LoaderWrapper = styled.div`
    position: absolute;
    top: 34%;
    left: 26%;
    transform: translate(-50%, -50%);
    width: ${props => props.$size || '80px'};
    height: ${props => props.$size || '80px'};
    border: 30px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db;
    border-radius: 50%;
    animation: ${spin} 1s linear infinite;
    z-index: 999;
`;