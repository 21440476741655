import React from 'react'
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import { MiniCalendarAlarms } from '../../MiniCalendar/index';
import {
    ContainerCardTop,
    ContainerCardImgTop,
    TitleCardTypeAlarm,
    ContainerCardCheckbox,
    TitleCardSeen,
    ContainerCardCenter,
    ContainerCardCalendar,
    CardItemTitleCenter,
    CardItemCenter,
    ContainerCardBottom,
    ContainerCardButtons,
    CustomButton, 
    CustomCard
} from './styles'

const AlarmCardList = ({ option, alarmSeeChange, remove, addBack, verifyButtonClick }) => {
    const intl = useIntl();

    const getImage = (alarmTypeId, isEvent) => {
        const alarmTypeImages = {
            "Falta de Água": "falta_agua_red.png",
            "Fechamento de registro de rede": "fechamento_red.png",
            "Mistura de setor": "mistura_red.png",
            "Reparo de vazamento": "reparo_red.png",
            "Suspeita de Fraude ou usos não medidos": "suspeita_red.png",
            "Vazamento": "vazament_red.png",
        };
    
        const imageName = alarmTypeId in alarmTypeImages ? alarmTypeImages[alarmTypeId] : "exclamation.png";
        const imgSrc = require(`../../../assets/img/dropdown/${imageName}`);
        const imgStyle = { width: isEvent ? 60 : 40, marginLeft: isEvent ? 20 : 0 };
    
        return <img src={imgSrc} alt="" className="w-4rem" style={imgStyle} />;
    };

    return <CustomCard>
        <ContainerCardTop>
            <ContainerCardImgTop>
                {getImage(option?.alarmTypeName, false)}
            </ContainerCardImgTop>
            <TitleCardTypeAlarm><FormattedMessage id={`${option?.alarmTypeName}`} /></TitleCardTypeAlarm>
            <ContainerCardCheckbox>
                <TitleCardSeen><FormattedMessage id="seen" /></TitleCardSeen>
                <Checkbox
                    checked={option.seen}
                    onChange={(e) => alarmSeeChange(option, e.target.checked)}
                />
            </ContainerCardCheckbox>
        </ContainerCardTop>
        <ContainerCardCenter>
            <ContainerCardCalendar>
                {MiniCalendarAlarms(new Date(option.alarmNoteDate))}
            </ContainerCardCalendar>
            <div className='card-item'>
                <div><CardItemTitleCenter><FormattedMessage id="identifier" />: </CardItemTitleCenter><CardItemCenter>{option.alarmNoteId}</CardItemCenter></div>
                <div><CardItemTitleCenter><FormattedMessage id="description" />: </CardItemTitleCenter><CardItemCenter>{option.shortDescription}</CardItemCenter></div>
            </div>
        </ContainerCardCenter>
        <ContainerCardBottom>
            <ContainerCardButtons>
                {option && option.removedByUser === false ?
                    <CustomButton label={intl.formatMessage({ id: "remove" })} icon="pi pi-folder" iconPos="left" rounded size="small" severity="secondary" onClick={() => remove(option)} />
                    : <CustomButton label={intl.formatMessage({ id: "add_back" })} icon="pi pi-folder" iconPos="left" rounded size="small" severity="secondary" onClick={() => addBack(option)} />
                }
            </ContainerCardButtons>

            <ContainerCardButtons>
                <CustomButton label={intl.formatMessage({ id: "verify" })} icon="pi pi-external-link" iconPos="left" rounded size="small" severity="info" onClick={() => verifyButtonClick(option)} />
            </ContainerCardButtons>
        </ContainerCardBottom>
    </CustomCard>
}

export default AlarmCardList