import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { MdCameraAlt } from 'react-icons/md';
import { Container } from './styles';

const ButtonCapture = ({onButtonClick}) => {
  return (
    <Container onClick={onButtonClick}>
      <Tooltip target=".custom-target-camera" />
      <MdCameraAlt size={25} />
    </Container>
  );
}

export default ButtonCapture;
