import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContent = styled.div`
  height: 200px;
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 1000;
`;

export const ButtonContent = styled.div`
display: flex;
justify-content: space-around;
margin-top: 60px;
`;

export const Button = styled.button`
  margin: 0 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const DeleteButton = styled(Button)`
  background-color: #ff3333;
  color: white;
`;

export const CancelButton = styled(Button)`
  background-color: #cccccc;
  color: black;
`;