import api from './api.js'

const userData = JSON.parse(sessionStorage.getItem('@ada-user'));
const token = userData.state.user.token;

const getHeader = () => ({
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})

export const change_password = (data) => {
    return api.post('users/change-password', data, getHeader())
}

export default change_password;