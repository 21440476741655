import React, { memo, useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip as PrimeReactTooltip } from 'primereact/tooltip';
import { getDatetimeFromTimestamp } from "../../../utils/Utils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  registerables
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';
import reset from "../../../assets/img/ICONE.png";
import useStateSidebarStore from '../../../store/useStateSidebarStore';
import useSliderStore from '../../../store/useSliderStore';

import { Container, Header, TitleButton, ButtonSelected, ButtonNoSelected, Chart, ContainerReset } from './styles';

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

const calculateLeakResearchFactor = (ref_date, region) => {
  const water_flow = region;
  let fpMap = {};

  for (let k in water_flow) {
    const dtc = getDatetimeFromTimestamp(water_flow[k]["datetime"]);
    if (dtc > ref_date) {
      const dtc_ref = dtc.toDateString();
      if (fpMap[dtc_ref] == undefined)
        fpMap[dtc_ref] = water_flow[k]["researchFactor"] != null ? water_flow[k]["researchFactor"].toPrecision(2) : null;
    }
  }

  let water_flowX = Object.keys(fpMap).map(e => new Date(e));
  let datasetsOver = [];
  let dt = Object.values(fpMap);

  datasetsOver.push(
    {
      fill: false,
      data: dt,
      borderWidth: 1,
      pointRadius: 3,
      spanGaps: false,
      backgroundColor: "green"
    }
  );

  return {
    labels: water_flowX,
    datasets: datasetsOver
  };
}

const RenderLeakResearchFactor = ({ ref_date, region, btnChangeChart }) => {
  const intl = useIntl();
  const { isOpen } = useStateSidebarStore();
  const { currentSlider, setCurrentSlider } = useSliderStore();
  const [verticalLineShow, setVerticalLineShow] = useState(false);
  const [verticalLinePosition, setVerticalLinePosition] = useState(null);
  const [flag, setFlag] = useState(false);
  const [upDateSize, setUpDateSize] = useState(0);
  const [windowWidth, setWindowWidth] = useState(() => {
    const data = window.innerWidth;
    if (data <= 2053) {
      return 570;
    } else if (data > 2053) {
      return 770;
    }
  });

  const lastZoomLevelRef = useRef(null);
  const myChartRef = useRef(null);

  useEffect(() => {
    if (currentSlider) {
      setVerticalLineShow(currentSlider.show)
      const date_full = new Date(currentSlider.value);
      const day = date_full.getDate();
      const month = date_full.getMonth() + 1; // Os meses começam em zero, então é necessário adicionar 1
      const year = date_full.getFullYear().toString().slice(-2); // Obtém os últimos dois dígitos do ano
      setVerticalLinePosition(`${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`)
    }
  }, [currentSlider]);

  useEffect(() => {
    if (myChartRef && myChartRef.current) {
      myChartRef.current.resize();
    }
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (myChartRef && myChartRef.current) {
        myChartRef.current.resize();
      }
    }, 1000);
  }, [upDateSize]);

  useEffect(() => {
    const atualizarLarguraTela = () => {
      const data = window.innerWidth;
      setUpDateSize(data);
      if (data <= 2053) {
        setWindowWidth(570);
      } else if (data > 2053) {
        setWindowWidth(770);
      }
    };
    window.addEventListener('resize', atualizarLarguraTela);
    return () => {
      window.removeEventListener('resize', atualizarLarguraTela);
    };
  }, []);

  const handleZoomComplete = (chart) => {
    if (chart) {
      const currentZoomLevelMin = chart.chart.scales['x'].min;
      const currentZoomLevelMax = chart.chart.scales['x'].max;
      lastZoomLevelRef.current = {
        min: currentZoomLevelMin,
        max: currentZoomLevelMax
      };
      setFlag(true);
    }
  };



  const resetZoomBtn = () => {
    if (myChartRef && myChartRef.current) {
      myChartRef.current.resetZoom();
      setFlag(false);
    }
  };

  var CH_DATA = calculateLeakResearchFactor(ref_date, region);
  var CH_OPTS_BAR = {
    resposive: false, 
    animation: false,
    scales: {
      y: {
        title: {
          display: true,
          text: intl.formatMessage({ id: "research_factor_subtitle" }),
          padding: 5
        },
        ticks: {
          display: true,
          max: 1,
          min: 0
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: '#eef0fa',
          zeroLineColor: 'rgba(90, 113, 208, 0)'
        },
      },
      x: {
        min: flag ? lastZoomLevelRef.current.min : 'original',
        max: flag ? lastZoomLevelRef.current.max : 'original',
        type: 'time',
        time: {
          unit: 'day',
          parser: 'MM/DD/YYYY HH:mm',
          tooltipFormat: intl.formatMessage({ id: "day_tooltip" }),
          displayFormats: {
            day: intl.formatMessage({ id: "day_label_x" }),
          }
        },
        position: 'bottom',
        grid: {
          drawBorder: false,
          display: false,
          drawTicks: false
        },
        ticks: {
          marginTop: 10,
          beginAtZero: false,
          // stepSize: 10,
          fontColor: '#878f87',
          padding: 1,
          maxRotation: 0,
          minRotation: 0,
        },
      }
    },
    elements: {
      line: {
        tension: 0
      }
    },
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: 'rgba(2, 171, 254, 1)',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const aux = context[0].formattedValue.split(',');
            const dateFormated = aux[0].trim().replace("(", "").replace(")", "");
            return dateFormated;
          },
          label: function (context) {
            let labelTooltip = context.dataset.label || '';
            if (labelTooltip) {
              labelTooltip += ': ';
            }
            labelTooltip += context.parsed.y;
            return labelTooltip;
          },
        },
      },
      zoom: {
        limits: {
          x: {
            min: CH_DATA.labels[0],
            max: CH_DATA.labels[CH_DATA.labels.length - 1]
          }
        },
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl',
        },
        zoom: {
          wheel: {
            enabled: false
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
          drag: {
            enabled: true
          },
          mode: 'x',
          onZoomComplete: (chart => {
            handleZoomComplete(chart);
          }),
        },
        drag: {
          backgroundColor: 'rgba(110, 255, 148, 0.4)',
        },
      },
      autocolors: false,
      annotation: verticalLineShow ? {
        annotations: {
          line1: {
            type: 'line',
            scaleID: 'x',
            value: verticalLinePosition,
            // value: '03/01/23',
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            borderColor: 'rgba(0, 255, 0, 0.52)',
            borderWidth: 4,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      } : {}
    }
  };
  return (
    <Container>
      <Header>
        <TitleButton>
          <ButtonNoSelected onClick={() => btnChangeChart('Vazão')}><FormattedMessage id="flow_title" /></ButtonNoSelected>
          <ButtonSelected><FormattedMessage id="research_factor_title" /></ButtonSelected>
        </TitleButton>
        <PrimeReactTooltip target=".custom-target-btn-reset" />
        <ContainerReset className='custom-target-btn-reset'
          onClick={() => resetZoomBtn()}
          data-pr-tooltip={intl.formatMessage({ id: "reset_zoom" })}
          data-pr-position="left"
        >
          <img src={reset} width={22} alt="Reset" style={{ verticalAlign: "text-top" }} />
        </ContainerReset>
      </Header>
      <Chart $data={CH_DATA}>
        {CH_DATA && CH_DATA.labels.length > 0 ? <Scatter data={CH_DATA} options={CH_OPTS_BAR} redraw={false} ref={myChartRef} width={windowWidth} /> : <FormattedMessage id="msg_no_data" />}
      </Chart>
    </Container>
  )
}

export default memo(RenderLeakResearchFactor);