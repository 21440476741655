import styled from 'styled-components';

export const FileInput = styled.input`
  display: none;
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-top: 10px;
`;

export const FileInputLabel = styled.label`
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
`;

export const FileLabel = styled.span`
  font-weight: bold;
  margin-left: 10px;
`;