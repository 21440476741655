import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { GoogleMap, Marker, Polyline, OverlayView } from '@react-google-maps/api';
import { Delaunay } from 'd3-delaunay';
import * as turf from '@turf/turf';
import * as clipper from 'polygon-clipping';
import { Dialog } from 'primereact/dialog';
import html2canvas from 'html2canvas';
import { getDatetimeFromTimestamp } from "../../../utils/Utils";
import useSliderStore from '../../../store/useSliderStore';
import ImageEditorComponent from '../../../components/ImageEditor/index';

import ButtonGroup from '../../ButtonGroup';
import ColorScale from '../../ColorScale';
import Legend from '../../Legend';
import ButtonCapture from '../../ButtonCapture';
import CommandBar from '../../CommandBar';
import SliderComponent from '../../SliderComponent';
import InfoWindows from '../../infoWindow';
import FilterDeviceMap from '../../FilterDeviceMap';

import { mapStyle, mapStyleFeira } from '../../../utils/MapStyles';
import useCompanyStore from '../../../store/useCompanyStore';
const colorsIsolines = [
  'rgb(124, 0, 152)',
  'rgb(170, 0, 0)',
  'rgb(234, 47, 58)',
  'rgb(196, 87, 23)',
  'rgb(255, 140, 0)',
  'rgb(255, 189, 14)',
  'rgb(255, 255, 0)',
  'rgb(155, 165, 0)',
  'rgba(25, 150, 100, 194)',
  'rgb(0, 255, 0)'
];

const TimelapseMap = ({ period, locations, devices, traces, selectedSensor, onSelectSensor, onSelectSector, layers, info, userType, minMaxValueScale, geoLocation, isMaster }) => {
  const intl = useIntl();
  const { currentSlider, setCurrentSlider } = useSliderStore();
  const FRAME_LAT_LNG = 0.0025;
  const [map, setMap] = useState(null);
  const { tradingName } = useCompanyStore();
  const [kindPin, setKindPin] = useState("value");
  const [isolinhas, setIsolinhas] = useState(false);
  const [slider, setSlider] = useState(0);
  const [frames, setFrames] = useState({});
  const [x, setX] = useState(null);
  const [delta, setDelta] = useState(false);
  const [customMarkerIcon, setCustomMarkerIcon] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });
  const [defaultZoom, setDefaultZoom] = useState(2);
  const polygonsRef = useRef([]);
  const [buttonGroup, setButtonGroup] = useState(false);
  const [visible, setVisible] = useState(false);
  const [imageMap, setImageMap] = useState(null);

  const [showTraces, setShowTraces] = useState(false);
  const [showStreet, setShowStreet] = useState(null);
  const [maxValueScaleData, setMaxValueScaleData] = useState(null);
  const [minValueScaleData, setMinValueScaleData] = useState(null);
  const [idDeviceChosen, setIdDeviceChosen] = useState(null);

  useEffect(() => {
    if (locations !== null && period !== null && info !== null) {
      buildTricks(locations, period);
    }

    const tradingNameCheck = tradingName.includes('Demonstração') || tradingName.includes('Comercial');
    const selectedMapStyle = tradingNameCheck ? mapStyleFeira : mapStyle;
    const mapOptions = {
      zoomControl: false,
      styles: selectedMapStyle,
      fullscreenControl: true
    };
    setShowStreet(mapOptions);

  }, [locations, period, info]);

  useEffect(() => {
    setShowTraces(true);
  }, [traces])

  useEffect(() => {
    setTimeout(() => {
      setIsolinhas(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (x && x.length) {
      setSlider(x.length - 1);
    }
  }, [x]);

  useEffect(() => {
    // upgradeMap();
    if (!isMaster[0]) {
      upgradeMap();
    };
  }, [slider, frames, delta, isolinhas, maxValueScaleData]);

  useEffect(() => {
    if (!isMaster[0]) {
      fitBounds(map);
    }
  }, [customMarkerIcon]);

  useEffect(() => {
    if (buttonGroup) {
      if (x) {
        setCurrentSlider(
          {
            value: x[slider],
            position: slider,
            region: info?.name,
            show: true,
            flag: true
          }
        )
      }
    }
    if (!buttonGroup) {
      if (x) {
        setCurrentSlider(
          {
            value: '',
            position: slider,
            region: info?.name,
            show: false,
            flag: false,
          }
        )
      }
    }
  }, [slider, buttonGroup]);

  useEffect(() => {
    if (currentSlider && currentSlider.show) {
      handleButtonGroup();
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [slider]);

  useEffect(() => {
    if (isMaster && isMaster[0]) {
      setCustomMarkerIcon(null);
      const avgLat = calculateAverageCoordinate(isMaster[1], 'lat');
      const avgLng = calculateAverageCoordinate(isMaster[1], 'long');

      setDefaultCenter({ lat: avgLat, lng: avgLng });

      const maxLatDiff = calculateCoordinateDiff(isMaster[1], 'lat');
      const maxLngDiff = calculateCoordinateDiff(isMaster[1], 'long');

      setDefaultZoom(calculateZoom(maxLatDiff, maxLngDiff) + 1);

      const coordinatesArray = isMaster[1].map(coordsArray => {
        if (Array.isArray(coordsArray)) {
          return coordsArray.map(coords => ({ lat: coords.lat, lng: coords.long }));
        } else {
          return { lat: coordsArray.lat, lng: coordsArray.long };
        }
      });
      dropLayers();

      polygonMap(coordinatesArray, '#007700', 0, false, '');
      if (geoLocation) {
        processGeoLocationMacro(geoLocation, polygonMap);
      }
    }
  }, [isMaster, geoLocation]);

  const polygonMap = (data, value, index, fill, id) => {
    try {
      const polygon = new window.google.maps.Polygon({
        paths: data,
        strokeColor: value,
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: value,
        fillOpacity: fill ? 0.2 : 0.1,
        zIndex: index,
      });
      polygon.id = id;
      polygon.addListener('click', () => {
        onSelectSector(polygon.id);
      });

      polygon.setMap(map);
      polygonsRef.current.push(polygon);
    } catch (error) {
      console.error("Error creating polygon:", error);
    }
  }

  const calculateAverageCoordinate = (data, type) => {
    if (!Array.isArray(data[0])) {
      return data.reduce((acc, coord) => acc + coord[type], 0) / data.length;
    }

    const total = data.reduce((acc, coordinates) => {
      const sum = coordinates.reduce((sumCoord, coord) => sumCoord + coord[type], 0);
      return acc + sum / coordinates.length;
    }, 0);

    return total / data.length;
  };

  const calculateCoordinateDiff = (data, type) => {
    if (!Array.isArray(data[0])) {
      const maxCoord = Math.max(...data.map(coord => coord[type]));
      const minCoord = Math.min(...data.map(coord => coord[type]));
      return maxCoord - minCoord;
    }

    const diffArray = data.map(coordinates => {
      const maxCoord = Math.max(...coordinates.map(coord => coord[type]));
      const minCoord = Math.min(...coordinates.map(coord => coord[type]));
      return maxCoord - minCoord;
    });

    return diffArray;
  };

  function calculateZoom(maxDiffArrayLng, maxDiffArrayLat) {
    function calculateZoomForArray(maxDiffArray) {
      return Math.min(
        ...maxDiffArray.map(diff => Math.floor(Math.log2(360 / diff)))
      );
    }

    if (Array.isArray(maxDiffArrayLng) && Array.isArray(maxDiffArrayLat)) {
      const zoomLng = calculateZoomForArray(maxDiffArrayLng);
      const zoomLat = calculateZoomForArray(maxDiffArrayLat);

      return Math.min(zoomLng, zoomLat);
    } else {
      const zoomLng = Math.floor(Math.log2(360 / maxDiffArrayLng));
      const zoomLat = Math.floor(Math.log2(180 / maxDiffArrayLat));

      return Math.min(zoomLng, zoomLat);
    }
  }


  const generateColorArray = (length) => {
    const cores = [
      '#0000FF', '#FF0000', '#800080', '#8B4513', '#FFFF00', '#FF1493',
      '#00FFFF', '#FFA500', '#008000', '#FFD700', '#4B0082', '#00FF00',
      '#6A5ACD', '#FF6347', '#DC143C', '#20B2AA', '#9400D3', '#00CED1',
      '#ADFF2F', '#FF4500', '#228B22', '#A0522D', '#BDB76B', '#32CD32',
      '#FF8C00', '#1E90FF', '#7FFF00', '#9932CC', '#8A2BE2', '#40E0D0',
      '#FF69B4', '#2F4F4F'
    ];

    const colorArray = [];
    for (let i = 0; i < length; i++) {
      colorArray.push(cores[i % cores.length]);
    }
    return colorArray;
  };

  const processGeoLocationMacro = (geoLocation, polygonMap) => {
    const result = [];
    let actualColorIndex = 0;

    geoLocation.forEach((innerArray, outerIndex) => {
      if (Array.isArray(innerArray)) {
        const mappedArray = innerArray.map(point => ({
          outerIndex,
          lat: point.lat,
          lng: point.long,
          sectorId: point.sectorId
        }));
        result.push(mappedArray);
        result.forEach((a) => {
          // const totalCores = generateColorArray(result.length); 
          // const actualColor = totalCores[actualColorIndex];
          // actualColorIndex = (actualColorIndex + 1) % totalCores.length;
          const actualColor = '#6A5ACD';
          const index = a.map(item => item.outerIndex + 1)[0] || a.outerIndex;
          const id = a.map(item => item.sectorId);
          polygonMap(a, actualColor, index, true, id[0]);
        });
      }
    });
  };

  const handleKeyDown = (event) => {
    if (event.code === 'ArrowLeft' && slider > 0) {
      handleDecrement();
    } else if (event.code === 'ArrowRight' && x && slider < x.length - 1) {
      handleIncrement();
    }
  };

  const handleButtonClick = useCallback(() => {
    setVisible(true);
    if (map === null) {
      return
    }
    html2canvas(map.getDiv(), {
      useCORS: true,
    }).then(function (canvas) {
      var img = canvas.toDataURL("image/jpeg");
      var image = new Image();
      image.src = img;
      setImageMap(image.src);
    });
  }, [map]);

  const handleButtonGroup = () => {
    setButtonGroup(prevButtonGroup => !prevButtonGroup);
    if (x && !buttonGroup) {
      setCurrentSlider({
        value: x[slider],
        position: slider,
        region: info?.name,
        show: true,
        flag: true,
      })
    } else {
      setCurrentSlider(
        {
          value: '',
          position: slider,
          region: info?.name,
          show: false,
          flag: false,
        }
      )
    }
  };

  const handleDecrement = () => {
    if (slider > 0) {
      setSlider(slider - 1)
    }
  };

  const handleIncrement = () => {
    if (slider < x.length - 1) {
      setSlider(slider + 1)
    }
  };

  const upgradeMap = () => {
    if (!window?.google?.maps || Object.keys(frames).length === 0) {
      return;
    }

    if (isolinhas) {
      renderMap(map, frames[x[slider]], layers, slider);
    } else {
      dropLayers();
    }

    if (minMaxValueScale) {
      const validId = findValidDeviceId(frames[x[slider]], minMaxValueScale);
      setIdDeviceChosen(validId);
      setMaxValueScaleData(getMaxValueScaleData(frames[x[slider]], validId));
      setMinValueScaleData(getMinValueScaleData(devices));
    }

    renderMarkers(frames[x[slider]], devices);
  };

  const isMvnValid = (device) => {
    return device.h_load !== null && device.h_load !== undefined && !isNaN(device.h_load);
  }

  const findValidDeviceId = (devices, minMaxValueScale) => {
    const { DeviceMainId, DeviceSecondaryId, DeviceTertiaryId } = minMaxValueScale;
    const value = [DeviceMainId, DeviceSecondaryId, DeviceTertiaryId];
    for (const id of value) {
      const device = devices?.find(device => device.id === id);
      if (device && isMvnValid(device)) {
        return id;
      }
    }
    return null;
  };

  const getMaxValueScaleData = (devices, validId) => {
    const mainDevice = devices?.find(device => device.id === validId);
    return mainDevice && mainDevice.h_load ? Math.round(mainDevice.h_load) : null;
  };

  const getMinValueScaleData = (devices) => {
    let minorElevation = Infinity;
    for (const deviceId in devices) {
      const device = devices[deviceId];
      if (device.elevation && device.elevation < minorElevation) {
        minorElevation = device.elevation;
      }
    }
    return Math.round(minorElevation);
  };

  const fitBounds = (map) => {
    if (window?.google?.maps && map) {
      const bounds = new window.google.maps.LatLngBounds();
      if (customMarkerIcon != null) {
        customMarkerIcon.forEach((marker) => {
          if (!isNaN(marker.lat) && !isNaN(marker.lng) && marker.install_point !== 'Comissonamento inativo') {
            bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
          }
        });
        map.fitBounds(bounds);
        const newCenter = map.getCenter();
        if (x && x.length > 0) {
          if (!isNaN(newCenter.lat()) && !isNaN(newCenter.lng())) {
            setDefaultCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
            setDefaultZoom(map.getZoom());
          }
        } else if (x && x.length === 0) {
          setDefaultCenter({ lat: -15.685068956906802, lng: -47.94552127288126 });
          setDefaultZoom(map.getZoom());
        }
      }
    }
  };

  const dropLayers = () => {

    if (isMaster.length === 0 || !isMaster[0] || !Array.isArray(isMaster[1][0])) {
      if (map) {
          map.data.forEach(function (feature) {
          map.data.remove(feature);
        });
      }
    }


    polygonsRef.current.forEach((polygon) => {
      polygon.setMap(null);
    });
    polygonsRef.current = [];
  }

  function getColor(value) {
    const r = value > 0.5 ? 255 : Math.round(510 * value);
    const g = value < 0.5 ? 255 : Math.round(510 * (1 - value));
    const b = 0;
    return `rgb(${r}, ${g}, ${b})`;
  }

  function getMinMax() {

    let minVal = null;
    let maxVal = null;

    if (minValueScaleData && maxValueScaleData) {
      if (delta) {
        // return { minVal: -10, maxVal: 10 };
        return { minVal: minValueScaleData, maxVal: maxValueScaleData };
      } else {
        return { minVal: minValueScaleData, maxVal: maxValueScaleData };
      }
    }

    return { minVal, maxVal };
  }

  function value2color(value) {
    const hidroLoad = Math.round(value);
    const { minVal, maxVal } = getMinMax();

    if (isNaN(hidroLoad)) {
      return "rgb(0, 0, 0)";
    }

    if (maxVal && hidroLoad > maxVal) {
      return "rgb(0, 150, 255)";
    }

    const percentage = (hidroLoad - minVal) / (maxVal - minVal) * 100;

    let index = Math.round(percentage / 10);

    if (percentage < 0) {
      index = 0;
    } else if (index > 0) {
      index -= 1;
    }

    return colorsIsolines[index];
  }


  function value2colorDelta(value) {
    const maxVal = 10;
    const minVal = -10;
    const normalizedValue = Math.log(((Math.abs((maxVal - value)) / (maxVal - minVal)) * 9) + 1) / Math.log(10);
    return getColor(normalizedValue);
  };

  function buildTricks(locations, period) {
    let newFrames = {};
    var xVect = [];

    for (let i in locations) {
      for (let j in locations[i]) {
        var k = locations[i][j].datestr;

        if (newFrames[k] == undefined) {
          xVect.push(locations[i][j].datetime);
          newFrames[k] = [];
        }

        if (locations[i][j].install_point !== 'Comissonamento inativo') {
          const row = {
            lat: locations[i][j].lat,
            lon: locations[i][j].lon,
            id: locations[i][j].id,
            device: locations[i][j].device,
            install_point: locations[i][j].install_point,
            pressure: locations[i][j].p_avg,
            h_load: locations[i][j].h_load,
            h_load_diff: locations[i][j].h_load_diff,
            color: locations[i][j].color,
            color_delta: locations[i][j].color_delta,
          };
          newFrames[k].push(row);
        }

      }
    }

    xVect.sort((a, b) => a - b);
    xVect = xVect.map(e => getDatetimeFromTimestamp(e).toDateString());

    // xVect.shift();
    setSlider(xVect.length - 1);
    if (info?.name === currentSlider?.region && currentSlider?.position > 0) {
      setSlider(currentSlider.position)
    } else {
      setSlider(xVect.length - 1)

    }

    setX(xVect);
    setFrames(newFrames);
  }

  function handleMarkerClick(marker) {
    setSelectedMarker(marker);
  }

  function renderMarkers(positions, devices) {
    var icons = [];
    for (let i in positions) {
      const id = positions[i].id;
      if (devices[id] && devices.installPointName !== 'Comissonamento inativo') {
        const icon = {
          id: id,
          lat: devices[id].lat,
          lng: devices[id].lon,
          device: devices[id].deviceId,
          install_point: devices[id].installPointName,
          pressure: positions[i].pressure == null ? '.' : positions[i]?.pressure,
          h_load: typeof positions[i].h_load !== 'number' ? '.' : positions[i].h_load.toFixed(2),
          h_load_diff: typeof positions[i].h_load_diff !== 'number' ? '.' : positions[i].h_load_diff.toFixed(2),
          scaledSize: new window.google.maps.Size(40, 40),
          elevation: devices[id].elevation
        };
        icons.push(icon);
      }
    }
    setCustomMarkerIcon(icons);
  }

  const removerDispositivosInativos = (dispositivos) => {
    if (dispositivos) {
      const dispositivosFiltrados = dispositivos.filter(
        (dispositivo) => dispositivo.install_point !== "Comissonamento inativo"
      );
      return dispositivosFiltrados;
    }
  }

  const renderMap = (map, points, layers, k) => {
    const dispositivosAtivos = removerDispositivosInativos(points);
    if (layers) {
      renderIsobands(map, dispositivosAtivos, layers, k);
    }
    else {
      renderVoronoi(map, dispositivosAtivos);
    }
  }

  const renderVoronoi = (map, points) => {
    if (map == null || points == null) {
      console.error("Invalid map or points data.");
      return;
    }

    const [minLng, minLat, maxLng, maxLat] = points.reduce(
      ([minLng, minLat, maxLng, maxLat], { lat, lon }) => [
        Math.min(minLng, lon),
        Math.min(minLat, lat),
        Math.max(maxLng, lon),
        Math.max(maxLat, lat),
      ],
      [Infinity, Infinity, -Infinity, -Infinity]
    );

    points = points.filter(point => point.h_load != null)
    if (points == null)
      return;

    const delaunay = Delaunay.from(
      points.map((point) => [point.lat, point.lon])
    );

    if (minLat == Infinity || minLng == Infinity || maxLat == Infinity || maxLng == Infinity) {
      console.error("Invalid bounds for Voronoi generation.");
      return;
    }

    if (minLat == -Infinity || minLng == -Infinity || maxLat == -Infinity || maxLng == -Infinity) {
      console.error("Invalid bounds for Voronoi generation.");
      return;
    }

    const voronoi = delaunay.voronoi([minLat - FRAME_LAT_LNG, minLng - FRAME_LAT_LNG, maxLat + FRAME_LAT_LNG, maxLng + FRAME_LAT_LNG]);

    const svgPathToLatLngArray = (path) => {
      const coords = path.replace(/M|Z/g, "")
        .split("L")
        .map((coord) => coord.split(",").map(Number));
      return coords.map(([lat, lon]) => ({ lat, lng: lon }));
    };

    dropLayers();

    const polygonMap = (data, value, index) => {
      try {
        const polygon = new window.google.maps.Polygon({
          paths: data,
          strokeColor: value2color(value),
          strokeOpacity: 0,
          strokeWeight: 1,
          fillColor: value2color(value),
          fillOpacity: 0.3,
          zIndex: index,
        });

        polygon.setMap(map);
        polygonsRef.current.push(polygon);
      } catch (error) {
        console.error("Error creating polygon:", error);
      }
    }

    if (geoLocation && geoLocation.length > 0) {

      let geoLocationAdjust = geoLocation;
      const pointsGeoJSON = turf.featureCollection(
        points.map((point, index) => turf.point([point.lon, point.lat], { id: index }))
      );

      const voronoiPolygons = turf.voronoi(pointsGeoJSON);

      const voronoiCoordinates = voronoiPolygons.features.map(feature =>
        feature.geometry.coordinates[0],
      );

      if (
        geoLocationAdjust.length > 1 &&
        geoLocationAdjust[0].lat !== geoLocationAdjust[geoLocationAdjust.length - 1].lat ||
        geoLocationAdjust[0].long !== geoLocationAdjust[geoLocationAdjust.length - 1].long
      ) {

        geoLocationAdjust.push({
          "lat": geoLocationAdjust[0].lat,
          "long": geoLocationAdjust[0].long
        });
      }

      const boundingBox = turf.polygon([
        geoLocationAdjust.map(coord => [coord.long, coord.lat]),
      ]);

      const clippedPolygons = voronoiCoordinates.map(coordinates =>
        clipper.intersection([coordinates], [boundingBox.geometry.coordinates])
      );

      if (clippedPolygons.length === 0 && points.length === 0) {
        const aux = geoLocationAdjust.map(coords => {
          return {
            lat: coords.lat,
            lng: coords.long
          }
        });
        polygonMap(aux, 200, 1)
      }

      clippedPolygons.forEach((feature, index) => {
        const coordinates = feature[0]?.[0]?.map(coordenada => ({
          lat: coordenada[1],
          lng: coordenada[0]
        }));

        try {
          if (coordinates && points[index]) {
            const hloadValue = points[index].h_load;
            polygonMap(coordinates, hloadValue, index);
          }
        } catch (error) {
          console.error("Error rendering Voronoi polygon:", error);
        }
      });

    } else {
      points.forEach((point, index) => {
        try {
          const svgPath = voronoi.renderCell(index);
          const path = svgPathToLatLngArray(svgPath);
          polygonMap(path, point.h_load, index);
        } catch (error) {
          console.error("Error rendering Voronoi polygon:", error);
        }
      });
    }
    map.data.setStyle({});
  }

  const renderIsobands = (map, points, layers, k) => {
    if (map == null || points == null)
      return;

    dropLayers();

    const dt = getDatetimeFromTimestamp(x[k]);
    const month = dt.getMonth() + 1
    const dts = dt.getFullYear() + "-" + (month < 10 ? '0' + month : month) + "-" + (dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate());

    let map_layer = layers[dts]

    for (let layer in map_layer) {
      let features = map_layer[layer].source.features;

      for (let idx in features) {
        map.data.addGeoJson(features[idx]);
      }
    }

    map.data.setStyle((feature) => {
      let color = "gray";

      if (feature.getProperty("color")) {
        color = feature.getProperty("color");
      }
      return {
        fillColor: color,
        strokeColor: color,
        strokeWeight: 1,
      };
    });
  }

  const { minVal, maxVal } = getMinMax();

  return (locations && <div id='teste123' style={{ backgroundColor: '#fff', height: '100%' }}>
    <Dialog header={intl.formatMessage({ id: "map_capture" })} visible={visible} id='modal-capture' onHide={() => setVisible(false)}>
      <ImageEditorComponent image={imageMap} width={700} height={600} />
    </Dialog>
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: isMaster && !isMaster[0] ? '69vh' : '80.6vh' }}
      onLoad={(map) => setMap(map)}
      center={defaultCenter}
      zoom={defaultZoom}
      options={showStreet}
    >
      {showTraces && traces && traces.map((line, index) => {
        return <Polyline
          key={index}
          path={[{ lat: line.x0, lng: line.y0 }, { lat: line.x1, lng: line.y1 }]}
          options={{
            strokeColor: "#006699",
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
      })}
      {/* {true && geoLocation && geoLocation.map((line, index) => {
        return <Polyline
          key={index}
          path={[{ lat: line.x0, lng: line.y0 }, { lat: line.x1, lng: line.y1 }]}
          options={{
            strokeColor: "#006699",
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
      })} */}
      {customMarkerIcon != null && customMarkerIcon.map((e, idx) => {
        const icon = kindPin == "alarm" ? e : {
          path: idDeviceChosen === e.id ? "M 6 -12 L -6 -12 L -6 0 L 6 0 Z" : "M 6 -6 A 6 6 0 1 0 -6 -6 A 6 6 0 1 0 6 -6",
          /*** Ajuste para remover isolinhas do delta****/
          // fillColor: selectedSensor == null || selectedSensor == e.device ? value2color(delta ? e.h_load_diff : e.h_load) : "#AAAAAA",
          fillColor: selectedSensor == null || selectedSensor == e.device ? delta ? value2colorDelta(e.h_load_diff) : value2color(e.h_load) : "#AAAAAA",
          fillOpacity: 0.9,
          scale: 4,
          strokeWeight: 0,
        };
        return <Marker key={"mrk_bm_idx_" + idx} position={e} icon={icon} onClick={() => handleMarkerClick(e)}>

          {selectedMarker == e && <InfoWindows e={e} selectedMarker={selectedMarker} setSelectedMarker={handleMarkerClick} selectedSensor={selectedSensor} onSelectSensor={onSelectSensor} />}

          <OverlayView
            position={e}
            getPixelPositionOffset={(width, height) => ({
              x: -width / 2,
              y: -height,
            })}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div
              onClick={(event) => event.stopPropagation()}
              style={{
                borderRadius: 30,
                backgroundColor: 'white'
              }}
            >
              <div style={delta ? { width: '10px', height: '10px', ...icon, marginTop: -66, marginLeft: -20 } : { width: '10px', height: '10px', ...icon, marginTop: -66, marginLeft: -20 }}>
                <span style={{ textAlign: 'center', backgroundColor: '#000', color: '#fff', padding: '1px 5px', borderRadius: '8px', marginLeft: 4 }}>{e.id.slice(-4)}</span><br /><br />
                <span onClick={() => handleMarkerClick(e)} style={delta ? { fontSize: 10, fontWeight: 'bold', marginLeft: 3 } : { fontSize: 10, fontWeight: 'bold' }}>
                  {delta ? (e.h_load_diff !== '.' ? `D:${Math.round(e.h_load_diff)}` : '') :
                    (`${e.h_load !== '.' ? `CH:${Math.round(e.h_load)}` : ''}\n${e.pressure !== '.' && !isNaN(e.pressure) ? `P:${Math.round(e.pressure)}` : ''}`)}
                </span>
              </div>
            </div>
          </OverlayView>
        </Marker>
      })}
      {
        x && x.length > 0 && isMaster && !isMaster[0] &&
        <>
          <ColorScale isolinhas={isolinhas} kindPin={kindPin} minVal={minVal} maxVal={maxVal} delta={delta} />
          <Legend kindPin={kindPin} />
        </>
      }
    </GoogleMap>
    {isMaster && !isMaster[0] && <FilterDeviceMap devices={devices} filteredSensor={onSelectSensor} selectedSensor={selectedSensor} />}
    {x && x.length > 0 && isMaster && !isMaster[0] && <CommandBar kindPin={kindPin} setKindPin={setKindPin} delta={delta} setDelta={setDelta} isolinhas={isolinhas} setIsolinhas={setIsolinhas} />}
    {isMaster && !isMaster[0] &&
      <div style={userType === "1" ? { display: 'flex', flexDirection: 'row', marginTop: '4.5vh', justifyContent: 'space-around' } : { display: 'flex', flexDirection: 'row', marginTop: 50, flexWrap: 'nowrap', justifyContent: 'center' }}>
        <div className='col-sm-0'>
          {userType === "1" ? <ButtonCapture onButtonClick={handleButtonClick} /> :
            <div style={{ paddingBottom: 10, paddingTop: 10, borderRadius: 10, marginLeft: 10 }}></div>
          }
        </div>
        <div className='col-sm-9' style={{ alignSelf: 'center' }}>
          <SliderComponent x={x} slider={slider} setSlider={setSlider} decrement={handleDecrement} increment={handleIncrement} />
        </div>
        <div className='col-sm-0'>
          <ButtonGroup buttonGroup={buttonGroup} handleButtonGroup={handleButtonGroup} />
        </div>
      </div>
    }

  </div>
  );
};

export default memo(TimelapseMap);
