import React from 'react';
import { BrowserRouter } from "react-router-dom";

import { useAuth } from '../hooks/auth';

import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';

const Routes = () => {
    const { isAuthenticated } = useAuth();
    return (
        <BrowserRouter>
            {isAuthenticated ? <AppRoutes /> : <AuthRoutes />}
        </BrowserRouter>
    )
}

export default Routes;