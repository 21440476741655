import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-content: center;
`;

export const MoveLeftButton = styled.div`
  text-align: center;
  cursor: pointer;
  border: solid 2px black;
  border-radius: 6px;
  user-select: none;
`;

export const MoveRightButton = styled(MoveLeftButton)``;