import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, DrawingManager, Marker } from '@react-google-maps/api';
import _debounce from 'lodash/debounce';
import { mapStyle } from '../../../utils/MapStyles';

const calculateAverage = (points, prop) => {
    const sum = points.reduce((acc, point) => acc + point[prop], 0);
    return sum / points.length;
};

const MapComponent = ({ data, isBig, deletePolygon, onPath, onDevices, kmlData, tubes, totalDvc }) => {
    const [map, setMap] = useState(null);
    const polygonsRefKmz = useRef([]);
    const [showDraw, setShowDraw] = useState(false);
    const [create, setCreate] = useState(false);
    const [defaultCenter, setDefaultCenter] = useState({ lat: -23.47003808219593, lng: -47.417858840616105 });

    const [allDvc, setAllDvc] = useState([]);
    const [verify, setVerify] = useState(false);


    useEffect(() => {
        if (totalDvc && data.length > 0) {
            const auxFlag = data.map(device => device?.device_id);
            const isAnyUndefined = auxFlag.includes(undefined);
            const list = isAnyUndefined ? data.map(device => device.serialNumber) : data.map(device => device.device_id);
            const finalList = totalDvc.map(device => {
                const isPresent = isAnyUndefined ? list.includes(device.serialNumber) : list.includes(device.device_id);
                return { ...device, all: !isPresent };
            });
            setAllDvc(finalList);
        }

        if (data.length > 0) {
            const avgLat = calculateAverage(data, 'lat');
            const avgLng = calculateAverage(data, 'long');
            setDefaultCenter({ lat: avgLat, lng: avgLng });
        }
    }, [data]);

    useEffect(() => {
        if (isBig) {
            setCreate(true);
            setShowDraw(isBig);
            setTimeout(() => {
                setVerify(true);
            }, 1000);
        } else if (!isBig) {
            setCreate(false);
            setTimeout(() => {
                setVerify(false);
            }, 500);
        }
    }, [isBig]);

    useEffect(() => {
        if (deletePolygon) {
            onDeletePolygon();
        }
    }, [deletePolygon]);

    useEffect(() => {
        if (kmlData && kmlData.length > 0) {
            const allCoordinates = kmlData.flat();
            const avgLat = calculateAverage(allCoordinates, 'latitude');
            const avgLng = calculateAverage(allCoordinates, 'longitude');
            setDefaultCenter({ lat: avgLat, lng: avgLng });
            polygonMapKmz(allCoordinates);
        }
    }, [kmlData, map, isBig]);

    const mapStyles = {
        height: "67vh",
        width: "100%"
    };

    const mapStylesBig = {
        height: "800px",
        width: "100%"
    };

    const onDeletePolygon = () => {
        polygonsRefKmz.current.forEach(polygon => polygon.setMap(null));
        polygonsRefKmz.current = [];
    }

    const mapOptions = {
        zoomControl: true,
        styles: mapStyle,
        fullscreenControl: true
    };

    const polygonMapKmz = data => {
        polygonsRefKmz.current.forEach(polygon => polygon.setMap(null));
        const aux = data.map(coord => ({ lat: coord.latitude, lng: coord.longitude }))
        try {
            const polygon = new window.google.maps.Polygon({
                paths: aux,
                editable: isBig,
                draggable: false,
                strokeColor: "#00FF00",
                strokeOpacity: 0.08,
                strokeWeight: 1,
                fillColor: "#00FF00",
                fillOpacity: 0.3,
                zIndex: 1,
            });
            window.google.maps.event.addListener(polygon.getPath(), 'set_at', function () {
                completePolygon(polygon);
            });
            setShowDraw(false);
            polygon.setMap(map);
            polygonsRefKmz.current.push(polygon);

        } catch (error) {
            console.error("Error creating polygon:", error);
        }
    };

    const completePolygon = data => {
        const polygonCoords = data.getPath().getArray();
        const updatedPoints = polygonCoords.map(point => {
            return {
                "latitude": point.lat(),
                "longitude": point.lng()
            };
        });
        onPath(updatedPoints);
    }

    const createSvgMarker = (label, color) => `
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none">
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" fill="${color}"/>
            <text x="50%" y="50%" text-anchor="middle" stroke="black" stroke-width="0.5px" dy=".3em" font-family="Roboto" font-size="6px" fill="black">${label}</text>
        </svg>
    `;

    const markerOptions = React.useCallback((label, color) => ({
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(createSvgMarker(label, color))}`,
        scaledSize: new window.google.maps.Size(48, 48),
    }), []);

    const getMarkerColor = (all) => (all ? '#e5f03b' : '#88c55a');

    const MarkerComponent = React.memo(({ point }) => {
        const { lat, long, serial_number, serialNumber, all } = point;
        const label = serial_number ? serial_number.slice(-4) : serialNumber.slice(-4);
        const color = getMarkerColor(all);

        return (
            <Marker
                position={{ lat, lng: long }}
                icon={markerOptions(label, color)}
            />
        );
    }, (prevProps, nextProps) => prevProps.point === nextProps.point);

    return (
        <div className='ooo'>
            <GoogleMap
                mapContainerStyle={create ? mapStylesBig : mapStyles}
                onLoad={map => setMap(map)}
                zoom={15}
                center={defaultCenter}
                options={mapOptions}
            >
                {allDvc.map((point, index) => (
                    <MarkerComponent key={index} point={point} />
                ))}
                {showDraw && (
                    <DrawingManager
                        options={{
                            drawingControl: verify,
                            drawingControlOptions: {
                                position: window.google.maps.ControlPosition.TOP_CENTER,
                                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
                            },
                            polygonOptions: {
                                fillColor: `#6EFF94`,
                                fillOpacity: 0.08,
                                strokeColor: `#6EFF94`,
                                strokeWeight: 1,
                                clickable: true,
                                editable: true,
                                zIndex: 1,
                            },
                        }}
                        onPolygonComplete={(polygon) => {
                            polygonsRefKmz.current.push(polygon);
                            completePolygon(polygon);
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    );
};

export default MapComponent;

