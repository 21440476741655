const light = {
    colors: {
        primary: '#8086C0',
        secondary: '#FFFFFF',
        tertiary: '#F5f5f5',

        white: '#FFF',
        black: '#000',
        gray: '#E6E6E6',

        success: '#6EFF94',
        info: '#F7931B',
        warning: '#E44C4E',
    },
  };
  
  export default light;