import React, { useState, useEffect, useRef } from 'react'
import { MdOutlineHome, MdOutlinePlace, MdDensitySmall, MdSettings, MdSave, MdPictureAsPdf, MdLock, MdLogout, MdOutlineMap } from 'react-icons/md'
// import { Container, CustomToggle, CustomMenu, ContainerItems, Items, ContainerLogo, Logo } from './styles'
import { HeaderContainer, Logo, Nav, NavItem, Menu, Items, CustomToggle, CustomMenu, NavItemSelect, Alarm, CustomItemDropdown } from './styles'
import { FormattedMessage, useIntl } from 'react-intl';
import Dropdown from 'react-bootstrap/Dropdown';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { clients_all } from '../../services/alarms';
import { useAuth } from '../../hooks/auth';
import DropdownClient from '../DropdownClients';
import ExportReport from '../ExportReport';
import { Toast } from 'primereact/toast';
import _debounce from 'lodash/debounce';
import { CgDisplayGrid } from "react-icons/cg";

import logo from '../../assets/img/logo-stattus4-light.webp'
import useUserStore from '../../store/useUserStore';
import AlarmList from '../../components/AlarmList/index';
import useClientSelectedStore from '../../store/map/useClientSelectedStore';
import { keycloak } from '../../keycloak';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // theme
import 'primereact/resources/primereact.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import useCompanyStore from '../../store/useCompanyStore';
import useDropdownStore from '../../store/useDropdownStore';

const Header = () => {
    const toast = useRef(null);
    const { logout } = useAuth();
    const { user } = useUserStore();
    const routeLocation = useLocation();
    const navigate = useNavigate();
    const { setTradingName } = useCompanyStore();
    const { clientSelected } = useClientSelectedStore();
    const { isDropdownDisabled } = useDropdownStore();
    const [currentRoute, setCurrentRoute] = useState(null);
    const [allClients, setAllClients] = useState([]);
    const [initialClient, setInitialClient] = useState(null);
    const [showModalExport, setShowModalExport] = useState(false);
    const loginByKeycloak = JSON.parse(localStorage.getItem('isKcAda'));
    const last_client = localStorage.getItem('last_client');

    useEffect(() => {
        debouncedGetAllClients();
        return debouncedGetAllClients.cancel;
    }, [])

    useEffect(() => {
        if (user) {
            const client = {
                id: user.client.id,
                trading_name: user.client.trading_name
            };
            setInitialClient(client);
            setTradingName(user.client.trading_name);
        }
    }, [])

    useEffect(() => {
        if (routeLocation) {
            setCurrentRoute(routeLocation.pathname);
        }
    }, [routeLocation]);

    const debouncedGetAllClients = _debounce(() => {
        getAllClients();
    }, 1);

    const getAllClients = async () => {
        try {
            const response = await clients_all();
            const filteredData = response.data.filter(item => item.activeAda === true);
            setAllClients(filteredData)
        } catch (error) {
            if (error.response) {
                const msg = error.response.data.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
            } else if (error.request) {
                const message = error.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
            }
        }
    };

    const exit = () => {
        if (loginByKeycloak) {
            keycloak.logout({ redirectUri: window.location.origin + `/intercept/${last_client}` });
            sessionStorage.removeItem('@ada-user');
            sessionStorage.removeItem('@ada-company');
            localStorage.removeItem('isKcAda');
        }
        if (!loginByKeycloak) {
            logout();
            navigate('/login');
        }
    }

    const handleModalExportReport = () => {
        setShowModalExport(true);
    };

    const handleClose = () => {
        setShowModalExport(false);
    }

    return (
        <>
            <HeaderContainer>
                <Toast ref={toast} />
                <Link to="/home">
                    <Logo>
                        <img src={logo} alt='Logo' />
                    </Logo>
                </Link>
                <Nav>
                    <NavItem>
                        <Items href='/home' $actived={currentRoute === '/home'}>
                            <MdOutlineHome />
                            <FormattedMessage id="home" />
                        </Items>
                        <Items href='/install-points' $actived={currentRoute === '/install-points'}>
                            <MdOutlinePlace />
                            <FormattedMessage id="install_points" />
                        </Items>
                    </NavItem>
                    <NavItemSelect>
                        {currentRoute === '/home' && user.type_user === '1' && <DropdownClient data={allClients} initial={initialClient} disabled={isDropdownDisabled} />}
                        {currentRoute === '/install-points' && user.type_user === '1' && <DropdownClient data={allClients} initial={initialClient} disabled={isDropdownDisabled} />}
                    </NavItemSelect>
                    <NavItem></NavItem>
                </Nav>
                <Alarm>
                    {
                        currentRoute === '/home' && <AlarmList data={user.type_user !== '1' ? user.client_id : clientSelected.id} token={user?.token} />
                    }
                    {
                        currentRoute === '/install-points' && <AlarmList data={user.type_user !== '1' ? user.client_id : clientSelected.id} token={user?.token} />
                    }
                </Alarm>
                <Menu>
                    <Dropdown>
                        <CustomToggle variant="success" id="dropdown-basic">
                            <MdDensitySmall size={28} color='#000' />
                        </CustomToggle>
                        <CustomMenu>
                            {user.type_user === '1' && <CustomItemDropdown href="/manager-macro-sector" ><div><CgDisplayGrid size={30} /> <FormattedMessage id="macro_sector_management" /></div></CustomItemDropdown>}
                            {user.type_user === '1' && <CustomItemDropdown href="/manager-sector" ><div><MdOutlineMap size={25} /> <FormattedMessage id="sector_manager" /></div></CustomItemDropdown>}
                            {user.type_user === '1' && <CustomItemDropdown href="/manager-alarm" ><div><MdSettings size={25} /> <FormattedMessage id="alarms_manager" /></div></CustomItemDropdown>}
                            <CustomItemDropdown onClick={handleModalExportReport}><div><MdSave size={25} /> <FormattedMessage id="save_reports" /></div></CustomItemDropdown>
                            <CustomItemDropdown href="#/action-3" disabled><div><MdPictureAsPdf size={25} /> <FormattedMessage id="export_pdf" /></div></CustomItemDropdown>
                            {!loginByKeycloak && <CustomItemDropdown href="/change-password"><div><MdLock size={25} /> <FormattedMessage id="change_password" /></div></CustomItemDropdown>}
                            <CustomItemDropdown onClick={exit}><MdLogout size={25} /> <FormattedMessage id="log_out" /></CustomItemDropdown>
                        </CustomMenu>
                    </Dropdown>
                </Menu>
            </HeaderContainer>
            <ExportReport onShow={showModalExport} onClose={handleClose} />
        </>
    )
}

export default Header;



