import React from "react";
import { Dropdown } from "primereact/dropdown";

const DropdownCompRegion = ( { data, handleDropdownChangeRegion, value} ) => {
    
      const regionTemplate = (option) => {
        return (
          <div className="car-option">
            <span>{option.sectorName}</span>
          </div>
        );
      };
    
      return (
        <div>
          <Dropdown
            options={data}
            value={value}
            onChange={handleDropdownChangeRegion}
            optionLabel="sectorName"
            optionValue="sectorId"
            itemTemplate={regionTemplate}
            placeholder="Selecione uma região"
            style={{ width: '100%', marginTop: "8px", backgroundColor: 'rgb(230, 230, 230' }}
          />
        </div>
      );
    };
    

export default DropdownCompRegion;
