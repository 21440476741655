import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { IntlProvider } from 'react-intl';
import enMessages from './locales/en.json';
import esMessages from './locales/es.json';
import ptMessages from './locales/pt.json';

import { AuthProvider } from './hooks/auth';

const messages = {
  en: enMessages,
  es: esMessages,
  pt: ptMessages
};

const aux = navigator.language.split(/[-_]/)[0];
const language = aux === 'pt' ? 'pt' : aux === 'es' ? 'es' : aux === 'en' ? 'en' : 'en';
const defaultLocale = 'en';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={messages[language]} defaultLocale={defaultLocale}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </IntlProvider>
  </React.StrictMode>
);

reportWebVitals();
