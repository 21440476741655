import styled, {css} from "styled-components";
import Dropdown from 'react-bootstrap/Dropdown';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';

const activeButtonStyles = css`
  background-color: ${props => props.theme.colors.primary}; ;
  border-color: ${props => props.theme.colors.primary}; ;
`;

export const CustomDropdown = styled(Dropdown)`
  .dropdown-menu {
    background-color: rgb(230,230,230);
  }
  
  .dropdown-header {
    background-color: white;
    margin-top: -7px;
    margin-bottom: 10px;
  }

  .dropdown-menu {
    border: 1px solid grey;
  }
`;


export const ContainerCardTop = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ContainerCardImgTop = styled.div`
    margin-left: 10px;
    margin-top: 5px;
`;

export const TitleCardTypeAlarm = styled.span`
    font-size: 14px;
    margin-left: 5px;
    font-weight: bold;
    align-self: center;
`;

export const ContainerCardCheckbox = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: center;
`;

export const TitleCardSeen = styled.span`
    font-size: 16px;
    margin-right: 10px;
`;

export const ContainerCardCenter = styled.div`
    display: flex;
`;

export const ContainerCardCalendar = styled.div`
    place-self: center;
`;

export const CardItemTitleCenter = styled.span`
    font-size: 14px;
    font-weight: bold;
`;

export const CardItemCenter = styled.span`
    font-size: 13px;
`;

export const ContainerCardBottom = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
`;

export const ContainerCardButtons = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
`;

export const AlarmIcon = styled.i`
    font-size: 1.4rem;
    color: ${props => props?.data === 'true' ? '#000' : '#EE0000'};
    cursor: pointer;
`;

export const ContainerCardOpt = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

export const AlarmCardIconReturn = styled.i`
    font-size: 1.3rem;
    cursor: pointer;
    color: black
`;

export const AlarmLabelCheckBox = styled.label`
    color: black;
    font-size: 1rem;
    display: flex;
    align-items: baseline
`;

export const AlarmCardIconFile = styled.i`
    color: black;
    font-size: 1rem;
    display: flex;
    cursor: pointer;
    &:hover {
    background-color: ${props => props.theme.colors.primary}; 
    color: #fff; 
    padding: 3px;
    border-radius: 3px;
  }
`;

export const ContainerMsgNoAlarm = styled.div`
    width: 350px;
    text-align: center;
`;

export const ContainerTitleDialog = styled.div`
    display: flex;
`;

export const LabelEventsMsg = styled.span`
    font-size: 16px;
`;

export const ContainerMsgSuccessDialog = styled.div`
    margin: auto;
`;

export const ContainerDialog = styled.div`
    display: flex;
    margin-top: 3%;
`;

export const ContainerDialogImg = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-self: center; 
`;

export const ContainerDialogCheckBox = styled.div`
    display: flex;
    justify-content: end;
    justify-self: center;
    margin-right: 20px; 
`;

export const ContainerDialogCheckBoxLabel = styled.label`
    font-size: 16px; 
    margin-right: 10px;
`;

export const ContainerDialogCalender = styled.div`
    display: flex;
`;

export const ContainerDialogObs = styled.div`
    display: flex;
    align-items: center;
`;

export const ContainerDialogButtons = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const SubContainerDialogButtons = styled.div`
    margin-top: 5%;
`;

export const DialogButtons = styled.button`
    zoom: 0.80;
    border-radius: 20px;
    margin-top: 10px;
`;


export const CustomToggle = styled(Dropdown.Toggle)`
 padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.primary}; 
  color: #fff;
  border: none;
  border-color: ${props => props.theme.colors.primary}; 

  &::after {
    display: none;
    background-color: ${props => props.theme.colors.primary}; 
  }

  & ::before {
    background-color: ${props => props.theme.colors.primary}; 
  }

  &.btn-primary {
    background-color: ${props => props.theme.colors.primary}; 
  }


  &:hover {
    background-color: ${props => props.theme.colors.primary}; 
    color: #fff; 
  }

  &.show {
    background-color: ${props => props.theme.colors.primary}; 
    color: #fff; /* Cor do texto quando o menu está aberto */
  }

  &:checked {
    ${activeButtonStyles}
  }

  &:focus {
    background-color: ${props => props.theme.colors.primary}; 
    border-color: ${props => props.theme.colors.primary}; 
  }

  /* Estilos quando o botão está ativo (pressionado) */
  &:active {
    background-color: ${props => props.theme.colors.primary}; 
    border-color: ${props => props.theme.colors.primary}; 
  }

  /* Estilos quando o botão está desabilitado */
  &:disabled {
    background-color: ${props => props.theme.colors.primary}; 
    border-color: ${props => props.theme.colors.primary}; 
  }


`;

export const CustomMenu = styled(Dropdown.Menu)`
    margin-top: 18px;
    border-radius: 0%;
    margin-right: 16%;
    right: -21%;
  &::before {
    content: "";
    position: absolute;
    top: -20px; 
    left: 92%; 
    transform: translateX(-50%);
    border-width: 12px; 
    border-style: solid;
    border-color: transparent transparent grey transparent; 
  }
`;

export const CustomButton = styled(Button)`
  height: 35px;
`;

export const CustomlistBox = styled(ListBox)`
background-color: rgb(230,230,230);
  &.p-listbox{
    border: 1px solid transparent;
  }
`;
