import React from 'react';
import Slider from 'rc-slider';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from 'primereact/tooltip';
import { Row, MoveLeftButton, MoveRightButton } from './styles';

import 'rc-slider/assets/index.css';

const SliderComponent = ({ x, slider, setSlider, decrement, increment }) => {
  const intl = useIntl();
  return (
    <>
      <Row className="row-sm">
        <Tooltip target=".custom-target-move-left" />
        <MoveLeftButton
          className='col-sm-1 custom-target-move-left'
          onClick={decrement}
          data-pr-tooltip={intl.formatMessage({ id: "move_left" })}
          data-pr-position="left"
        >
          &lt;
        </MoveLeftButton>
        <div className='col-sm-8'>
          <div
            style={{
              zIndex: 10,
              background: "rgb(255 255 255 / 90%)",
              color: "white",
              left: 95,
              fontSize: 11,
              padding: 5,
            }}
          >
            <Slider
              keyboard={true}
              step={1}
              min={0}
              max={x ? x.length - 1 : 0}
              dots={false}
              value={slider}
              onChange={newValue => setSlider(newValue)}
              handleRender={renderProps => {
                if (x) {
                  const aux = x[slider];
                  const dateObj = new Date(aux);
                  const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
                  const noDate = 'Nenhum dispositivo instalado'
                  return (
                    <div {...renderProps?.props}>
                      {x.length > 0 &&
                        <div style={{ whiteSpace: "nowrap", color: "#000", marginTop: -25, marginLeft: -20, width: "fit-content" }}>
                          {formattedDate}
                        </div>
                      }
                    </div>
                  );
                }
              }}
            />
          </div>
        </div>
        <Tooltip target=".custom-target-move-right" />
        <MoveRightButton
          className='col-sm-1 custom-target-move-right'
          onClick={increment}
          data-pr-tooltip={intl.formatMessage({ id: "move_right" })}
          data-pr-position="right"
        >
          &gt;
        </MoveRightButton>
      </Row>
    </>
  );
}

export default SliderComponent;

