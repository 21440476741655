import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { sectorDelete } from '../../../services/dashboard';
import devs from "../../../assets/img/dropdown/exclamation.png";
import Switcher from 'react-switcher-rc';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { addLocale, locale } from 'primereact/api';
import { Card } from 'primereact/card';
import ConfirmModal from '../ConfirmModal/index';
import { MdInsertDriveFile, MdDelete } from 'react-icons/md';
import { ModalDelete } from '../../ModalDelete/index';

import 'primereact/resources/themes/lara-light-indigo/theme.css'; // theme
import 'primereact/resources/primereact.css';
import './style.css';
import { delete_macro_sector } from '../../../services/macro_sector';


const DataTableComp = ({ list, onUpdate }) => {
    locale('pt-BR')
    const toast = useRef(null);
    const navigate = useNavigate();
    const [sectors, setsectors] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [client, setClient] = useState(null);
    const [edit, setEdit] = useState(false);
    const [regionOptions, setRegionOptions] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [sectorData, setSectorData] = useState(null);

    addLocale('pt-BR', {
        apply: 'Aplicar',
        clear: 'Limpar'
    });

    useEffect(() => {
        setsectors(list);
        setLoading(false);
        initFilters();
    }, [list]);

    useEffect(() => {
        setEdit(false);
    }, [edit]);


    const initFilters = () => {
        setFilters({
            sectorName: { value: [], matchMode: FilterMatchMode.IN },
            alarmTypeName: { value: [], matchMode: FilterMatchMode.IN },
            alarmNoteId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
            seen: { value: null, matchMode: FilterMatchMode.EQUALS },
        });
    };

    const regionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.sectorName}</span>
            </div>
        );
    };

    const numberSectorsBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.subSector ? rowData.subSector.length : 0}</span>
            </div>
        );
    };

    const regionFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value || []}
                options={regionOptions}
                itemTemplate={regionItemTemplate}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Nenhum"
                className="p-column-filter"
            />
        );
    };

    const regionItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const callEditSector = (data) => {
        setVisible(false);
        setEdit(true);
        navigate('/edit-macro-sector', { state: { data: data } });
    }

    const deleteSector = (data) => {
        setShowModal(true);
        setSectorData(data);
    }

    const visualizationBodyTemplate = (options) => {
        return (
            <MdInsertDriveFile size={25} style={{ cursor: 'pointer' }} onClick={() => callEditSector(options)} />
        )
    }

    const visualizationDeleteBodyTemplate = (options) => {
        return (
            <MdDelete size={25} style={{ cursor: 'pointer' }} onClick={() => deleteSector(options)} />
        )
    }

    const handleCancel = () => {
        setShowModal(false);
    };

    const handleDelete = async () => {
        const aux = {
            "clientId": sectorData.clientId,
            "sectorId": sectorData.sectorId
        };
        try {
            await delete_macro_sector(aux);
            toast.current.show({ severity: 'success', summary: 'Atenção', detail: 'Setor excluído com sucesso.', life: 3000 });
            setShowModal(false);
            onUpdate();
        } catch (error) {
            const message = error.response.data.message;
            toast.current?.show({ severity: 'error', detail: message, life: 3000 });
            setShowModal(false);
        }

    };

    return (
        <div className="card" style={{ padding: 2 }}>
            <Toast ref={toast} />
            {showModal && (
                <ModalDelete
                    onDelete={handleDelete}
                    onCancel={handleCancel}
                    sectorData={sectorData}
                />
            )}
            <Dialog header={<div>
                <span>Nome: Cliente {client?.clientName}</span><br />
                <span>Setor: {client?.sectorName}</span>
            </div>} visible={visible} id='modal' onHide={() => setVisible(false)}>
                <div style={{ display: 'flex', marginTop: '3%' }}>
                    <div className='col-6' style={{ display: 'flex', justifyContent: 'space-evenly', alignSelf: 'center' }}>
                        <img src={client?.alarmNoteImg} style={{ width: '100%' }} />
                    </div>
                    {client && <div className='col-6'>
                        <div className='button-edit-custom'>
                            <div type='button' style={{ marginBottom: '2%', backgroundColor: '#88dac7', width: 30, textAlign: 'center' }}
                                onClick={() => callEditSector(client)}>
                                <i className="pi pi-file-edit" />
                            </div>
                        </div>
                        <Card id='card-selected'>
                            Teste
                        </Card>
                    </div>}
                </div>
            </Dialog>
            {sectors && <DataTable value={sectors} paginator showGridlines rows={10} loading={loading} dataKey={sectors.alarmNoteImg}
                filters={filters} globalFilterFields={['sectorName', 'mainInputPressPoint', 'mainInputPressPointElevation', 'mainPressCritialPoint', 'dvcList', 'seen']}
                emptyMessage="Nenhum resultado encotrado." style={{ marginTop: 40 }} className="custom-datatable"
                locale={"pt"}
                rowClassName={(rowData, rowIndex) =>
                    rowIndex % 2 === 0 ? 'p-datatable-row-even' : 'p-datatable-row-odd'
                }>
                <Column header="Nome do Macro Setor" filterField="sectorName" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }}
                    body={regionBodyTemplate} filter filterElement={regionFilterTemplate} />
                <Column header="Nº de Setores" filterField="sectorName" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }}
                    body={numberSectorsBodyTemplate} filter filterElement={regionFilterTemplate} />
                <Column field="" header="Editar" dataType="boolean" bodyClassName="text-center" style={{ maxWidth: '6rem' }} body={visualizationBodyTemplate} />
                <Column field="" header="Excluir" dataType="boolean" bodyClassName="text-center" style={{ maxWidth: '6rem' }} body={visualizationDeleteBodyTemplate} />
            </DataTable>}
        </div>
    );
}


export default DataTableComp;