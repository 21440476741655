import styled, { css } from 'styled-components';

export const Container = styled.button`

    width: 100%;

    margin: 7px 0;
    padding: 10px;

    border-radius: 5px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    background-color: ${props => props.theme.colors.success};

    transition: opacity .3s;

    &:hover {
        opacity: calc().7;
    }

`;