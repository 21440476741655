import styled from 'styled-components';

export const Container = styled.div`
  width: 250px;
  max-width: 100%;
`;

export const Heading = styled.h6``;

export const InfoItem = styled.div`
  strong {
    margin-right: 5px;
  }
`;

export const Button = styled.button`
  margin: 10px;
  zoom: 0.7;
  float: right;
`;