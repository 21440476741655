import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Paragraph, Item } from './styles';

const Legend = ({ kindPin }) => {
  return kindPin === "value" && (
    <Container>
      <Paragraph><FormattedMessage id="subtitle" /></Paragraph>
      <Item $bgColor="#e6e6e6" $textColor="#000"><FormattedMessage id="subtitle_hydraulic_load" /></Item>
      <Item $bgColor="#e6e6e6" $textColor="#000"><FormattedMessage id="subtitle_pressure" /></Item>
      <Item $bgColor="#e6e6e6" $textColor="#000"><FormattedMessage id="subtitle_delta" /></Item>
      <Item $bgColor="#000" $textColor="#fff"><FormattedMessage id="data_unavailable" /></Item>
    </Container>
  );
}

export default Legend;

