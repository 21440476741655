import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { delete_alarms, update_alarms } from '../../../services/alarms';
import devs from "../../../assets/img/dropdown/exclamation.png";
import Switcher from 'react-switcher-rc';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { addLocale, locale } from 'primereact/api';
import { Card } from 'primereact/card';
import ConfirmModal from '../ConfirmModal/index';
import { MdInsertDriveFile, MdDelete, MdEditDocument } from 'react-icons/md'
import { note_by_id_alarms } from "../../../services/alarms";

import 'primereact/resources/themes/lara-light-indigo/theme.css'; // theme
import 'primereact/resources/primereact.css';
import './style.css';

const messageModal = "Deseja realmente deletar esse alarme ?";

const DataTableComp = ({ list, onUpdate }) => {
    locale('pt-BR')
    const toast = useRef(null);
    const navigate = useNavigate();
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [client, setClient] = useState(null);
    const [time, setTime] = useState({});
    const [switcherState, setSwitcherState] = useState(false)
    const [edit, setEdit] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [regionOptions, setRegionOptions] = useState([]);
    const [alarmOptions, setAlarmOptions] = useState([]);

    addLocale('pt-BR', {
        apply: 'Aplicar',
        clear: 'Limpar'
    });

    const filterLocale = {
        filterHeader: 'Filtrar',
        filterPlaceHolder: 'Filtrar',
        filterMatchMode: 'Modo de filtragem',
        filterSelectAll: 'Selecionar todos',
        filterUnselectAll: 'Desmarcar todos',
        filterButtonClear: 'Limpar', // Traduzir o botão "Clear"
        filterButtonApply: 'Aplicar', // Traduzir o botão "Apply"
        // Outras traduções de filtros, se necessário
    };

    const onChangeInput = async (e) => {
        setSwitcherState(e.target.checked);
        try {
            let temp = client;
            temp.showToUser = e.target.checked;
            await update_alarms(temp);
            onUpdate();
            toast.current.show({ severity: 'success', summary: 'Atenção', detail: 'Propriedade Mostrar para o cliente, alterado com sucesso.', life: 3000 });
        } catch (error) {
            if (error.response) {
                const msg = error.response.data.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
            } else if (error.request) {
                const message = error.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
            }
        }
    }

    useEffect(() => {
        setCustomers(getCustomers(list));
        setLoading(false);
        initFilters();
    }, [list]);

    useEffect(() => {
        setEdit(false);
    }, [edit]);

    useEffect(() => {
        if (client) {
            setSwitcherState(client.showToUser);
        }
    }, [client])

    useEffect(() => {
        setCustomers(getCustomers(list));
        setLoading(false);
        initFilters();

        const uniqueRegions = getUniqueRegions(list);
        setRegionOptions(uniqueRegions);
    }, [list]);


    useEffect(() => {
        setCustomers(getCustomers(list));
        setLoading(false);
        initFilters();

        const uniqueRegions = getUniqueRegions(list);
        setRegionOptions(uniqueRegions);

        const uniqueAlarmTypes = getUniqueAlarmTypes(list);
        setAlarmOptions(uniqueAlarmTypes);
    }, [list]);

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.alarmNoteDate);
            return d;
        });
    };


    const formatDate = (value) => {
        return value.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
    };

    const initFilters = () => {
        setFilters({
            sectorName: { value: [], matchMode: FilterMatchMode.IN },
            alarmTypeName: { value: [], matchMode: FilterMatchMode.IN },
            alarmNoteId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
            seen: { value: null, matchMode: FilterMatchMode.EQUALS },
        });
    };

    const regionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.sectorName}</span>
            </div>
        );
    };

    const alarmBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.alarmTypeName}</span>
            </div>
        );
    };

    const identifierBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.alarmNoteId}</span>
            </div>
        );
    };

    const addressBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.address}</span>
            </div>
        );
    };

    const removeBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.removedByUser === true ? 'Sim' : 'Não'}</span>
            </div>
        );
    };

    const obsBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.clientObs && rowData.clientObs[0].date ? rowData.clientObs[0].date : ''}</span>
            </div>
        );
    };

    const obsBodyTemplateUser = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.clientObs && rowData.clientObs[0].userName ? rowData.clientObs[0].userName : ''}</span>
            </div>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.shortDescription}</span>
            </div>
        );
    };

    const seeBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.seen === true ? 'Sim' : 'Não'}</span>
            </div>
        );
        // return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    };

    const handleStatusChange = (rowData) => (e) => {
        const newCustomers = customers.map((customer) =>
            customer === rowData ? { ...customer, status: e.checked } : customer
        );
        setCustomers(newCustomers);
    };

    const regionFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value || []}
                options={regionOptions}
                itemTemplate={regionItemTemplate}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Nenhum"
                className="p-column-filter"
            />
        );
    };


    // const regionFilterTemplate = (options) => {
    //     return <MultiSelect value={options.value || []} options={customers.map((customer) => customer.sectorName)} itemTemplate={regionItemTemplate} onChange={(e) => options.filterCallback(e.value)} placeholder="Nenhum" className="p-column-filter" />;
    // };

    const regionItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    // const alarmFilterTemplate = (options) => {
    //     return <MultiSelect value={options.value || []} options={customers.map((customer) => customer.alarmTypeName)} itemTemplate={alarmItemTemplate} onChange={(e) => options.filterCallback(e.value)} placeholder="Nenhum" className="p-column-filter" />;
    // };

    const alarmFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value || []}
                options={alarmOptions}
                itemTemplate={alarmItemTemplate}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Nenhum"
                className="p-column-filter"
            />
        );
    };

    const alarmItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    // const identifierFilterTemplate = (options) => {
    //     return <MultiSelect value={options.value || []} options={customers.map((customer) => customer.alarmTypeId)} itemTemplate={identifierItemTemplate} onChange={(e) => options.filterCallback(e.value)} placeholder="Nenhum" className="p-column-filter" />;
    // };

    const identifierFilterTemplate = (options) => {
        return (
            <InputText
                value={options.value || []}
                onChange={(e) => options.filterCallback(e.target.value)}
                placeholder="Filtrar por identificador"
                className="p-column-filter"
            />
        );
    };

    const identifierItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
                mask="99/99/9999"
                showIcon={false}
            />
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <Checkbox disabled checked={rowData.showToUser} style={{ cursor: 'default' }} />
            </div>
        );
    };

    const seeFilterTemplate = (options) => {
        const seeOptions = [
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
        ];

        return (
            <Dropdown
                value={options.value}
                options={seeOptions}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Nenhum"
            />
        );
    };

    const formatTime = (time) => {
        const dateObject = new Date(time);

        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        const seconds = dateObject.getSeconds();

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();

        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');

        const aux = {
            hour: `${formattedHours}:${formattedMinutes}:${formattedSeconds}`,
            date: `${formattedDay}/${formattedMonth}/${year}`
        }
        setTime(aux);
    };

    const callModal = async (options) => {
        const { clientId, alarmNoteId } = options;
        
        try {
            const response = await note_by_id_alarms({
                clientId, 
                alarmNoteId, 
                ignoreDeleted: true
            });
            
            if (response?.data) {
                const updatedOptions = {
                    ...options,
                    alarmNoteImg: response.data.alarmNoteImg,
                };
    
                setClient(updatedOptions);
                formatTime(updatedOptions.date);
                setVisible(true);
            } else {
                console.error('No data found in response');
            }
        } catch (error) {
            console.error('Error fetching alarm note:', error);
        }
    };     
    
    const callEditAlarm = (data) => {
        setVisible(false);
        setEdit(true);
        navigate('/edit-alarm', { state: { data: data } });
    }
    
    const visualizationBodyTemplate = (options) => {
        return (
            <MdInsertDriveFile size={25} style={{ cursor: 'pointer' }} onClick={() => callModal(options)} />
        )
    }

    const deletAlarm = () => {
        setConfirm(true);
    }

    const handleButtonYes = async () => {
        const payload = {
            clientId: client.clientId,
            alarmNoteId: client.alarmNoteId
        }
        try {
            await delete_alarms(payload);
            setVisible(false);
            setConfirm(false);
            onUpdate();
            toast.current.show({ severity: 'success', summary: 'Atenção', detail: 'Alarme deletado com sucesso.', life: 3000 });
        } catch (error) {
            if (error.response) {
                const msg = error.response.data.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
            } else if (error.request) {
                const message = error.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
            }
            setConfirm(false);
        }
    }

    const handleButtonNo = () => {
        setConfirm(false);
    }

    const imageBodyEvent = (alarmTypeId) => {
        const alarmTypeImages = {
            "Falta de Água": "falta_agua_red.png",
            "Fechamento de registro de rede": "fechamento_red.png",
            "Mistura de setor": "mistura_red.png",
            "Reparo de vazamento": "reparo_red.png",
            "Suspeita de Fraude ou usos não medidos": "suspeita_red.png",
            "Vazamento": "vazament_red.png",
        };
        if (alarmTypeId in alarmTypeImages) {
            return <img src={require(`../../../assets/img/dropdown/${alarmTypeImages[alarmTypeId]}`)} alt={""} className="w-4rem" style={{ width: 60, marginLeft: 30 }} />
        } else {
            return <img src={devs} alt={""} className="w-4rem" style={{ width: 60 }} />
        }

    };

    const getUniqueRegions = (data) => {
        if (!data) return [];
        const uniqueRegions = new Set();
        data.forEach((d) => uniqueRegions.add(d.sectorName));
        return Array.from(uniqueRegions);
    };

    const getUniqueAlarmTypes = (data) => {
        if (!data) return [];
        const uniqueAlarmTypes = new Set();
        data.forEach((d) => uniqueAlarmTypes.add(d.alarmTypeName));
        return Array.from(uniqueAlarmTypes);
    };

    return (
        <div className="card" style={{ padding: 2 }}>
            <Toast ref={toast} />
            <ConfirmModal show={confirm} onYesClick={handleButtonYes} onNoClick={handleButtonNo} onClose={handleButtonNo} message={messageModal} />
            <Dialog header={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <span>Nome: Cliente {client?.clientName}</span><br />
                    <span>Setor: {client?.sectorName}</span>
                </div>
                <div style={{ marginRight: '1vw' }}>
                    <Switcher
                        onChange={onChangeInput}
                        disabled={false}
                        checked={switcherState}
                        name="switcherName"
                        checkedIcon={<span style={{ fontSize: 12, bottom: 3, position: "relative" }}>Ativado</span>}
                        unCheckedIcon={<span style={{ fontSize: 12, bottom: 3, position: "relative" }}>Inativo</span>}
                    />
                </div>
            </div>} visible={visible} id='modal' onHide={() => setVisible(false)}>
                <div style={{ display: 'flex', marginTop: '3%' }}>
                    <div className='col-6' style={{ display: 'flex', justifyContent: 'space-evenly', alignSelf: 'center' }}>
                        <img src={client?.alarmNoteImg} style={{ width: '100%' }} />
                    </div>
                    {client && <div className='col-6'>
                        <Card id='card-selected'>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}><p className='font-card-alarms'>Alarme: {client.alarmTypeName}</p>{imageBodyEvent(client.alarmTypeName)}</div>
                            <p className='font-card-alarms'>Visto: {client.seen ? 'Sim' : 'Não'}</p>
                            <p className='font-card-alarms'>Identificador: {client.alarmNoteId}</p>
                            <p className='font-card-alarms'>Horário: {time.hour}</p>
                            <p className='font-card-alarms'>Data: {time.date}</p>
                            <p className='font-card-alarms'>Descrição: {client.completeDescription}</p>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><p className='font-card-alarms'>Observação: </p>
                                <div style={{ border: '1px solid grey', borderRadius: 5, height: 120, width: '100%', overflowY: 'scroll', marginLeft: 5, padding: '0px 10px 0px 10px' }}>
                                    {client && client.clientObs && Array.isArray(client.clientObs) ?
                                        client.clientObs.map((a, index) => (
                                            <div key={index} style={{ marginBottom: 15 }}>
                                                {a && a.obs && <div style={{ display: 'flex', justifyContent: 'space-around', fontSize: '13px', fontWeight: 'bold' }}>
                                                    <span>Autor: {a.userName}</span>
                                                    <span>Data: {a.date}</span>
                                                </div>}
                                                {a.obs}
                                            </div>
                                        )) : <p className='font-card-alarms'>{client.clientObs}</p>

                                    }
                                </div>
                            </div>
                        </Card>
                        <div style={{ marginTop: '10%', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                            <button className="btn" style={{ zoom: 0.80, borderRadius: 20, marginTop: 10, backgroundColor: '#8086C0', color: '#fff', fontSize: 16, width: 120, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                                onClick={() => callEditAlarm(client)}>Editar <MdEditDocument size={20} /></button>

                            <button className="btn btn-danger" style={{ zoom: 0.80, borderRadius: 20, marginTop: 10, fontSize: 16, width: 120, display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginLeft: '3vw', marginRight: '0.8vw' }}
                                onClick={() => deletAlarm()}>Deletar <MdDelete size={20} /></button>
                        </div>
                    </div>}
                </div>
            </Dialog>
            {customers && <DataTable value={customers} paginator showGridlines rows={10} loading={loading} dataKey={customers.alarmNoteImg}
                filters={filters} globalFilterFields={['sectorName', 'alarmTypeName', 'alarmNoteId', 'alarmNoteDate', 'seen']}
                emptyMessage="Nenhum resultado encotrado." style={{ marginTop: 40 }} className="custom-datatable"
                locale={"pt"}
                rowClassName={(rowData, rowIndex) =>
                    rowIndex % 2 === 0 ? 'p-datatable-row-even' : 'p-datatable-row-odd'
                }>
                <Column header="Região de instalação" filterField="sectorName" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }}
                    body={regionBodyTemplate} filter filterElement={regionFilterTemplate} />
                <Column header="Alarme" filterField="alarmTypeName" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '10rem' }}
                    body={alarmBodyTemplate} filter filterElement={alarmFilterTemplate} />
                <Column header="Identificador" filterField="alarmNoteId" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ maxWidth: '10rem' }}
                    body={identifierBodyTemplate} filter filterElement={identifierFilterTemplate} filterMatchMode="startsWith" />
                <Column header="Data" field="alarmNoteDate" dataType="date" style={{ minWidth: '6rem' }} sortable body={dateBodyTemplate} />
                <Column field="removedByUser" header="Arquivado" style={{ minWidth: '6rem' }} body={removeBodyTemplate} />
                <Column field="clientObs" header="Última Obs" style={{ minWidth: '4rem' }} body={obsBodyTemplate} />
                <Column field="clientObs" header="Últ. Usuário" style={{ minWidth: '4rem' }} body={obsBodyTemplateUser} />
                <Column field="status" header="Status" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '5rem' }} body={statusBodyTemplate} />
                <Column header="Descrição resumida" filterField="shortDescription" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }}
                    body={descriptionBodyTemplate} />
                <Column field="seen" header="Visto" dataType="boolean" style={{ maxWidth: '5.8rem' }} bodyClassName="text-center" body={seeBodyTemplate} filter filterElement={seeFilterTemplate} />
                <Column field="" header="Visualizar" dataType="boolean" bodyClassName="text-center" style={{ maxWidth: '6rem' }} body={visualizationBodyTemplate} />
            </DataTable>}
        </div>
    );
}


export default DataTableComp;