import React, { useState, useEffect, useRef } from "react";
import { Toast } from 'primereact/toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog } from 'primereact/dialog';
import { export_report } from '../../services/dashboard';
import useCompanySectorStore from "../../store/useCompanySectorStore";
import "./style.css";

const ExportReport = ({ onShow, onClose }) => {
  const {clientIdSelected, sectorIdSelected, startDateSelected, endDateSelected} = useCompanySectorStore();
  const toast = useRef(null);
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [emails, setEmails] = useState(['']);
  const [emailErrors, setEmailErrors] = useState(['']);

  useEffect(() => {
    if (onShow) {
      setShowModal(true);
    } else if (!onShow) {
      setShowModal(false);
      onClose();
    }
  }, [onShow])

  const closeModalExport = () => {
    setShowModal(false);
    setEmails(['']);
    onClose();
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);

    const updatedEmailErrors = [...emailErrors];
    updatedEmailErrors[index] = value.length <= 5 ? intl.formatMessage({ id: "error_address_email" }) : '';
    setEmailErrors(updatedEmailErrors);
  };

  const handleAddEmail = () => {
    setEmails([...emails, '']);
    setEmailErrors([...emailErrors, '']);
  };

  const handleSendEmails = () => {

    const payload = {
      "email": emails,
      "sectorId": sectorIdSelected,
      "dti": startDateSelected,
      "dtf": endDateSelected,
      "clientId": clientIdSelected,
    }

    try {
      export_report(payload);
      setShowModal(false);
      setEmails(['']);
      setEmailErrors(['']);
      onClose();
      toast.current.show({ severity: 'success', summary: intl.formatMessage({ id: "success_send_email_report" }), life: 3000 });
    } catch (error) {
      setShowModal(false);
      onClose();
      const message = error.response.data.message;
      toast.current?.show({ severity: 'error', summary: intl.formatMessage({ id: "error_send_email_report" }), detail: message, life: 3000 });
    }

  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);

    const updatedEmailErrors = [...emailErrors];
    updatedEmailErrors.splice(index, 1);
    setEmailErrors(updatedEmailErrors);
  };

  const isSendButtonDisabled = emails.some(email => email.length <= 5);

  return (
    <>
      <Toast ref={toast} />
      <Dialog header={intl.formatMessage({ id: "export_report" })} visible={showModal} id="modal-email" onHide={closeModalExport}
        style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
        <div>
          {emails.map((email, index) => (
            <div key={index} style={{ marginBottom: 20 }}>
              <div style={{ display: 'flex' }}>
                <input
                  type="email"
                  placeholder={intl.formatMessage({ id: "type_email" })}
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                  style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '2px' }}
                />

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveEmail(index)}
                  style={{ marginLeft: 10 }}
                >
                  X
                </button>
              </div>
              {emailErrors[index] && <div style={{ color: 'red', fontSize: 12 }}>{emailErrors[index]}</div>}
            </div>
          ))}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleAddEmail} style={{ backgroundColor: 'rgb(128, 134, 192)', borderColor: 'rgb(128, 134, 192)' }}
            ><FormattedMessage id="add_button" />
            </button>
          </div>
        </div>
        <div style={{ textAlign: 'center', marginTop: 30 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSendEmails}
            disabled={isSendButtonDisabled}
            style={
              !isSendButtonDisabled ? { backgroundColor: 'rgb(110, 255, 148)', borderColor: 'rgb(110, 255, 148)', color: '#000' } :
                { backgroundColor: '#cdcdcd', borderColor: '#cdcdcd', color: '#000' }
            }><FormattedMessage id="send_button" />
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default ExportReport;
