import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledButton = styled.button`
  font-size: 20px;
  width: 50px;
  border: none;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ColorInput = styled.input`
  height: 2.2rem;
`;

export const FileNameInput = styled.input`
  border: 1px solid #dfdfdf;
`;

export const CanvasContainer = styled.div`
  overflow: hidden;
`;