import moment from "moment";
import 'moment/locale/pt-br'
//moment.locale("pt-br");

export const xxconvertDeviceMap = (devices) => {
  let positionMap = {};
  if (devices) {
    for (let i in devices) {
      let id = String(devices[i].ID);
      if (positionMap[id] == undefined) {
        positionMap[id] = devices[i];
      }
    }
  }

  return positionMap;
}

export const convertDeviceMap = (devices) => {
  let positionMap = {};
  if (devices) {
    for (let i in devices) {
      let id = String(devices[i].deviceId);
      if (positionMap[id] == undefined) {
        positionMap[id] = devices[i];
      }
    }
  }

  return positionMap;
}

export const mergeDevices = (devices, schemeDevices) => {
  const mergedDevices = {};
  schemeDevices.forEach(dev => {
    const deviceId = dev.deviceId;
    if (devices[deviceId]) {
      mergedDevices[deviceId] = {
        ...devices[deviceId],
        elevation: dev.elevation,
        type: dev.type,
        dataType: dev.dataType
      };
    }
  });
  return mergedDevices;
}

export const convertMaxScale = (deviceId, devicesArray, pressureArray) => {
  const stringDeviceId = deviceId.toString();
  const selectedDevice = devicesArray.find(device => device.deviceId === stringDeviceId);
  const filteredPressureData = pressureArray.filter(item => item.deviceId === stringDeviceId);
  filteredPressureData.sort((a, b) => b.timestamp - a.timestamp);

  if (filteredPressureData.length > 0) {
    const maxPressure = filteredPressureData[0].singleValue;
    const deviceElevation = selectedDevice?.elevation;

    if (deviceElevation !== undefined) {
      return Math.round(maxPressure + deviceElevation);
    }
  } else {
    return 0;
  }
}

export const xxxgetReferenceDate = (period, ref) => {
  const ndays = period === "Semanal" ? 7 : period === "Quinzenal" ? 15 : period === "Mensal" ? 30 : 60
  var ref_date = null;
  if (ndays !== null) {
    ref_date = new Date(new Date(ref) - ndays * 24 * 60 * 60 * 1000);
  }
  return ref_date;
}

export const getReferenceDate = (period, ref) => {
  const ndays = period === "Semanal" ? 7 : period === "Quinzenal" ? 15 : period === "Mensal" ? 30 : 60
  var ref_date = null;
  if (ndays !== null) {
    ref_date = new Date(new Date(ref) - ndays * 24 * 60 * 60 * 1000);
  }
  return ref_date;
}

export const getDatetimeFromTimestamp = (ts) => {
  return new Date(ts + new Date().getTimezoneOffset() * 60 * 1000);
}

export const ts2date = (ts, full) => {
  var m = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
  var t = typeof (ts) === "number" ? new Date(ts + new Date().getTimezoneOffset() * 60 * 1000) : new Date(ts);
  return (t.getDate() < 10 ? '0' + t.getDate() : t.getDate()) + " / " + m[t.getMonth()] + " " + t.getFullYear() + (full ? (" " + (t.getHours() < 10 ? "0" + t.getHours() : t.getHours()) + ":" + (t.getMinutes() < 10 ? "0" + t.getMinutes() : t.getMinutes())) : "");
}


export const toSingleDataset = (timeX, vMap) => {
  let dt = [];
  for (let j in timeX) {
    let d = timeX[j];
    dt.push(vMap[d]);
  }
  return dt;
}

export const mathRound = (num) => {
  return Math.round(100 * num) / 100;
}

export const mathMean = (arr, field) => {
  var sum = 0, n = 0;
  for (let i in arr) {
    var val = arr[i][field];
    if (val != null) {
      sum += val;
      n++;
    }
  }
  if (n == 0)
    return null;
  else
    return sum / n;
}

export const mathStd = (arr, mu, field) => {
  var sum = 0, n = 0;
  for (let i in arr) {
    var val = arr[i][field];
    if (val != null) {
      sum += (val - mu) * (val - mu);
      n++;
    }
  }
  if (n == 0)
    return null;
  if (n == 1)
    return sum;
  else
    return Math.sqrt(sum / (n - 1));;
}

export const alarmValueQuantization = (v, mu, std) => {

  std = 2;

  if (v == null)
    return "black";
  else if (v >= (mu - std))
    return "green";
  else if (v >= (mu - 2 * std))
    return "yellow";
  else if (v >= (mu - 3 * std))
    return "purple";
  else
    return "red";
}

export const alarmValueQuantizationDelta = (v) => {
  if (v == null)
    return "black";
  else if (v >= -2)
    return "green";
  else if (v >= -4)
    return "yellow";
  else if (v >= -6)
    return "purple";
  else
    return "red";
}

export const convertDeviceAPI = (data) => {
  const aux = data ? data.data : [];
  const uniqueDevicesMap = {};
  const uniqueDevicesArray = [];

  for (const obj of aux) {
    const deviceId = obj.deviceId;
    if (!uniqueDevicesMap[deviceId]) {
      uniqueDevicesMap[deviceId] = true;
      const { long, ...rest } = obj;
      const newObj = { ...rest, lon: long };
      uniqueDevicesArray.push(newObj);
    }
  }
  return uniqueDevicesArray;
}

export const timeLocationToPosition = (time_location_reading, devices, ref_date) => {
  let positionMap = {}, positionAcum = {};
  if (time_location_reading)
    for (let i in time_location_reading) {
      if (ref_date != null && getDatetimeFromTimestamp(time_location_reading[i].datetime) < ref_date)
        continue;

      const device_id = time_location_reading[i].deviceId;
      if (!isNaN(devices[device_id].lat) && !isNaN(devices[device_id].lon)) {
        if (positionMap[device_id] == null) {
          positionMap[device_id] = [];
          positionAcum[device_id] = {
            id: device_id,
            device: devices[device_id].deviceId,
            install_point: devices[device_id].installPointName,
            lat: devices[device_id].lat,
            lon: devices[device_id].lon,
            color: "",
            pressure: 0,
            h_load: 0,
            h_load_diff: 0
          };
        }
        positionMap[device_id].push({
          id: device_id,
          device: devices[device_id].deviceId,
          install_point: devices[device_id].installPointName,
          lat: devices[device_id].lat,
          lon: devices[device_id].lon,
          datetime: time_location_reading[i].datetime,
          datestr: getDatetimeFromTimestamp(time_location_reading[i].datetime).toDateString(),
          p_avg: time_location_reading[i].pressAvg,
          h_load: time_location_reading[i].mvn,
          h_load_diff: time_location_reading[i].delta,

          color: "",
          color_delta: ""
        });
      }
    }

  for (let id in positionMap) {
    const mu_pavg = mathMean(positionMap[id], "p_avg");
    const mu_hload = mathMean(positionMap[id], "h_load");
    const mu_hload_diff = mathMean(positionMap[id], "h_load_diff");
    const std_hload = mathStd(positionMap[id], mu_hload, "h_load");

    positionAcum[id].pressure = mu_pavg;
    positionAcum[id].h_load = mu_hload;
    positionAcum[id].h_load_diff = mu_hload_diff;
    positionAcum[id].color = alarmValueQuantization(positionMap[id][positionMap[id].length - 1].h_load, mu_hload, std_hload);

    for (let i in positionMap[id]) {
      positionMap[id][i].color = alarmValueQuantization(positionMap[id][i].h_load, mu_hload, std_hload);
      positionMap[id][i].color_delta = alarmValueQuantizationDelta(positionMap[id][i].h_load_diff);
    }
  }
  return { positions: Object.values(positionAcum), locations: positionMap };
}

export const xxxtimeLocationToPosition = (time_location_reading, devices, ref_date) => {
  let positionMap = {}, positionAcum = {};
  if (time_location_reading)
    for (let i in time_location_reading) {
      if (ref_date != null && getDatetimeFromTimestamp(time_location_reading[i].date) < ref_date)
        continue;

      const device_id = time_location_reading[i].ID;

      if (positionMap[device_id] == null) {
        positionMap[device_id] = [];
        positionAcum[device_id] = {
          id: device_id,
          device: devices[device_id].device_id,
          install_point: devices[device_id].system_id,
          lat: devices[device_id].lat,
          lon: devices[device_id].lon,
          color: "",
          pressure: 0,
          h_load: 0,
          h_load_diff: 0
        };
      }

      positionMap[device_id].push({
        id: device_id,
        device: devices[device_id].device_id,
        install_point: devices[device_id].system_id,
        lat: devices[device_id].lat,
        lon: devices[device_id].lon,
        datetime: time_location_reading[i].date,
        datestr: getDatetimeFromTimestamp(time_location_reading[i].date).toDateString(),
        p_avg: time_location_reading[i].p_avg,
        h_load: time_location_reading[i].h_load,
        h_load_diff: time_location_reading[i].h_load_diff,

        color: "",
        color_delta: ""
      });
    }

  for (let id in positionMap) {
    const mu_pavg = mathMean(positionMap[id], "p_avg");
    const mu_hload = mathMean(positionMap[id], "h_load");
    const mu_hload_diff = mathMean(positionMap[id], "h_load_diff");
    const std_hload = mathStd(positionMap[id], mu_hload, "h_load");

    positionAcum[id].pressure = mu_pavg;
    positionAcum[id].h_load = mu_hload;
    positionAcum[id].h_load_diff = mu_hload_diff;
    positionAcum[id].color = alarmValueQuantization(positionMap[id][positionMap[id].length - 1].h_load, mu_hload, std_hload);

    for (let i in positionMap[id]) {
      positionMap[id][i].color = alarmValueQuantization(positionMap[id][i].h_load, mu_hload, std_hload);
      positionMap[id][i].color_delta = alarmValueQuantizationDelta(positionMap[id][i].h_load_diff);
    }
  }

  return { positions: Object.values(positionAcum), locations: positionMap };
}

export const splineInterpolation = (points, xf) => {
  points = points.filter(p => p.x !== undefined && p.y !== undefined);
  const n = points.length - 1;
  let a = points.map(point => point.y);
  let b = new Array(n);
  let d = new Array(n);
  let h = new Array(n);

  for (let i = 0; i < n; i++) {
    h[i] = points[i + 1].x - points[i].x;
  }

  let alpha = new Array(n);
  for (let i = 1; i < n; i++) {
    alpha[i] = (3 / h[i]) * (a[i + 1] - a[i]) - (3 / h[i - 1]) * (a[i] - a[i - 1]);
  }

  let c = new Array(n + 1).fill(0);
  let l = new Array(n + 1).fill(0);
  let mu = new Array(n + 1).fill(0);
  let z = new Array(n + 1).fill(0);

  l[0] = 1;
  for (let i = 1; i < n; i++) {
    l[i] = 2 * (points[i + 1].x - points[i - 1].x) - h[i - 1] * mu[i - 1];
    mu[i] = h[i] / l[i];
    z[i] = (alpha[i] - h[i - 1] * z[i - 1]) / l[i];
  }
  l[n] = 1;

  for (let i = n - 1; i >= 0; i--) {
    c[i] = z[i] - mu[i] * c[i + 1];
    b[i] = (a[i + 1] - a[i]) / h[i] - h[i] * (c[i + 1] + 2 * c[i]) / 3;
    d[i] = (c[i + 1] - c[i]) / (3 * h[i]);
  }

  function findInterval(x) {
    let i = 0;
    while (i < n && points[i + 1].x < x) {
      i++;
    }
    return i;
  }

  function interpolate(x) {
    const i = findInterval(x);
    const dx = x - points[i].x;
    return a[i] + b[i] * dx + c[i] * Math.pow(dx, 2) + d[i] * Math.pow(dx, 3);
  }

  function singleInterpolate(points, xf) {
    if (xf < points[0].x) {
      const i = 0;
      const dx = xf - points[i].x;
      return mathRound(a[i] + b[i] * dx);
    } else if (xf > points[n].x) {
      const i = n - 1;
      const dx = xf - points[i].x;
      return mathRound(a[i] + b[i] * dx);
    } else {
      return mathRound(interpolate(xf));
    }
  }

  if (Array.isArray(xf)) {
    let yf = new Array(xf.length);
    for (let i in xf) {
      yf[i] = singleInterpolate(points, xf[i])
    }
    return yf;
  }
  return singleInterpolate(points, xf)
}

export const linearInterpolation = (points, xf) => {
  points = points.filter(p => p.x !== undefined && p.y !== undefined);

  function findInterval(x) {
    for (let i = 0; i < points.length - 1; i++) {
      if (x >= points[i].x && x <= points[i + 1].x) {
        return i;
      }
    }
    return -1;
  }

  function interpolate(x, i) {
    const x1 = points[i].x;
    const x2 = points[i + 1].x;
    const y1 = points[i].y;
    const y2 = points[i + 1].y;

    return y1 + ((x - x1) * (y2 - y1)) / (x2 - x1);
  }

  function singleInterpolate(points, xf) {
    const intervalIndex = findInterval(xf);
    if (intervalIndex === -1) {
      if (xf < points[0].x) {
        return mathRound(interpolate(points[0].x, 0) - (points[1].x - xf) * (interpolate(points[1].x, 0) - interpolate(points[0].x, 0)) / (points[1].x - points[0].x));
      } else {
        const lastIndex = points.length - 2;
        return mathRound(interpolate(points[lastIndex + 1].x, lastIndex) + (xf - points[lastIndex + 1].x) * (interpolate(points[lastIndex + 1].x, lastIndex) - interpolate(points[lastIndex].x, lastIndex)) / (points[lastIndex + 1].x - points[lastIndex].x));
      }
    }
    return mathRound(interpolate(xf, intervalIndex));
  }

  if (Array.isArray(xf)) {
    let yf = [];
    for (let i in xf) {
      yf.push(singleInterpolate(points, xf[i]));
    }
    return yf;
  }
  return singleInterpolate(points, xf);
}

export const ALL_64_COLOR = ["#FFC300", "#E84C3D", "#FFC107", "#9C27B0", "#FF9800", "#673AB7", "#F44336", "#4CAF50", "#E91E63", "#8BC34A", "#3F51B5", "#FF5722", "#2196F3", "#FF4081", "#00BCD4", "#FFEB3B", "#9E9E9E", "#CDDC39", "#795548", "#00ACC1", "#607D8B", "#FF6F00", "#689F38", "#9C27B0", "#3F51B5", "#E64A19", "#4CAF50", "#8BC34A", "#673AB7", "#F44336", "#2196F3", "#009688", "#FF9800", "#FFC107", "#4E342E", "#FFEB3B", "#607D8B", "#E91E63", "#8E24AA", "#9E9E9E", "#3F51B5", "#00BCD4", "#CDDC39", "#FF6F00", "#FF4081", "#795548", "#9C27B0", "#FFC107", "#F44336", "#4CAF50", "#E91E63", "#673AB7", "#FF5722", "#2196F3", "#FF9800", "#8BC34A", "#00ACC1", "#E64A19", "#9E9E9E", "#607D8B", "#795548", "#CDDC39", "#3F51B5", "#FFEB3B", "#4D4D4D", "#005CAF", "#5F5F5F", "#D02E29", "#A0C238", "#57A3CF", "#DAAA00", "#58595B", "#235789", "#6CABDD", "#E37222", "#A7A9AC", "#007A33", "#0081AB", "#A09F9C", "#FFA07A", "#DE3163", "#008080", "#BFBFBF", "#FFD700", "#FF00FF", "#4B0082", "#000080", "#8B0000", "#800080", "#808000", "#FF00FF", "#00FFFF", "#800000", "#008000", "#0000FF", "#FFFF00", "#FFD700", "#FF69B4", "#1E90FF", "#F08080", "#90EE90", "#BA55D3", "#F0E68C", "#AFEEEE", "#FA8072", "#87CEFA", "#DDA0DD", "#32CD32", "#7B68EE", "#00FF7F", "#FFA500", "#7FFF00", "#FF7F50", "#20B2AA", "#BA55D3", "#00BFFF", "#9370DB", "#FF6347", "#66CDAA", "#8B008B", "#40E0D0", "#E6E6FA", "#6495ED", "#FA8072", "#9ACD32", "#FF69B4", "#00CED1", "#BDB76B"];

export const RED_COLOR = "#ff0000", YELLOW_COLOR = "#f4c93b", GREEN_COLOR = "#10b152", PURPLE_COLOR = "#7030a1";
