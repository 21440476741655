import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import 'primeicons/primeicons.css';

import { Container, ButtonContainer, StyledButton, ColorInput, FileNameInput, CanvasContainer } from './styles';

const ImageEditorComponent = ({ image, width, height }) => {
  const { editor, onReady } = useFabricJSEditor();
  const [fileName, setFileName] = useState('imagem_editada.png');
  const [imageLoaded, setImageLoaded] = useState(null);
  const [toggleDrawSelected, setToggleDrawSelected] = useState(false);

  const history = [];
  const [color, setColor] = useState("#35363a");

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }

    if (!editor.canvas.__eventListeners["mouse:wheel"]) {
      editor.canvas.on("mouse:wheel", function (opt) {
        var delta = opt.e.deltaY;
        var zoom = editor.canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        editor.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });
    }

    if (!editor.canvas.__eventListeners["mouse:down"]) {
      editor.canvas.on("mouse:down", function (opt) {
        var evt = opt.e;
        if (evt.ctrlKey === true) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
        }
      });
    }

    if (!editor.canvas.__eventListeners["mouse:move"]) {
      editor.canvas.on("mouse:move", function (opt) {
        if (this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });
    }

    if (!editor.canvas.__eventListeners["mouse:up"]) {
      editor.canvas.on("mouse:up", function (opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
      });
    }

    editor.canvas.renderAll();
  }, [editor]);


  useEffect(() => {

    setImageLoaded(image);

  }, [image])


  const addBackground = () => {
    if (!editor || !fabric) {
      return;
    }

    fabric.Image.fromURL(
      imageLoaded,
      (image) => {
        editor.canvas.setBackgroundImage(
          image,
          editor.canvas.renderAll.bind(editor.canvas),
        );
        editor.canvas.setDimensions({ width, height });
      }
    );
  };


  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.setHeight(500);
    editor.canvas.setWidth(500);
    addBackground();
    editor.canvas.renderAll();
  }, [editor?.canvas.backgroundImage]);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.freeDrawingBrush.color = color;
    editor.setStrokeColor(color);
  }, [color]);

  const toggleDraw = () => {
    editor.canvas.isDrawingMode = !editor.canvas.isDrawingMode;
    setToggleDrawSelected(!toggleDrawSelected);
  };
  const undo = () => {
    if (editor.canvas._objects.length > 0) {
      history.push(editor.canvas._objects.pop());
    }
    editor.canvas.renderAll();
  };

  const removeSelectedObject = () => {
    editor.canvas.remove(editor.canvas.getActiveObject());
  };

  const onAddCircle = () => {
    editor.addCircle();
  };
  const onAddRectangle = () => {
    editor.addRectangle();
  };

  const Download = () => {
    if (editor?.canvas) {
      // Cria um novo canvas temporário com as mesmas dimensões do canvas original
      const tempCanvas = new fabric.Canvas(null, {
        width: editor.canvas.getWidth(),
        height: editor.canvas.getHeight(),
      });

      // Adiciona uma imagem de fundo ao novo canvas com as mesmas dimensões do canvas original
      const img = new window.Image();
      img.onload = () => {
        const fabricImage = new window.fabric.Image(img, {
          width: editor.canvas.getWidth(),
          height: editor.canvas.getHeight(),
        });
        tempCanvas.add(fabricImage);

        // Adiciona apenas as formas desejadas (círculos e retângulos) ao novo canvas
        editor.canvas.forEachObject((obj) => {
          if (obj.type === 'circle' || obj.type === 'rect') {
            const cloneObj = fabric.util.object.clone(obj);
            tempCanvas.add(cloneObj);
          }
        });

        // Renderiza o novo canvas com as formas no elemento <canvas>
        tempCanvas.renderAll();

        // Cria a URL da imagem do novo canvas
        const dataURL = editor.canvas.toDataURL({
          format: "png",
          quality: 1,
        });

        const link = document.createElement('a');
        link.download = fileName;
        link.href = dataURL;
        link.click();
      };
      img.src = imageLoaded;
    }
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  return (
    <Container>
      <ButtonContainer>
        <StyledButton className="pi pi-circle" onClick={onAddCircle} disabled={toggleDrawSelected}></StyledButton>
        <StyledButton className="pi pi-stop" onClick={onAddRectangle} disabled={toggleDrawSelected}></StyledButton>
        <StyledButton className="pi pi-pencil" onClick={toggleDraw}></StyledButton>
        <StyledButton className="pi pi-replay" onClick={undo} disabled={toggleDrawSelected}></StyledButton>
        <StyledButton className="pi pi-trash" onClick={removeSelectedObject} disabled={toggleDrawSelected}></StyledButton>
        <ColorInput
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
        <div style={{ display: 'flex' }}>
          <FileNameInput
            type="text"
            value={fileName}
            onChange={handleFileNameChange}
            placeholder="Nome do arquivo (com extensão)"
          />
          <StyledButton className="pi pi-download" onClick={Download} disabled={toggleDrawSelected}></StyledButton>
        </div>
      </ButtonContainer>
      <CanvasContainer>
        <FabricJSCanvas className="sample-canvas" onReady={onReady} width={width} height={height} />
      </CanvasContainer>
    </Container>
  );
}

export default ImageEditorComponent;




