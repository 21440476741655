import styled, { css } from 'styled-components';

export const ContainerDate = styled.div`
    /* grid-area: TR; */
    height: 30px;
    margin-top: 10px;
    justify-self: self-end;
    margin-right: 10px;
    align-self: center;
    display:  ${(props) => (props.$show ? 'block' : 'none')};
`;

export const ItemDate = styled.a`
    text-decoration: none;
    border-bottom: 1px solid black;
    border-left: ${(props) => (props.$noBorderLefet ? 'none' : '2px solid black')};
    cursor: ${(props) => (props.$noBorderLefet ? 'text' : 'pointer')};
    padding: 5px 8px 0px 8px;
    color: ${props => props.theme.colors.black};
    font-weight: ${(props) => (props.$value ? 'bold' : '300')};
`;