import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useCompanyStore = create(
  persist(
    (set) => ({
      id: null,
      tradingName: '',
      corporateName: '',
      setId: (id) => set({ id }),
      setTradingName: (tradingName) => set({ tradingName }),
      setCorporateName: (corporateName) => set({ corporateName }),
    }),
    {
      name: '@ada-company',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useCompanyStore;