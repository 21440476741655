import styled, { css, keyframes } from 'styled-components';

export const Content = styled.div`
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
`;

export const ContentForm = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 65%;
    background-color: #fff;
    @media(max-width: 900px) {
      width: 100%;
    }
`;

export const BackGround = styled.div`
    > img {
        max-width: 100%;
        max-height: auto;
    }
    @media(max-width: 1200px) {
      > img {
        display: none;
      }
    }
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    > h1 {
        margin-top: 18px;
    }

    > img {
        width: 60px;
        height: 60px;
    }
`;

export const Form = styled.form`
    width: 350px;

    padding: 3px;
    border-radius: 10px;
    @media(max-width: 900px) {
      width: 300px;
    }
    
    >input {
      height: 45px;
    }
`;

export const FormTitle = styled.h5`
    color: grey;
`;

export const ContainerForgotPassword = styled.a`
    width: 350px;
    text-align: end;
    text-decoration: none;
    margin-top: 30px;
    color: #000;
    @media(max-width: 900px) {
      width: 300px;
    }
`;

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: white;
`;

export const LoadingSpinnerWrapper = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.5);
  border-top: 4px solid ${props => props.theme.colors.success};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: ${spin} 1s linear infinite;
`;

export const LoadingMessageWrapper = styled.div`
  margin-top: 50px;
`;

export const LoadingMessage = styled.p`
  font-size: 4.2rem;
  @media(max-width: 900px) {
      font-size: 2.2rem;
    }
`;

