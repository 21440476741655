import React, { useEffect, useState, useRef } from 'react'
import Header from '../../components/Header';
import { Toast } from 'primereact/toast';
import DropdownComp from '../../components/ManagerSector/Dropdown/Standard/index';
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { clients_all } from "../../services/alarms";
import { allSectorsInformation } from '../../services/dashboard';
import { Container, Heading1, Heading4, MyButton, ContainerMyButton, CenteredColumn, ContainerTable, ContainerDropDown } from "./styles";

import DataTableComp from '../../components/ManagerSector/DataTable/index';

import useUserStore from '../../store/useUserStore';

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

const ManagerSector = () => {
  const { user } = useUserStore();

  const { id } = useParams();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [confirmClient, setConfirmClient] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [idClient, setIdClient] = useState('');
  const [clients, setClients] = useState(null);
  const [listSector, setlistSector] = useState(null);
  const [upDateList, setUpDateList] = useState(false);
  const [selectNewClient, setSelectNewClient] = useState(false);

  const handleDropdownChange = (e) => {
    setSelectedClient(e.value);
    setIdClient(e.value.id);
    setConfirmClient(true);
    setSelectNewClient(!selectNewClient);
  };

  useEffect(() => {
    if (id) {
      setEditClient(true);
    }
    clientsAll();
  }, [])

  useEffect(() => {
    listAllSectors(selectedClient);
  }, [confirmClient, upDateList])

  useEffect(() => {
    listAllSectors(selectedClient);
  }, [selectNewClient])

  const listAllSectors = async (data) => {
    if (data) {
      const payload = {
        clientId: data.id
      }
      try {
        const res = await allSectorsInformation(payload, user?.token);
        setlistSector(res.data);
      } catch (error) {
        if (error.response) {
          const msg = error.response.data.message;
          toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 10000 });
        } else if (error.request) {
          const message = error.message;
          toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 10000 });
        }
      }
    }
  }

  const clientsAll = async () => {
    try {
      const res = await clients_all(user);
      setClients(res.data);
    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 10000 });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 10000 });
      }
    }
  };

  const handleUpDate = () => {
    setUpDateList(!upDateList)
  };

  const newSector = () => (
    navigate(`/create-sector/${idClient}`)
  )

  return (
    <div>
      <Header />
      <Toast ref={toast} />
      <Container className="container-fluid h-100">
        {id ? <div className="row h-100">
          <div className="col-3">
            <Heading1>Gestão de setor</Heading1>
          </div>

          <ContainerDropDown className="col-6 d-flex align-items-center justify-content-center" $confirmClient={confirmClient}>
            <DropdownComp data={clients} handleDropdown={handleDropdownChange} value={selectedClient} />
          </ContainerDropDown>
          <div style={{ marginInline: 'auto', marginTop: 10 }}>
            <DataTableComp list={listSector} onUpdate={handleUpDate} />
          </div>
        </div> :
          <div className="row h-100">
            <div className="col-3">
              <Heading4>Gestão de setor</Heading4>
            </div>

            <ContainerDropDown className="col-6 d-flex align-items-center justify-content-center" $confirmClient={confirmClient}>
              <DropdownComp data={clients} handleDropdown={handleDropdownChange} value={selectedClient} />
            </ContainerDropDown>

            <ContainerMyButton className="col-3">
              {confirmClient && <MyButton
                onClick={newSector}>Criar Setor</MyButton>}
            </ContainerMyButton>

            <ContainerTable>
              {confirmClient && <DataTableComp list={listSector} onUpdate={handleUpDate} />}
            </ContainerTable>
          </div>
        }
      </Container>
    </div>
  )
}

export default ManagerSector;