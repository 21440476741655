import React, { useState, useEffect } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import { CustomDropdownButton } from './styles';
import useClientSelectedStore from '../../store/map/useClientSelectedStore';
import useCompanyStore from '../../store/useCompanyStore';
import { useIntl } from 'react-intl';

const DropdownClient = ({ data, initial, disabled }) => {
  const intl = useIntl();
  const { setId, setTradingName } = useCompanyStore();
  const { setClientSelected } = useClientSelectedStore();
  const [selectedOption, setSelectedOption] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    handleOptionClick({
      tradingName: initial?.trading_name,
      id: initial?.id
    });
  }, [initial]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setClientSelected(option);
    setTradingName(option.tradingName);
    setId(option.id);
    setSearchTerm(''); // Limpa o campo de pesquisa quando uma opção é clicada
  };

  const filterData = () => {
    if (!searchTerm) {
      setFilteredData(data);
      return;
    }
    const filtered = data.filter(option =>
      option.tradingName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    filterData();
  }, [searchTerm, data]);

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  return selectedOption?.tradingName ? (
    <CustomDropdownButton
      title={selectedOption.tradingName}
      disabled={disabled}
    >
      <Dropdown.Item>
        <FormControl
          placeholder={intl.formatMessage({ id: "search_client" })}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={handleInputClick}
        />
      </Dropdown.Item>

      {filteredData.map((option) => (
        <Dropdown.Item
          key={option.id}
          onClick={() => handleOptionClick(option)}
        >
          {option.tradingName}
        </Dropdown.Item>
      ))}
    </CustomDropdownButton>
  ) : null;
};

export default DropdownClient;
