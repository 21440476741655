import { create } from 'zustand';

const useZoomStore = create((set) => ({
  currentZoom: {
    pressure: {
        min: null,
        max: null,
    },
    mvn: {
        min: null,
        max: null,
    },
    flow: {
        min: null,
        max: null,
    },
    factor: {
        min: null,
        max: null,
    },
  },
  setCurrentZoom: (type, value) => set((state) => ({
    currentZoom: {
      ...state.currentZoom,
      [type]: {
        ...state.currentZoom[type],
        ...value,
      },
    },
  })),
}));

export default useZoomStore;