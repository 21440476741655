import axios from 'axios'
import { URL_KDI_API, TIMEOUT_REQ_LOGIN } from '../config/config';

const api = axios.create({
  timeout: TIMEOUT_REQ_LOGIN,
})

const getHeader = (token) => ({
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  }
})

const dologinKeyclaok = (email, token) => {
  return api.post(URL_KDI_API + 'users/find-one/all-information', { email }, getHeader(token));
};

export default dologinKeyclaok;
