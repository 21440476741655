import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Title, CustomFormCheck, FormCheck, StyledButton } from './styles';
import { MdKeyboardArrowLeft, MdOutlineHighlightOff, MdSearch } from 'react-icons/md';

const FilterDeviceMap = ({ devices, filteredSensor, selectedSensor }) => {
    const intl = useIntl();
    const [filteredData, setFilteredData] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [rotateIcon, setRotateIcon] = useState(false);

    useEffect(() => {
        setFilteredData(Object.values(devices));
    }, [devices]);

    const handleFilterChange = (e) => {
        const searchTerm = e.target.value;
        setFilterValue(searchTerm);

        const filtered = Object.values(devices).filter((item) =>
            item.deviceId.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
    };

    const handleClick = () => {
        setRotateIcon(!rotateIcon); // Alternar a rotação do ícone
    };

    const handleClear = () => {
        setFilterValue('');
        setFilteredData(Object.values(devices))
    };

    const handleSelectDevice = (data) => {
        filteredSensor(data);
        setFilterValue(data);
    };

    return (
        <Container $state={rotateIcon}>
            <Title>
                {rotateIcon && <FormattedMessage id="title_filter_devices" />}
                <StyledButton onClick={handleClick} $rotateIcon={rotateIcon}>
                    {!rotateIcon ? <MdSearch size={25} style={{cursor: 'pointer'}}/> : <MdKeyboardArrowLeft size={20} style={{cursor: 'pointer'}}/>}
                </StyledButton>
            </Title>
            {rotateIcon && <div style={{marginBottom: 5}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10, marginTop: 10 }}>
                    <input
                        style={{ width: 120, height: 20, paddingLeft: 3 }}
                        type="text"
                        placeholder={intl.formatMessage({ id: "msg_input_filter" })}
                        value={filterValue}
                        onChange={handleFilterChange}

                    />
                    {filterValue.length > 0 && <MdOutlineHighlightOff size={18} style={{ cursor: 'pointer' }} onClick={handleClear} />}
                </div>
                {filteredData && filteredData.length === 1 ? filteredData.map((item) => (
                    <span style={{ cursor: 'pointer', backgroundColor: selectedSensor === filterValue ? '#E44C4E':'#6EFF94' , padding: 4, color: '#000' }} key={item.deviceId} onClick={() => handleSelectDevice(item.deviceId)}>
                        {item.deviceId}
                    </span>
                )) : <span><FormattedMessage id="msg_devices_found" /> {filteredData.length}</span>}
            </div>}
        </Container>
    );
}

export default FilterDeviceMap;