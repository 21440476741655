import React, { useState, useEffect, useRef, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip as PrimeReactTooltip } from 'primereact/tooltip';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  registerables
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getDatetimeFromTimestamp } from "../../../utils/Utils";
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { ALL_64_COLOR } from "../../../utils/Utils";
import reset from "../../../assets/img/ICONE.png";
import { OverlayPanel } from 'primereact/overlaypanel';
import moment from 'moment';
import 'primeicons/primeicons.css';
import useStateSidebarStore from '../../../store/useStateSidebarStore';
import 'chartjs-adapter-moment';
import useSliderStore from '../../../store/useSliderStore';
import useZoomStore from '../../../store/useZoomStore';
import { TabView, TabPanel } from 'primereact/tabview';
import { MdSearch, MdCancel } from 'react-icons/md'


import { Container, Header, TitleChart, SettingsOpt, SettingsContainer, Content, SettingsCircle, ButtonReset, Chart, SettingsContainerList, Button } from './styles';

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin,
  annotationPlugin
);

const offsetMinutes = new Date().getTimezoneOffset();
const HOURADJUST = Math.abs(offsetMinutes) / 60;

const RenderPressure = ({ ref_date, region, devicesList, initialDevicesList, selectedSensor, onSelectLine, onGetDevices }) => {
  const intl = useIntl();
  const op = useRef(null);
  const { isOpen } = useStateSidebarStore();
  const { currentSlider, setCurrentSlider } = useSliderStore();
  const [verticalLineShow, setVerticalLineShow] = useState(false);
  const [verticalLinePosition, setVerticalLinePosition] = useState(null);
  const [flag, setFlag] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedItemsList, setCheckedItemsList] = useState({});
  const [isWindowMaximized, setIsWindowMaximized] = useState(false);
  const [upDateSize, setUpDateSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [devicesListState, setDevicesListState] = useState([]);
  const [clearFilterDeviceList, setClearFilterDeviceList] = useState(false);
  const [inputValueFilter, setInputValueFilter] = useState('');

  const lastZoomLevelRef = useRef(null);
  const myChartRefPressure = useRef(null);

  useEffect(() => {
    handleSelectAll();
    if (myChartRefPressure && myChartRefPressure.current) {
      myChartRefPressure.current.resize();
    }
  }, [isOpen])


  useEffect(() => {

    if (currentSlider) {
      const { show, value } = currentSlider;
      setVerticalLineShow(show);
      const date_full = new Date(value);
      const day = date_full.getDate();
      const month = date_full.getMonth() + 1;
      const year = date_full.getFullYear().toString().slice(-2);
      setVerticalLinePosition(`${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`);
    }
  }, [currentSlider]);

  useEffect(() => {
    //handleClearAll();
    setTimeout(() => {
      // handleSelectAll();
      if (myChartRefPressure && myChartRefPressure.current) {
        myChartRefPressure.current.resize();
      }
    }, 2500);
  }, [isOpen])

  useEffect(() => {
    handleClearAll();
    setTimeout(() => {
      handleSelectAll();
      if (myChartRefPressure && myChartRefPressure.current) {
        myChartRefPressure.current.resize();
      }
    }, 2000);
  }, [upDateSize])

  useEffect(() => {
    handleClearAll();
    setTimeout(() => {
      handleSelectAll();
      if (myChartRefPressure && myChartRefPressure.current) {
        myChartRefPressure.current.resize();
      }
    }, 2200);
  }, [isWindowMaximized])

  useEffect(() => {
    const handleResize = () => {
      const isMaximized = window.innerWidth === window.screen.width;
      setIsWindowMaximized(isMaximized);
      const width = window.innerWidth;
      const height = window.innerHeight;
      setUpDateSize({ width, height });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (devicesList) {
      const arrayDevicesList = devicesList.filter(item => item.dataType === 'pressure').map(item => item.deviceId);
      setDevicesListState(arrayDevicesList);
    };
  }, [devicesList, clearFilterDeviceList]);

  useEffect(() => {
    if (inputValueFilter.length > 0) {
      const searchTerm = inputValueFilter;
      const filtered = Object.values(devicesListState).filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setDevicesListState(filtered)
    }
    if (inputValueFilter.length === 0) {
      setClearFilterDeviceList(!clearFilterDeviceList);
    };
  }, [inputValueFilter])

  useEffect(() => {
    const objeto = initialDevicesList.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});

    if (checkedItemsList && Object.keys(checkedItemsList).length) {
      const jsonData = JSON.stringify(checkedItemsList);
      localStorage.setItem('deviceData', jsonData);
    };
  }, [checkedItemsList])

  useEffect(() => {
    if (initialDevicesList) {
      const obj = initialDevicesList.reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
      const jsonData = JSON.stringify(obj);
      localStorage.setItem('deviceData', jsonData);
    };
  }, [initialDevicesList])


  const handleZoomComplete = (chart) => {
    if (chart) {
      const currentZoomLevelMin = chart.chart.scales['x'].min;
      const currentZoomLevelMax = chart.chart.scales['x'].max;
      lastZoomLevelRef.current = {
        min: currentZoomLevelMin,
        max: currentZoomLevelMax
      };
      setFlag(true);
    }
  };

  const resetZoomBtn = () => {
    if (myChartRefPressure && myChartRefPressure.current) {
      myChartRefPressure.current.resetZoom();
      setFlag(false);
    }
  };

  const verifyInterval = (entry, previousEntry) => {
    const diffMinutes = moment(entry.timestamp).diff(previousEntry.x, 'minutes');
    return diffMinutes > 30;
  }

  const array = region;
  let Num = 1;
  ref_date = getDatetimeFromTimestamp(ref_date);

  const groupDataByColor = (array) => {
    let groupedData = {};
    array.forEach((entry) => {
      if (!groupedData[entry.deviceId]) {
        if (selectedSensor != null) {
          if (selectedSensor === entry.deviceId) {
            groupedData[entry.deviceId] = {
              label: `ID ${entry.deviceId}`,
              data: [],
              fill: false,
              borderColor: "#6b45ba",
              tension: 0,
              borderWidth: 2,
              pointRadius: 1,
              hoverRadius: 8,
              spanGaps: false
            };
          } else {
            groupedData[entry.deviceId] = {
              label: `ID ${entry.deviceId}`,
              data: [],
              fill: false,
              borderColor: "#BBBBBB",
              tension: 0,
              borderWidth: 1,
              pointRadius: 0.8,
              hoverRadius: 8,
              spanGaps: false
            };
          }
        } else {
          groupedData[entry.deviceId] = {
            label: `ID ${entry.deviceId}`,
            data: [],
            fill: false,
            borderColor: getRandomColor(),
            tension: 0,
            borderWidth: 1,
            pointRadius: 0.6,
            hoverRadius: 8,
            spanGaps: false
          };
        }
      }

      const previousEntry = groupedData[entry.deviceId].data[groupedData[entry.deviceId].data.length - 1];

      if (previousEntry && previousEntry.x !== undefined && verifyInterval(entry, previousEntry)) {
        groupedData[entry.deviceId].data.push({ x: null, y: null });
        //  groupedData[entry.ID].data.push({ x: NaN, y: NaN });
      }
      groupedData[entry.deviceId].data.push({ x: entry.timestamp + (HOURADJUST * 60 * 60 * 1000), y: entry.singleValue });
    });
    if (selectedSensor != null && groupedData[selectedSensor]) {
      const selectedSensorEntry = groupedData[selectedSensor];
      delete groupedData[selectedSensor];
      groupedData = { [selectedSensor]: selectedSensorEntry, ...groupedData };
    }
    return Object.values(groupedData);
  };
  const getRandomColor = () => {
    let color = ALL_64_COLOR[(Num++ * 8) % 128]
    return color;
  }

  let auxDate = new Date(verticalLinePosition);
  auxDate.setDate(auxDate.getDate() + 1);
  let finalDate = (auxDate.getMonth() + 1) + '/' + auxDate.getDate() + '/' + auxDate.getFullYear();

  const handleSelectAll = () => {
    const initialCheckedItems = temp.reduce((acc, item) => {
      acc[item.label] = true;
      return acc;
    }, {});
    setCheckedItems(initialCheckedItems);
  };

  const handleClearAll = () => {
    setCheckedItems({});
  };

  const handleCheckboxChange = (label) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [label]: !prevCheckedItems[label],
    }));
  };

  const handleGetNewDevices = (devices) => {
    setCheckedItemsList((prevCheckedItemsList) => ({
      ...prevCheckedItemsList,
      [devices]: !prevCheckedItemsList[devices],
    }));
    // handleClearFilterChange();
  };

  const callNewDevices = () => {
    onGetDevices(checkedItemsList);
  };

  const handleClearAllDevices = () => {
    setCheckedItemsList({});
  };

  const temp = groupDataByColor(array);
  const filteredData = temp.filter((item) => checkedItems[item.label]);

  const chartData = {
    labels: array.map((entry) => entry.timestamp),
    datasets: filteredData
  };

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const clickedElement = elements[0];
      const datasetIndex = clickedElement.datasetIndex;
      const dataIndex = clickedElement.index;
      const filteredData = chartData.datasets[datasetIndex];
      const value = filteredData.data[dataIndex];
      onSelectLine({
        label: filteredData.label.replace('ID ', ''),
        value,
      })
    }
  };

  const chartOptions = {
    responsive: false,
    onClick: handleChartClick,
    parsing: false,
    animation: false,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: intl.formatMessage({ id: "pressure_subtitle" }),
          padding: 5
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: '#eef0fa',
          zeroLineColor: 'rgba(90, 113, 208, 0)'
        },
        ticks: {
          beginAtZero: false,
          max: Math.ceil(1.02 / 10) * 10,
          // stepSize: 10,
          padding: 10
        }
      },
      x: {
        min: flag ? lastZoomLevelRef.current.min : 'original',
        max: flag ? lastZoomLevelRef.current.max : 'original',
        type: 'time',
        time: {
          unit: 'day',
          parser: 'MM/DD/YYYY HH:mm',
          tooltipFormat: intl.formatMessage({ id: "day_tooltip" }),
          displayFormats: {
            day: intl.formatMessage({ id: "day_label_x" }),
          }
        },
        position: 'bottom',
        grid: {
          drawBorder: false,
          display: false,
          drawTicks: false
        },
        ticks: {
          marginTop: 10,
          beginAtZero: false,
          // stepSize: 10,
          fontColor: '#878f87',
          padding: 1,
          maxRotation: 0,
          minRotation: 0,
        },
      }
    },
    legend: {
      display: false
    },
    elements: {
      line: {
        tension: 0
      }
    },
    tooltips: {
      backgroundColor: 'rgba(2, 171, 254, 1)',
    },
    plugins: {
      legend: {
        display: false
      },
      decimation: {
        enabled: true,
        algorithm: 'lttb',
        samples: 400,
        threshold: 400
      },
      zoom: {
        limits: {
          x: {
            min: chartData.labels[0],
            max: chartData.labels[chartData.labels.length - 1]
          }
        },
        pan: {
          enabled: true,
          mode: 'xy',
          modifierKey: 'ctrl'
        },
        zoom: {
          wheel: {
            enabled: false
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
          drag: {
            enabled: true
          },
          onZoomComplete: (chart => {
            handleZoomComplete(chart);
          }),
        },
        drag: {
          backgroundColor: 'rgba(110, 255, 148, 0.4)',
        },
      },
      autocolors: false,
      annotation: verticalLineShow
        ? {
          annotations: {
            line1: {
              type: 'box',
              xScaleID: 'x',
              yScaleID: 'y',
              xMin: verticalLinePosition,
              xMax: finalDate,
              backgroundColor: 'rgba(0, 255, 0, 0.2)',
              borderColor: 'rgba(0, 255, 0, 0.52)',
              borderWidth: 0,
              drawTime: 'beforeDatasetsDraw'
            }
          }
        }
        : {}
      ,
      tooltip: {
        caretPadding: 10
      }
    }
  }

  const handleFilterChange = (e) => {
    setInputValueFilter(e.target.value);
  }

  const handleClearFilterChange = () => {
    setClearFilterDeviceList(!clearFilterDeviceList);
    setInputValueFilter('');
  };

  const handleOpenSettings = (e) => {
    const retrievedData = localStorage.getItem('deviceData');
    setCheckedItemsList(JSON.parse(retrievedData))
    return op.current.toggle(e)
  }

  return (
    <Container>
      <Header>
        <PrimeReactTooltip target=".custom-target-settings" />
        <i className="pi pi-sliders-h custom-target-settings"
          style={{ fontSize: '1rem', cursor: 'pointer', color: 'green', alignSelf: 'center', marginLeft: 15 }}
          onClick={(e) => handleOpenSettings(e)}
          data-pr-tooltip={intl.formatMessage({ id: "settings" })}
          data-pr-position="left"
        ></i>
        <TitleChart><FormattedMessage id="pressure_title" /></TitleChart>
        <OverlayPanel ref={op} position="top">
          {/* <SettingsOpt>
            <PrimeReactTooltip target=".custom-target-pi-check-square" />
            <i className="pi pi-check-square custom-target-pi-check-square"
              style={{ fontSize: '1rem', cursor: 'pointer', color: 'green' }}
              onClick={handleSelectAll}
              data-pr-tooltip={intl.formatMessage({ id: "select_all" })}
              data-pr-position="left"
            ></i>
            <PrimeReactTooltip target=".custom-target-pi-stop" />
            <i className="pi pi-stop custom-target-pi-stop"
              style={{ fontSize: '1rem', cursor: 'pointer', color: 'black' }}
              onClick={handleClearAll}
              data-pr-tooltip={intl.formatMessage({ id: "clear_all" })}
              data-pr-position="right"
            ></i>
          </SettingsOpt> */}
          <TabView>
            <TabPanel header={intl.formatMessage({ id: "all" })}>
              <div style={{ border: 'solid 1px black', width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                <input
                  type="text"
                  placeholder={intl.formatMessage({ id: "type_serial" })}
                  value={inputValueFilter}
                  onChange={handleFilterChange}
                  style={{ paddingLeft: '5px' }}
                />
                {inputValueFilter.length === 0 && <MdSearch size={25} />}
                {inputValueFilter.length > 0 && <MdCancel size={23} onClick={handleClearFilterChange} style={{ cursor: 'pointer' }} />}
              </div>
              <SettingsContainerList>
                {
                  devicesListState.map((device, index) => (
                    <Content key={index}>
                      <label>
                        <input
                          type="checkbox"
                          checked={checkedItemsList[device] || false}
                          onChange={() => handleGetNewDevices(device)}
                        />
                        <span>  {device}</span>
                      </label>
                    </Content>
                  ))
                }
              </SettingsContainerList>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                <Button type="submit" className="btn btn-danger" onClick={handleClearAllDevices}><FormattedMessage id="clear" /></Button>
                <Button type="submit" className="btn" style={{ backgroundColor: '#6eff94' }} onClick={callNewDevices}><FormattedMessage id="search" /></Button>
              </div>
            </TabPanel>
            <TabPanel header={intl.formatMessage({ id: "selected" })}>
              <SettingsContainer>
                {temp.map((item, index) => (
                  <Content key={index}>
                    <SettingsCircle $borderColor={item.borderColor} />
                    <label>
                      <input
                        type="checkbox"
                        checked={checkedItems[item.label] || false}
                        onChange={() => handleCheckboxChange(item.label)}
                      />
                      <span>  {item.label}</span>
                    </label>
                  </Content>
                ))}
              </SettingsContainer>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                <Button type="submit" className="btn btn-danger" onClick={handleClearAll}><FormattedMessage id="clear_all" /></Button>
                <Button type="submit" className="btn" style={{ backgroundColor: '#6eff94' }} onClick={handleSelectAll}><FormattedMessage id="mark_all" /></Button>
              </div>
            </TabPanel>

          </TabView>
        </OverlayPanel>
        <PrimeReactTooltip target=".custom-target-btn-reset" />
        <ButtonReset className='custom-target-btn-reset'
          onClick={() => resetZoomBtn()}
          data-pr-tooltip={intl.formatMessage({ id: "reset_zoom" })}
          data-pr-position="left"
        >
          <img src={reset} width={22} alt="Reset" style={{ verticalAlign: "text-top" }} />
        </ButtonReset>
      </Header>
      <Chart $data={chartData}>
        {/* <Line data={chartData} options={chartOptions} width={windowWidth} redraw={false} ref={myChartRefPressure} /> */}
        {chartData && chartData.labels.length > 0 ? <Line data={chartData} options={chartOptions} redraw={false} ref={myChartRefPressure} /> : <FormattedMessage id="msg_no_data" />}
      </Chart>
    </Container>
  );
};

export default memo(RenderPressure);
