import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import useDateSelectedStore from '../../store/map/useDateSelectedStore';
import { ContainerDate, ItemDate } from './styles';

const DateSelect = ({show}) => {
    const { dateSelected, setDateSelected } = useDateSelectedStore();

    useEffect(() => {
        setDateSelected('30');
    }, [])
    

    const handleMenuItemClick = (option) => {
        setDateSelected(option);
      };

  return (
    <ContainerDate $show={show}>

        <ItemDate href="#" $noBorderLefet={true} $value={true}>
          <FormattedMessage id="visualization" />
        </ItemDate>

        <ItemDate href="#" $noBorderLefet={false} $value={dateSelected === '30'} onClick={() => handleMenuItemClick('30')}>
          30 <FormattedMessage id="days" />
        </ItemDate>

        <ItemDate href="#" $noBorderLefet={false} $value={dateSelected === '60'} onClick={() => handleMenuItemClick('60')}>
          60 <FormattedMessage id="days" />
        </ItemDate>

        <ItemDate href="#" $noBorderLefet={false} $value={dateSelected === '90'} onClick={() => handleMenuItemClick('90')}>
          90 <FormattedMessage id="days" />
        </ItemDate>

      </ContainerDate>
  )
}

export default DateSelect;
